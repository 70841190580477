import {urlSafeBase64Encode} from "../helpers/Base64";

export const initialState = { items: [] }

export const clampCount = (count, { item, variation }, state) => {
  const product = variation || item

  const CUSTOM_NAME_PRINT_ID = 31575;
  const CUSTOM_K5_ID = 26907;

  if(state && product.id === CUSTOM_NAME_PRINT_ID) {
    let amountAllowed = 0;

    for(let i = 0; i < state.items.length; i++) {
      const item = state.items[i];
      if(item.item && item.item.id === CUSTOM_K5_ID) {
        amountAllowed++;
      }
    }

    return Math.max(0, Math.min(count, amountAllowed));
  }

  if(state && product.recommendedBy) {
    let recommender = null;
    for(let i = 0; i < state.items.length; i++) {
      const item = state.items[i];
      if(item.item && item.item.id === product.recommendedBy) {
        recommender = item;
        break;
      }
    }

    if(recommender) {
      return Math.max(0, Math.min(count, recommender.count));
    }
  }

  if (product.manage_stock) {
    return product.backorders === 'yes'
      ? Math.max(0, count)
      : Math.max(0, Math.min(count, product.stock_quantity))
  }

  return Math.max(0, count)
}

const createFinder = action => {
  return ({ item, variation, configuration }) => {
    if (item.id !== action.item.id) {
      return false;
    }

    if (action.variation) {
      if (!variation) {
        return false;
      }

      if (action.variation.id !== variation.id) {
        return false;
      }
    }

    if (action.configuration) {
      if (!configuration) {
        return false;
      }

      if (!_.isEqual(action.configuration, configuration)) {
        return false;
      }
    }

    return true;
  }
}

const getCategoryFromUrl = url => {
  const result = url.match(/.+\/wp\/product\/(\w+)\//);
  if(result && result.length && result[1]) return result[1];
  return null;
}

const trackCartEvent = (event, item, count) => {
  if(typeof ga === "undefined" || typeof ga.getAll === "undefined") return;

  if(!event || !item) return;
  if(!item.currency) item.currency = 'EUR';
  if(!count) count = 1;

  if(typeof window !== "undefined" && typeof ga !== "undefined") {
    if(event === 'addToCart') {
      let variationAttributes = 0;

      if(item.variation) {
        if(item.variation.attributes.length) {
          variationAttributes = {};

          item.variation.attributes.forEach(attr =>  {
            variationAttributes[attr.name.toLowerCase()] = attr.option;
          });
        }
      }

      const clientId = ga.getAll()[0].get('clientId');

      let url = '/api/ga/added-to-cart?cid=' + clientId + '&product_id=' + item.item.id + '&quantity=' + count;
      if(variationAttributes !== 0) {
        url += '&variation=' + urlSafeBase64Encode(JSON.stringify(variationAttributes));
      } else {
        url += '&variation=' + urlSafeBase64Encode("0");
      }

      fetch(url);
    } else {
      const clientId = ga.getAll()[0].get('clientId');
      let url = '/api/ga/removed-from-cart?cid=' + clientId + '&product_id=' + item.item.id + '&quantity=' + count;

      fetch(url);
    }
  }
}

const addItem = (state, action) => {
  if (!action.item) {
    return state;
  }

  if(action.item.acf && action.item.acf.availability && action.item.acf.availability === "coming_soon") {
    return state;
  }

  const index = state.items.findIndex(createFinder(action))

  // The item is already in the cart, so just increment the quantity.
  if (index >= 0 && !action.configuration) {
    const { item, count, variation } = state.items[index]
    const items = [...state.items]
    const updatedCount = clampCount(count + 1, { item, variation }, state)
    items[index] = { count: updatedCount, item }
    trackCartEvent('addToCart', { item, variation, currency: action.currency });
    return { ...state, items }
  }

  const newItem = {
    count: clampCount(1, action, state),
    item: { ...action.item },
  }

  if (action.variation) {
    newItem.variation = action.variation
  }

  if (action.configuration) {
    newItem.configuration = action.configuration
  }

  trackCartEvent('addToCart', { ...newItem, currency: action.currency });
  return { ...state, items: [...state.items, newItem] }
}

const removeItem = (state, action) => {
  if (!action.item) {
    return state
  }

  const index = state.items.findIndex(createFinder(action))

  if (index < 0) {
    return state
  }

  // Find any possible items that might be currently recommended by this item
  let modifiedRecommendedProductsData = false;
  let finalData = {};
  if(typeof state.recommendedProductsData !== "undefined") {
    let copyOfData = JSON.parse(JSON.stringify(state.recommendedProductsData));
    for(const key of Object.keys(copyOfData)){
      if(copyOfData[key].recommendedBy === action.item.id){
        copyOfData[key].useDiscount = false;
        modifiedRecommendedProductsData = true;
      }
    }

    if(modifiedRecommendedProductsData) finalData = copyOfData;
  }

  const items = [...state.items]
  items.splice(index, 1)

  if(modifiedRecommendedProductsData)
    return { ...state, items, recommendedProductsData: finalData}

  return { ...state, items }
}

const updateCount = (state, action) => {
  if (action.item == null || action.count == null) {
    return state
  }

  const index = state.items.findIndex(createFinder(action))

  if (index < 0) {
    return state
  }

  const previousCount = state.items[index].count;
  const items = [...state.items]
  const count = clampCount(action.count, items[index], state)

  if(count < previousCount) {
    trackCartEvent('removeFromCart', {item: action.item, variation: action.variation, currency: action.currency}, previousCount - count);
  } else {
    trackCartEvent('addToCart', {item: action.item, variation: action.variation, currency: action.currency}, count - previousCount);
  }

  items[index] = { ...items[index], count }

  return { ...state, items }
}

const setPaymentIntent = (state, action) => {
  if(action.intent == null) {
    return state;
  }

  return { ...state, intent: action.intent }
}

const setVariationProducts = (state, action) => {
  if(typeof state.variationProducts !== "undefined"){
    if(state.variationProducts.length === 0){
      return { ...state, variationProducts: [[...action.variationProducts]] };
    }

    let idx = -1;
    for(let i = 0; i < state.variationProducts.length; i++){
      if(state.variationProducts[i][0].variation_for === action.variationProducts[0].variation_for){
        idx = i;
        break;
      }
    }

    // This is a new variation type, simply push it to the array
    if(idx === -1) {
      let varProds = [...state.variationProducts];
      varProds.push(action.variationProducts);
      return { ...state, variationProducts: varProds };
    } else {
      // This variation type already exists, overwrite it
      let varProds = [...state.variationProducts];
      varProds[idx] = action.variationProducts;
      return { ...state, variationProducts: varProds };
    }
  }

  return { ...state, variationProducts: [[...action.variationProducts]] };
}

const saveRecommendedProductsData = (state, action) => {
  let data = {};

  if(typeof state.recommendedProductsData !== "undefined"){
    data = JSON.parse(JSON.stringify(state.recommendedProductsData));
  }

  action.recommendedProductsData.forEach(recData => {
    data['prod_' + recData.id] = {
      price: {
        eur: recData.price_eur,
        usd: recData.price_usd
      },
      recommendedBy: recData.recommended_by,
      useDiscount: recData.use_discount
    };
  });

  return { ...state, recommendedProductsData: data };
}

const setRecommendedProducts = (state, action) => {
  if(!action.recommendedProducts) return {...state, recommendedProducts: action.recommendedProducts}

  const recommendedProducts = action.recommendedProducts.filter(recProduct => recProduct.stock_status === 'instock');
  return {...state, recommendedProducts};
}


export default (state, action) => {
  let newState = state

  switch (action.type) {
    case 'init':
      newState = { ...state, ...action.state, didInit: true }
      break
    case 'clear':
      newState = { ...initialState, didInit: true }
      break
    case 'add':
      newState = addItem(state, action)
      break
    case 'set_count':
      newState = updateCount(state, action)
      break
    case 'remove':
      newState = removeItem(state, action)
      break
    case 'set_country':
      newState = { ...state, country: action.country }
      break
    case 'set_payment_intent':
      newState = setPaymentIntent(state, action);
      break;
    case 'crossSellProducts':
      newState = { ...state, crossSellProducts: action.crossSellProducts }
      break;
    case 'previousProducts':
      newState = { ...state, previousProducts: action.previousProducts };
      break;
    case 'variationProducts':
      newState = setVariationProducts(state, action);
      break;
    case 'productAddedToCart':
      newState = { ...state, productAddedToCart: action.productAddedToCart };
      break;
    case 'recommendedProducts':
      newState = setRecommendedProducts(state, action);
      break;
    case 'recommendedProductsData':
      newState = saveRecommendedProductsData(state, action);
      break;
    case 'linkedConfigurableProduct':
      newState = { ...state, linkedConfigurableProduct: action.linkedConfigurableProduct };
      break;
    case'test':
      console.log(action.message);
      break;
    default:
      newState = state
  }

  // HACK: for some reason we at times get orders containing products without
  // SKUs. It semes to happen only for variations, but until we know why,
  // we'll just make sure they never get added to the cart.
  newState.items = newState.items.filter(({ item, variation }) => (
    Boolean(variation != null ? variation.sku : item.sku)
  ))

  // SSR never inits.
  if (!newState.didInit) {
    return newState
  }

  try {
    const items = newState.items.map(({ variation, item, count, configuration }) => ({
      id: item.id,
      variation: variation ? variation.id : undefined,
      count,
      configuration
    }))
    const serialized = JSON.stringify({
      items,
      country: newState.country,
      intent: newState.intent,
      crossSellProducts: newState.crossSellProducts,
      previousProducts: newState.previousProducts,
      variationProducts: newState.variationProducts,
      productAddedToCart: newState.productAddedToCart,
      recommendedProducts: newState.recommendedProducts,
      recommendedProductsData: newState.recommendedProductsData,
      linkedConfigurableProduct: newState.linkedConfigurableProduct
    });
    window.localStorage.setItem('cart', serialized)
  } catch (err) {
    console.error(`Couldn't write to localStorage.`, err)
  }

  return newState
}
