import React, {Component} from 'react'
import styles from './product.module.css'
import classNames from 'classnames/bind'
import Helmet from 'react-helmet'
import {HOSTNAME} from '../../../constants'
import Hero from './Hero'
import QuickInfo from './QuickInfo'
import InfoBoxes from './InfoBoxes'
import Videos from './Videos'
import TechSpecs from './TechSpecs'
import FAQ from './FAQ'
import Reviews from './Reviews'
import Gallery from './Gallery'
import Html from './Html'
import RelatedProducts from './RelatedProducts'
import TrendingProducts from './TrendingProducts'
import {observer} from 'mobx-react'
import Ticker from "./Ticker";
import HeroImage from "./HeroImage";
import GalleryGrid from "./GalleryGrid";
import ConfiguratorTemplates from "../../../configurator/ConfiguratorTemplates";
import { Cart } from '../../../Cart'
import ConfiguratorHooks from "../../../configurator/ConfiguratorHooks";
import NewsFeed from "../../NewsFeed";
import TextRow from "../../TextRow";
import AnchorLink from "../../AnchorLink";
import AnchorMenu from "../../AnchorMenu";

const cn = classNames.bind(styles)

const Layouts = {
  hero: Hero,
  quick_info: QuickInfo,
  info_box: InfoBoxes,
  tech_specs: TechSpecs,
  reviews: Reviews,
  videos: Videos,
  gallery: Gallery,
  faq: FAQ,
  html: Html,
  related_products: RelatedProducts,
  trending_products: TrendingProducts,
  ticker: Ticker,
  hero_image: HeroImage,
  gallery_grid: GalleryGrid,
  newsfeed: NewsFeed,
  text_row: TextRow,
  anchor_link: AnchorLink
}

const Product = ({ data, store, location, noHelmet }) => {

  if (!data || !data.acf) {
    return null
  }

  if(data.acf.hide_product) {
    return typeof window !== "undefined" ? (
      <script type={'text/javascript'}>
        (function(){
        window.location = '/'
      })();
      </script>
    ) : null;
  }

  const { id, didGetStockQuantity, isConfigurable } = data || {};
  const [selectedProducts, setSelectedProducts] = React.useState({});
  const [configuratorPrices, setConfiguratorPrices] = React.useState({hasBeenSet: false, prices: {}, skus: [], ids: []});
  const cart = React.useContext(Cart);
  const configId = (data.acf.configuratorLink && data.acf.showConfiguratorLink) ? data.acf.configuratorLink : id;

  const addConfiguratorHooks = isConfigurable || (data.acf.configuratorLink && data.acf.showConfiguratorLink);
  ConfiguratorHooks.initHooks(addConfiguratorHooks, selectedProducts, setSelectedProducts, configuratorPrices, setConfiguratorPrices, configId, cart, id, data);

  React.useEffect(() => {
    if(typeof jQuery === 'undefined') return;

    ConfiguratorHooks.updateCurrency()
    ConfiguratorHooks.pricesHaveBeenSet(cart);

  }, [configuratorPrices.hasBeenSet, cart.currency])

  React.useEffect(() => {
    if (id && store && store.getStockQuantity && !didGetStockQuantity) {
      store.getStockQuantity(id)
    }
  }, [id, didGetStockQuantity])

  React.useEffect(() => {
    ConfiguratorHooks.setData(isConfigurable, id, store);
    ConfiguratorHooks.maybeLoadSharedData();
  }, [data.acf.configuratorLink, id, isConfigurable])

  const { content = [], short_description = '' } = data.acf

  if (!content || content.length === 0) {
    return null
  }

  const fbImage = content
    .filter(({ acf_fc_layout }) => acf_fc_layout === 'hero')
    .reduce((_, { images = [] }) => {
      if (images.length >= 2) {
        const image = images[1]
        return image.image
      }

      const image = images[0]

      if (image) {
        return image.image
      }

      return ''
    }, '')

  let prevAcfLayout = '';

  return (
    <div className={cn('content')}>
      <AnchorMenu />
      {!noHelmet && (
        <Helmet
          title={data.name}
          titleTemplate='%s – CHERRY XTRFY'
          meta={[
            {
              property: 'og:url',
              content: `${HOSTNAME}${location.pathname}`,
            },
            { property: 'og:type', content: 'product' },
            { property: 'og:title', content: data.name },
            { property: 'og:description', content: short_description },
            { property: 'og:image', content: fbImage.url },
          ]}
        />
      )}
      {content.map((c, idx) => {
        const { acf_fc_layout, ...contentProps } = c
        const nextItem = idx < content.length - 1 && content[idx + 1]
        const isLastOfType = nextItem.acf_fc_layout !== acf_fc_layout
        const Component = Layouts[acf_fc_layout]

        let additionalClasses = cn({ 'last-of-type': isLastOfType });

        switch(acf_fc_layout) {
          case 'text_row':
            if(nextItem.acf_fc_layout === 'related_products') {
              additionalClasses += ' ' + cn('related-products-next');
            }
            break;
          case 'related_products':
            if(prevAcfLayout === 'text_row') {
              additionalClasses += ' ' + cn('text-row-prev');
            }
            break;
          default:
            break;
        }

        prevAcfLayout = acf_fc_layout;

        if (!Component) {
          return null
        }

        return (
          <Component
            {...data}
            {...contentProps}
            key={idx}
            className={additionalClasses}
            store={store}
            product={data}
          />
        )
      })}
      {(isConfigurable || (data.acf.configuratorLink && data.acf.showConfiguratorLink)) ? <ConfiguratorTemplates /> : ''}
    </div>
  )
}

export default observer(Product)
