import React from 'react'
import styles from './product-tile.module.css'
import classNames from 'classnames/bind'
import { Link } from 'react-router-dom'
import striptags from 'striptags'
import Currency from '../Currency'
import ColourSwatches from "../ColourSwatches";
import {parsePrices} from "../../utils";

const cn = classNames.bind(styles)

const Info = ({ label, value }) => (
  <div className={cn('info')}>
    <h4 className={cn('info-label')}>{label}</h4>
    <p className={cn('info-value')}>{value}</p>
  </div>
)

export default (props) => {
  let {
    className,
    name,
    isPlaceholder,
    url,
    noLink,
    acf: { images, image_front, image_back, short_description, info_field, product_colour, colour_variations, special_notice } = {},
    recommendedProductsDataSmallest: { price_eur: recommended_products_price_eur, price_usd: recommended_products_price_usd, use_discount: use_recommended_products_price } = {}
  } = props;

  // Get the correct images to use
  const frontImage = (images ? images.image_front : false) || image_front;
  const backImage = (images ? images.image_back : false) || image_back;

  if(!special_notice) {
    special_notice = {
      text: '',
      background_colour: null,
      text_colour: null
    };
  }

  const [currentColour, setCurrentColour] = React.useState({
    colour: null,
    name: null,
    special_notice: {
      text: '',
      background_colour: null,
      text_colour: null
    },
    price: {
      onSale: null,
      regular: {
        eur: null,
        usd: null,
      },
      sale: {
        eur: null,
        usd: null
      }
    },
    image: {
      front: null,
      back: null
    },
    link: null
  });

  const prices = parsePrices(props);

  const defaultData = {
    colour: null,
    name: name,
    special_notice: {
      text: special_notice.text,
      background_colour: special_notice.background_colour,
      text_colour: special_notice.text_colour
    },
    price: {
      onSale: prices.isOnSale,
      regular: {
        eur: prices.eur.regularPrice,
        usd: prices.usd.regularPrice,
      },
      sale: {
        eur: prices.eur.price,
        usd: prices.usd.price
      }
    },
    image: {
      front: frontImage,
      back: backImage
    },
    link: url
  }

  let productData;
  if(currentColour.name) {
    productData = currentColour;
  } else {
    productData = defaultData;
  }

  let priceComponent = <Currency
    usd={productData.price.sale.usd}
    eur={productData.price.sale.eur}
    regularPriceUsd={productData.price.regular.usd}
    regularPriceEur={productData.price.regular.eur}
    onSale={productData.price.onSale}
  />

  if (use_recommended_products_price) {
    priceComponent = (
      <div className={cn(styles.currencyContainer)}>
        <span className={cn(styles.currency)}><Currency usd={recommended_products_price_usd} eur={recommended_products_price_eur} /></span>
        <span className={cn(styles.currency, styles.originalPrice)}><Currency usd={prices.usd.price} eur={prices.eur.price} /></span>
      </div>
    );
  }

  function changeColour(e, data) {
    e.preventDefault();
    setCurrentColour(data);
  }

  short_description = striptags(short_description) || '';

  let bulletPoints = '<ul class="' + styles.bulletpoints + '">';
  const matches = striptags(short_description).match(/^\* (.+)/gm) || [];
  matches.forEach(match => {
    const subGroups = match.match(/^\* (.+)/) || [];
    if(subGroups.length && subGroups[1]) {
      bulletPoints += '<li>' + subGroups[1] + '</li>';
    }
  });
  bulletPoints += '</ul>';

  if(bulletPoints.match(/<li>/g)) {
    let originalDesc = short_description;
    for(let i = 0; i < (matches.length - 1); i++) {
      originalDesc = originalDesc.replace(/^\* (.+)/m, '');

    }

    originalDesc = originalDesc.replace(/^\* (.+)/m, bulletPoints);
    short_description = originalDesc;
  }

  return (
    <article className={cn('item', className)}>
      {isPlaceholder ? (
        <div />
      ) : (
        <div className={cn('product-container')}>
          <LinkOrDiv isDiv={noLink} className={cn('content')} to={productData.link}>
            <div className={cn('images')}>
              {(productData.image.back) && (
                <img
                  src={productData.image.back}
                  className={cn('image-back')}
                  alt="Product Back"
                  decoding="async"
                />
              )}
              {(productData.image.front) && (
                <img
                  src={productData.image.front}
                  className={cn('image-front')}
                  alt="Product Front"
                  decoding="async"
                />
              )}
            </div>
            <div className={cn(styles.specialNotice, {
              [styles.noSpecialNotice]: productData.special_notice.text.length === 0
            })} style={{
              backgroundColor: productData.special_notice.background_colour,
              color: productData.special_notice.text_colour
            }}>
              {productData.special_notice.text}
            </div>
            <h3 className={cn('title')}>{productData.name}</h3>
            <div className={cn('price-row')}>
              {priceComponent}
            </div>
            <div className={cn('description')} dangerouslySetInnerHTML={{__html: short_description}} />
          </LinkOrDiv>
          {Array.isArray(colour_variations) && colour_variations.length && (
            <div className={cn('colour-swatch-row')}>
              <ColourSwatches productIds={colour_variations} onSwatchChange={changeColour} />
            </div>
          )}
          <div className={cn('info-row')}>
            {info_field && info_field.length > 0 ? (
              <Info
                label={info_field[0].label}
                value={
                  info_field[0].acf_fc_layout === 'text' ? (
                    info_field[0].value
                  ) : (
                    <span
                      style={{ background: info_field[0].color }}
                      className={cn('color-box')}
                    />
                  )
                }
              />
            ) : (
              <Info label="" value="" />
            )}
          </div>
        </div>
      )}
    </article>
  )
}

function LinkOrDiv({ isDiv, className, to, children }) {
  return isDiv ? (
    <div className={className}>{children}</div>
  ) : (
    <Link className={className} to={to}>{children}</Link>
  );
}
