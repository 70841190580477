import React from 'react'
import styles from './components.module.css'
import classNames from 'classnames/bind'
import Link from './Link'

const cn = classNames.bind(styles)

export default ({ children, url, className, ...props }) =>
  url
    ? <Link className={cn('button', className)} {...props} to={url}>{children}</Link>
    : <button className={cn('button', className)} {...props}>
        {children}
      </button>
