import superagent from 'superagent'

const get = path =>
  superagent
    .get(`/api/wp/${path}`)
    .then(res => {
      if (res.status && res.status === 200) {
        return JSON.parse(res.text) || []
      }

      return []
    })
    .catch(err => {
      console.log({ err })
      console.log('client_error_wordpress_api', err)
      return []
    })

export default { get }
