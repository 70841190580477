import React from 'react'
import styles from './hero-image.module.css'
import Button from "../../Button";

export default class extends React.Component {
    render() {
      let heading = this.props.heading;
      let buttons = this.props.button || [];
      let cls = `${styles.textContainer}`;
      let innerCls = `${styles.innerContainer}`;
      let newStyleCss = {};

      if (this.props.text_color === 'black') {
        cls += ` ${styles.textContainerBlack}`
      }

      if(this.props.newStyle) {
        heading = this.props.new_heading;
        cls += ` ${styles.newStyle}`
        innerCls += ` ${styles.newStyle}`;

        if(this.props.new_heading_font_size) {
         newStyleCss.fontSize =  this.props.new_heading_font_size + 'px';
        }
      } else {
        cls += ` ${styles.oldStyle}`
      }

      return <div className={styles.container}>
            {this.props.video ? <video muted playsInline loop autoPlay poster={this.props.image.url}>
                <source src={this.props.video.url} type="video/mp4"/>
            </video> : <img src={this.props.image.url} alt={this.props.image.alt}/>}

        <div className={cls}>
                <div className={innerCls}>
                  {this.props.newStyle ? (
                    <div style={newStyleCss} dangerouslySetInnerHTML={{__html: heading}} />
                  ) : (
                    <h2>{heading}</h2>
                  )}
                  <p>{this.props.description}</p>
                    {buttons.map((btn, idx) => {
                        return <Button key={idx} url={btn.url}>
                            {btn.label}
                        </Button>;
                    })}
                </div>
            </div>
        </div>;
    }
}
