import React from 'react'
import styles from './related-products.module.css'
import Wrap from '../../Wrap'
import Grid from '../Grid'
import cn from 'classnames'
import { observer } from 'mobx-react'

function RelatedProducts({ store, items, title, should_center, className }) {
  const mainStore = store.store

  if (!Array.isArray(items) || items.length === 0) {
    return null
  }

  const products = items
    .map(id => mainStore.products.find(product => product.id === id))
    .filter(product => product != null)

  return (
    <div className={cn(styles.container, className)}>
      <Wrap className={styles.wrap}>
        {title && <h3 className={styles.title}>{title}</h3>}
      </Wrap>
      <Grid items={products} shouldCenter={should_center} />
    </div>
  )
}

export default observer(RelatedProducts)
