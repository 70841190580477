import React from 'react'
import styles from './excerpt.module.css'
import classNames from 'classnames/bind'
import { Link, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Title from '../../Title'
import Date from '../../Date'

const cn = classNames.bind(styles)

const Excerpt = ({
  id,
  title,
  slug,
  date,
  content,
  excerpt,
  match,
  ...props
}) =>
  <article className={cn('excerpt')}>
    <Link to={`${match.path}/${slug}`}>
      <Date>{date}</Date>
      <Title tag="h3">{title.rendered}</Title>
      <p
        className={cn('content')}
        dangerouslySetInnerHTML={{
          __html: excerpt.rendered
        }}
      />
    </Link>
  </article>

export default withRouter(observer(Excerpt))
