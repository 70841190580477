import React from 'react';
import {BuyButton, BuyVariationButton} from "../Products/Product/Hero";
import {LoadingSpinner} from "../LoadingSpinner";
import Button from "../Button";
import buyButtonStyles from '../Products/Product/hero.module.css'
import ConfigurationButton from '../ConfigurationButton';
import LinkedConfigurableProduct from "../LinkedConfigurableProduct";
import classNames from "classnames/bind";

const cn = classNames.bind(buyButtonStyles);

export default ({
  product,
  isLoading,
  button,
  isExtraProduct,
  attribute,
  variationProducts,
  link,
  linkText,
  cart,
  extraClasses
}) => {
  const {
    manage_stock,
    stock_status,
    stock_quantity,
    backorders,
    isConfigurable,
    acf,
  } = product;

  const {
    show_both_configure_and_buy,
    showConfiguratorLink,
    configuratorLink,
    availability,
    buttonText,
    btn_fade
  } = acf;

  let { didGetStockQuantity } = product;

  if(typeof didGetStockQuantity === "undefined"){
    didGetStockQuantity = stock_status === "instock";
  }

  const fadeStylePrimary = btn_fade && btn_fade.type === "fade" && (btn_fade.button === "primary" || btn_fade.button === "both") ?
    {backgroundImage: `linear-gradient(to right, ${btn_fade.first}, ${btn_fade.second})`}
  : {};

  const fadeStyleConfig = btn_fade && btn_fade.type === "fade" && (btn_fade.button === "config" || btn_fade.button === "both") ?
    {backgroundImage: `linear-gradient(to right, ${btn_fade.first}, ${btn_fade.second})`}
  : {};

  return (
    <div className={cn(buyButtonStyles.buttonRow, extraClasses)}>
      {(availability === "coming_soon") ? (
        <Button className={buyButtonStyles.button} style={fadeStylePrimary}>Coming Soon</Button>
      ) : (
        (!didGetStockQuantity || isLoading) ? (
          <BuyButton isLoading>
            <LoadingSpinner />
          </BuyButton>
        ) : (isConfigurable && !show_both_configure_and_buy) ? (
          <ConfigurationButton style={fadeStylePrimary} product={product} />
        ) : button && button.url ? (
          <BuyButton style={fadeStylePrimary} url={button.url} isExtraProduct={isExtraProduct}>{button.label}</BuyButton>
        ) : attribute ? (
          <React.Fragment>
            <BuyVariationButton
              attribute={attribute}
              variations={variationProducts}
              product={product}
              isExtraProduct={isExtraProduct}
              style={fadeStylePrimary}
            />
          </React.Fragment>
        ) : (
          manage_stock ? stock_quantity > 0 : stock_status === 'instock'
        ) ? (
          <BuyButton product={product} isExtraProduct={isExtraProduct} style={fadeStylePrimary} />
        ) : (
          manage_stock
            ? backorders === 'yes'
            : stock_status === 'onbackorder'
        ) ? (
          <BuyButton product={product} isExtraProduct={isExtraProduct} style={fadeStylePrimary}>Add to cart (pre-order)</BuyButton>
        ) : (
          link ?
            <Button className={buyButtonStyles.button} url={link} style={fadeStylePrimary}>{linkText}</Button>
            : null )
      )}
      {(showConfiguratorLink && configuratorLink && !isExtraProduct) && (
        <div className={buyButtonStyles.buttonRow}>
          <LinkedConfigurableProduct
            cart={cart}
            productId={configuratorLink}
            buttonText={buttonText}
            style={fadeStyleConfig} />
        </div>
      )}
      {(show_both_configure_and_buy && isConfigurable && !isExtraProduct) && (
        <div className={buyButtonStyles.buttonRow}>
          <ConfigurationButton product={product} style={fadeStyleConfig} />
        </div>
      )}
    </div>
  );
}
