import React, { Component } from 'react'
import styles from './page.module.css'
import classNames from 'classnames/bind'
import { observer } from 'mobx-react'
import Wrap from '../Wrap'
import Product from '../Products/Product'

const cn = classNames.bind(styles)

class Page extends Component {
  render() {
    const { store, helmet, location, title } = this.props
    const { content, acf } = store

    return (
      <div className={cn('page')}>
        {helmet}
        {acf && acf.content ? (
          <Product
            noHelmet
            location={location}
            store={store}
            data={{
              acf,
              short_description: title && title.rendered
            }}
          />
        ) : (
          <Wrap className={cn('wrap')}>
            {content &&
              <div
                className={cn('content')}
                dangerouslySetInnerHTML={{
                  __html: content.rendered
                }}
              />}
          </Wrap>
        )}
      </div>
    )
  }
}

export default observer(Page)
