import React from 'react'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import App from '../common/App'

const Root = ({ store }) => (
  <BrowserRouter>
    <App store={store} />
  </BrowserRouter>
)

export default Root
