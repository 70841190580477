import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const getDomain = hostname => {
  return hostname
    .split('.')
    .slice(-2)
    .join('.')
    .replace('www.', '')
}

export default class Link extends Component {
  state = { url: null, to: null }

  parseTo = (to) => {
    if (!to) return

    const tmp = document.createElement('a')
    tmp.href = to

    // The url is external, use the provided url as is.
    if (getDomain(window.location.hostname) !== getDomain(tmp.hostname)) {
      this.setState({ url: to, to: null })
      return
    }

    const internal = tmp.pathname.replace(/wp\/((product|category)\/)*/, '')

    this.setState({
      to: internal,
      url: null
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // If we have a state, we know we're in client land, so it's safe to
    // call parseTo again.
    if (!this.state.to && !this.state.url) return

    if (nextProps.to !== this.props.to) {
      this.parseTo(nextProps.to)
    }
  }

  componentDidMount () {
    this.parseTo(this.props.to)
  }

  render () {
    const { children, ...props } = this.props

    if (this.state.url) {
      return <a href={this.state.url} {...props}>{children}</a>
    }

    if (this.state.to) {
      return (
        <RouterLink {...props} to={this.state.to}>
          {children}
        </RouterLink>
      )
    }

    // Still doesn't know if the url is internal or external,
    // so we just render it as it came.
    return <a href={this.props.to} {...props}>{children}</a>
  }
}
