import React, { Component } from 'react'
import styles from './home.module.css'
import classNames from 'classnames/bind'
import Carousel, { CarouselItem } from './Carousel'
import Link from '../../components/Link'
import Media from '../../components/Media'
import { observer } from 'mobx-react'
import Wrap from '../../components/Wrap'
import Promo from './Promo'
import QuickInfo from "../../components/Products/Product/QuickInfo";
import InfoBoxes from "../../components/Products/Product/InfoBoxes";
import TechSpecs from "../../components/Products/Product/TechSpecs";
import Reviews from "../../components/Products/Product/Reviews";
import Videos from "../../components/Products/Product/Videos";
import Gallery from "../../components/Products/Product/Gallery";
import FAQ from "../../components/Products/Product/FAQ";
import Html from "../../components/Products/Product/Html";
import RelatedProducts from "../../components/Products/Product/RelatedProducts";
import TrendingProducts from "../../components/Products/Product/TrendingProducts";
import Ticker from "../../components/Products/Product/Ticker";
import HeroImage from "../../components/Products/Product/HeroImage";
import GalleryGrid from "../../components/Products/Product/GalleryGrid";
import NewsFeed from "../../components/NewsFeed";
import TextRow from "../../components/TextRow";
import AnchorLink from "../../components/AnchorLink";
import AnchorMenu from "../../components/AnchorMenu";

const Layouts = {
    hero_image: HeroImage,
    quick_info: QuickInfo,
    info_box: InfoBoxes,
    tech_specs: TechSpecs,
    reviews: Reviews,
    videos: Videos,
    gallery: Gallery,
    gallery_grid: GalleryGrid,
    faq: FAQ,
    html: Html,
    related_products: RelatedProducts,
    trending_products: TrendingProducts,
    ticker: Ticker,
    newsfeed: NewsFeed,
    text_row: TextRow,
    anchor_link: AnchorLink
}
const cn = classNames.bind(styles)

const getHtmlWithoutWrapper = html => {
  const match = html.match(/^<p>(.*)<\/p>/)

  if (!match) {
    return html
  }

  return match[1]
}

const Content = ({ caption, text, button }) =>
  <div className={cn('content')}>
    <Wrap className={cn('wrap')}>
      {caption &&
        <h2
          className={cn('carousel-caption')}
          dangerouslySetInnerHTML={{
            __html: getHtmlWithoutWrapper(caption)
          }}
        />}
      {text &&
        <h3
          className={cn('carousel-text')}
          dangerouslySetInnerHTML={{
            __html: getHtmlWithoutWrapper(text)
          }}
        />}
      {button &&
        <Link className={cn('read-more')} to={button[button.acf_fc_layout]}>
          {button.label}
        </Link>}
    </Wrap>
  </div>

class Home extends Component {
  render() {
    const { store, helmet } = this.props
    const {
      content = [],
      carousel = [],
      promotions = [],
      ...carouselSettings
    } = store.acf || {}

    let prevAcfLayout = '';

    return (
      <div className={classNames('home')}>
        <AnchorMenu />
        {helmet}
        {Array.isArray(content) ? content.map((c, idx) => {
              const { acf_fc_layout, ...contentProps } = c
              const nextItem = idx < content.length - 1 && content[idx + 1]
              const isLastOfType = nextItem.acf_fc_layout !== acf_fc_layout
              const Component = Layouts[acf_fc_layout]

              let additionalClasses = cn({ 'last-of-type': isLastOfType });

              switch(acf_fc_layout) {
                case 'text_row':
                  if(nextItem.acf_fc_layout === 'related_products') {
                    additionalClasses += ' ' + cn('related-products-next');
                  }
                  break;
                case 'related_products':
                  if(prevAcfLayout === 'text_row') {
                    additionalClasses += ' ' + cn('text-row-prev');
                  }
                  break;
                default:
                  break;
              }

              prevAcfLayout = acf_fc_layout;

              if (!Component) {
                  return null
              }

              return (
                  <Component
                      {...store}
                      {...contentProps}
                      key={idx}
                      className={additionalClasses}
                      store={store}
                  />
              )
          }) : null}

        {/*<Carousel {...carouselSettings}>
          {carousel.map((item, idx) =>
            <CarouselItem key={idx}>
              {item.media.length > 0 &&
                <Media
                  type={item.media[0].acf_fc_layout}
                  src={item.media[0][item.media[0].acf_fc_layout]}
                />}
              <Content
                caption={item.caption}
                text={item.text}
                button={item.button.length && item.button[0]}
              />
            </CarouselItem>
          )}
        </Carousel>*/}
        <section className={cn('promos')}>
          {promotions.map(
            (promo, idx) => (idx < 4 ? <Promo key={idx} {...promo} /> : null)
          )}
        </section>
      </div>
    )
  }
}

export default observer(Home)
