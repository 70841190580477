import React from 'react'
import {computed, decorate, extendObservable, observable} from 'mobx'
import Footer from './footer'
import {PostCategory} from './PostCategory'
import {Page} from './Page'
import {woo} from "../api";

export default class Store {
  constructor({ pages = [], postCategory, productCategory, device } = {}) {
    extendObservable(this, {
      categories: observable.map({}),
      pages: observable.map({}),
      footer: new Footer(),
      device,
    })

    pages.forEach(({ slug, ...data }) => {
      const page = this.getPage(slug)
      page.setData(data)

      // If we're on a product page, the server will already have given us
      // the products for that page on the initial render. So we add those
      // to the page store.
      if (productCategory) {
        const { category_connection } = data.acf || {}

        if (category_connection === productCategory.id) {
          page.setItems(productCategory.items)
        }
      }
    })

    if (postCategory) {
      this.setPostCategory(postCategory)
    }
  }

  static getProductsById = async ids => {
    let allProducts = [];

    if(Array.isArray(ids)){
      if(ids.length === 0) return [];

      if(ids[0] !== "variations"){
        let idsStr = ""
        ids.forEach(id => idsStr += id + ",");
        return await woo.get(`products/?include=${idsStr}`);
      } else {
        for(let i = 1; i < ids.length; i++){
          allProducts = allProducts.concat(await woo.get(`products/${ids[i]}`));
        }

        return allProducts;
      }
    }

    allProducts[0] = await woo.get(`products/${ids}`);
    return allProducts;
  }

  static getProductStockStatus = async id => {
    if(isNaN(id)) {
      return { stock_status: 'error' };
    }

    // We need to query for variations, too, because the goaffpro plugin expects it
    return await woo.get(`products/${id}?_fields=stock_status,variations`);
  }

  get productPages() {
    const pages = []

    this.pages.forEach((page) => {
      if (page.productCategoryId != null) {
        pages.push(page)
      }
    })

    return pages
  }

  get products() {
    return this.productPages.reduce(
      (products, page) => [...products, ...page.items],
      []
    )
  }

  /**
   * Get initial data (client only).
   */
  getInitialData = (routes) => {
    // Product categories.
    routes.forEach(({ slug, path }) => {
      this.getPage(slug || path.substring(1)).getProducts()
    })
  }

  setPostCategory = ({ id, items }) => {
    const category = this.getPostCategory(id)
    category.setPosts(items)
  }

  getPostCategory = (id) => {
    let category = this.categories.get(id)

    if (!category) {
      category = new PostCategory(this, id)
      this.categories.set(id, category)
    }

    return category
  }

  getPage = (slug) => {
    let page = this.pages.get((slug || '').toLowerCase())

    if (!page) {
      page = new Page(this, slug)
      this.pages.set(slug, page)
    }

    return page
  }

  static fromJS() {
    return new Store()
  }
}

decorate(Store, {
  products: computed,
  productPages: computed,
})

const StoreContext = React.createContext()

export const StoreProvider = ({ children, store }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
)

export const useStore = () => {
  return React.useContext(StoreContext)
}
