import React, { Component } from 'react'
import styles from './videos.module.css'
import classNames from 'classnames/bind'
import YoutubePlayer from 'react-youtube'
import Measure from 'react-measure'
import { MdPlayArrow } from 'react-icons/md'

const cn = classNames.bind(styles)

const getId = (url) => {
  const match = url.match(
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  )
  return match && match.length >= 2 && match[2].length === 11 ? match[2] : null
}

class Video extends Component {
  state = { didStart: false }

  render() {
    const { width: _width, url, isOnly } = this.props
    const id = getId(url)

    if (!id) {
      return null
    }

    const height = ((_width / (isOnly ? 1 : 2) + (isOnly ? 0 : -10)) * 9) / 16
    const width = isOnly ? _width : _width / 2 - 10

    return (
      <div className={styles.videoContainer} style={{ height, width }}>
        <img
          src={`https://i.ytimg.com/vi/${id}/maxresdefault.jpg`}
          style={{ width, height }}
          className={styles.thumbnail}
          alt='YouTube Thumbnail'
        />
        <button
          className={styles.playButton}
          onClick={() => {
            this.setState({ didStart: true })
          }}
        >
          <MdPlayArrow />
        </button>
        {this.state.didStart ? (
          <div className={styles.video}>
            <YoutubePlayer
              videoId={id}
              opts={{
                height,
                width,
                showinfo: 0,
                rel: 0,
                controls: 1,
                playerVars: {
                  autoplay: 1,
                },
              }}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default class Videos extends Component {
  render() {
    const { items, className } = this.props
    const background_colour = this.props.background_colour || '#F6F6F6';

    return (
      <div className={cn('videos', className)} style={{backgroundColor: background_colour}}>
        <Measure whitelist={['width']}>
          {({ width }) => (
            <div className={cn('content')}>
              {items.map(({ url }, idx) => (
                <Video
                  key={idx}
                  url={url}
                  width={width}
                  isOnly={items.length === 1}
                />
              ))}
            </div>
          )}
        </Measure>
      </div>
    )
  }
}
