import React, {useState} from 'react';
import Select from './Select'
import { withRouter } from 'react-router-dom'
import {getCountryCurrency, getCountryName} from './Checkout/utils/countries'
import classNames from "classnames/bind";
import styles from "./country-selector.module.css";
import superagent from "superagent";

const cn = classNames.bind(styles)

const CountrySelector = withRouter(({cart}) => {

  const [countries, setCountries] = useState([]);

  if (countries.length === 0) {
    superagent
      .get('/api/countries')
      .end((err, res) => {
        setCountries(res.body)
      })
  }

  countries.sort(function(a, b) {
    return getCountryName(a).localeCompare(getCountryName(b));
  })

  return (
    <section id="country-selector" className={cn('section')} label="Select Country">
    <h1>Select Country</h1>
      <Select
        required
        label="Select Country"
        onChange={value => {
          const currencyType = getCountryCurrency(value)
          const country = { code: value, name: getCountryName(value), currencyType }
          modifyPcConfig(currencyType);
          cart.dispatch({ type: 'set_country', country })
        }}
        value={cart.country ? cart.country.code : ''}
        options={countries.map(country => ({
          label: getCountryName(country),
          value: country,
          data: {
            currencyType: getCountryCurrency(country)
          }
        }))}
      />
    </section>
  )
})

function modifyPcConfig(currencyType) {
  let currency = '&euro;';
  if(currencyType === 'USD') currency = '&#36;';

  PC_config = {
    "ajaxurl": "/wp/wp-admin/admin-ajax.php",
    "lang": {
      "money_precision": 2,
      "money_symbol": currency,
      "money_decimal": ".",
      "money_thousand": ",",
      "money_format": "%s&nbsp;%v",
      "required_error_message": "%s is required",
      "out_of_stock_error_message": "%s is out of stock"
    },
    "config": {
      "inline": false,
      "where": "out",
      "bg_image": "/wp/wp-content/plugins/product-configurator-for-woocommerce/assets/images/default-bg.jpg",
      "close_configurator_on_add_to_cart": false,
      "close_choices_when_selecting_choice": false,
      "choice_description_no_tooltip": false,
      "image_loading_mode": "lazy",
      "show_choice_description": false,
      "show_layer_description": false,
      "show_active_choice_in_layer": 1,
      "angles": {"show_image": true, "show_name": true},
      "add_tax": false,
      "tax_enabled": false,
      "tax_settings": {
        "prices_include_tax": true,
        "tax_display_mode": "incl"
      }
    }
  };

  const $elements = jQuery('span.choice-price');
  $elements.each(() => {
    const $this = jQuery(this);
    let price = $this.data('price');
    if(!price) {
      price = $this.text().trim().replace(/[^0-9]/g, '');
    }

    $this.text(currencyType === 'USD' ? ('$ ' + price) : ('€ ' + price));
  });
}

export default CountrySelector
