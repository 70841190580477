import React from 'react'
import styles from './products.module.css'
import productTileStyles from './product-tile.module.css';
import classNames from 'classnames/bind'
import { observer } from 'mobx-react'
import MediaElement from '../Media'
import Grid from './Grid'
import Wrap from '../Wrap'
import HeroImage from "./Product/HeroImage";
import QuickInfo from "./Product/QuickInfo";
import InfoBoxes from "./Product/InfoBoxes";
import TechSpecs from "./Product/TechSpecs";
import Reviews from "./Product/Reviews";
import Videos from "./Product/Videos";
import Gallery from "./Product/Gallery";
import GalleryGrid from "./Product/GalleryGrid";
import FAQ from "./Product/FAQ";
import Html from "./Product/Html";
import RelatedProducts from "./Product/RelatedProducts";
import TrendingProducts from "./Product/TrendingProducts";
import Ticker from "./Product/Ticker";
import NewsFeed from "../NewsFeed";
import TextRow from "../TextRow";
import CategoryFilter from "../CategoryFilter";

const Layouts = {
  hero_image: HeroImage,
  quick_info: QuickInfo,
  info_box: InfoBoxes,
  tech_specs: TechSpecs,
  reviews: Reviews,
  videos: Videos,
  gallery: Gallery,
  gallery_grid: GalleryGrid,
  faq: FAQ,
  html: Html,
  related_products: RelatedProducts,
  trending_products: TrendingProducts,
  ticker: Ticker,
  newsfeed: NewsFeed,
  text_row: TextRow
}

const cn = classNames.bind(styles)

const CategoryPage = ({ store, helmet }) => {
  const { hero, title, slug, acf } = store
  let _products = /*slug === 'accessories' ? store.accessories :*/ store.items
  let products = _products.filter(product => !product.acf.hide_product);

  const [hasFilters, setHasFilters] = React.useState(false);
  const [emptyFilters, setEmptyFilters] = React.useState(true);
  const [uniqueFilters, setUniqueFilters] = React.useState([]);
  const [filteredProducts, setFilteredProducts] = React.useState(products);
  const [headerGroups, setHeaderGroups] = React.useState([]);

  if(!hasFilters) {
    for(let i = 0; i < products.length; i++) {
      if(products[i].filters && products[i].filters.length) {
        setHasFilters(true);
        break;
      }
    }
  }

  React.useEffect(() => {
    if(hasFilters && !uniqueFilters.length) {
      let filterPromises = [];
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        if(product.filters && Array.isArray(product.filters)) {
          filterPromises.push(CategoryFilter.addStockStatusFilterToProduct(product.filters, product.id));
        }
      }

      Promise.all(filterPromises).then(results => {
        let allFilters = [];

        results.forEach(result => {
          const { filters, productId } = result;

          for(let i = 0; i < products.length; i++) {
            if(products[i].id !== productId) continue;

            products[i].filters = filters;

            for(let j = 0; j < filters.length; j++) {
              const filter = filters[j];
              const exists = allFilters.some(addedFilter => addedFilter.id === filter.id);

              if(!exists) allFilters.push(filter);
            }
          }
        });

        setUniqueFilters(allFilters);
      });
    }
  }, [hasFilters]);

  const groups = Array.isArray(acf && acf.groups)
    ? acf.groups
        .filter(group => group.tag != null)
        .map(group => ({
          title: group.tag.name,
          description: group.description,
          products: products.filter(product =>
            product.tags.some(tag => tag.id === group.tag.term_id)
          )
        }))
        .filter(group => Array.isArray(group.products) && group.products.length)
    : null;

  if (groups && groups.length > 0) {
    const groupedProducts = groups.reduce(
      (products, group) => [...products, ...(group.products || [])],
      []
    )

    const ungrouped = products.filter(
      product => !groupedProducts.includes(product)
    )

    if (ungrouped.length > 0) {
      groups.push({
        title: 'Others',
        products: ungrouped
      })
    }
  }

  const filteredGroups = Array.isArray(acf && acf.groups) && hasFilters
    ? acf.groups
      .filter(group => group.tag != null)
      .map(group => ({
        title: group.tag.name,
        description: group.description,
        products: filteredProducts.filter(product =>
          product.tags.some(tag => tag.id === group.tag.term_id)
        )
      }))
      .filter(group => Array.isArray(group.products) && group.products.length)
    : groups;

  let prevAcfLayout = '';

  function onFilterUpdate(selectedFilters) {
    let totalFilterCategories = selectedFilters.size;

    if(!totalFilterCategories) {
      setFilteredProducts(products);
      setEmptyFilters(true);
      return;
    }

    const newProducts = products.filter(product => {
      let matchedCategories = [];

      loopingThroughNewCategories:
      for(let i = 0; i < product.filters.length; i++) {
        const filter = product.filters[i];
        for(const [categoryId, filterMap] of selectedFilters) {
          for(const [filterSlug, __] of filterMap) {
            if(filterSlug === filter.slug) {

              if(matchedCategories.indexOf(categoryId) === -1) {
                matchedCategories.push(categoryId);
              }

              continue loopingThroughNewCategories;
            }
          }
        }
      }

      return matchedCategories.length === totalFilterCategories;
    });

    setFilteredProducts(newProducts);
    setEmptyFilters(false);
  }

  React.useEffect(() => {
    setHeaderGroups(emptyFilters ? groups : filteredGroups);
  }, [filteredProducts]);

  return (
    <div className={cn('page-container')}>
      {Array.isArray(acf.header_content) ? acf.header_content.map((c, idx) => {
        const { acf_fc_layout, ...contentProps } = c
        const nextItem = idx < acf.header_content.length - 1 && acf.header_content[idx + 1]
        const isLastOfType = nextItem.acf_fc_layout !== acf_fc_layout
        const Component = Layouts[acf_fc_layout]

        let additionalClasses = cn({ 'last-of-type': isLastOfType });

        switch(acf_fc_layout) {
          case 'text_row':
            if(nextItem.acf_fc_layout === 'related_products') {
              additionalClasses += ' ' + cn('related-products-next');
            }
            break;
          case 'related_products':
            if(prevAcfLayout === 'text_row') {
              additionalClasses += ' ' + cn('text-row-prev');
            }
            break;
          default:
            break;
        }

        prevAcfLayout = acf_fc_layout;

        if (!Component) {
          return null
        }

        return (
          <Component
            {...store}
            {...contentProps}
            key={idx}
            className={additionalClasses}
            store={store}
          />
        )
      }) : null}
      <div className={cn('products')}>
        {helmet}
        {hero && (
          <header className={cn('hero')}>
            <MediaElement type={hero.type} src={hero.src} />
          </header>
        )}
        {headerGroups && headerGroups.length ? (
          <React.Fragment>
            <h2>{title}</h2>
            {headerGroups.length > 1 && (
              <Wrap as="nav" className={styles.navigation}>
                {headerGroups.map((group, idx) => (
                  <button
                    key={idx}
                    onClick={() => {
                      const groups = document.getElementById('groups')
                      const el = groups ? groups.childNodes[idx] : null

                      if (!el) {
                        return
                      }

                      const rect = el.getBoundingClientRect()
                      const top =
                        window.pageYOffset || document.documentElement.scrollTop
                      window.scroll({
                        left: 0,
                        top: rect.y - 80 + top,
                        behavior: 'smooth'
                      })
                    }}
                  >
                    {group.title}
                  </button>
                ))}
              </Wrap>
            )}
            {(hasFilters && uniqueFilters && uniqueFilters.length) ? (
              <div className={cn(styles.productsFilterGridContainer)}>
                <div className={styles.filters}>
                  <CategoryFilter.Component filters={uniqueFilters} onFilterUpdate={onFilterUpdate} />
                </div>
                <div id="groups">
                  {(filteredGroups && filteredProducts.length) ? filteredGroups.map((group, idx) => (
                    <Grid
                      isGroup
                      key={idx}
                      label={group.title}
                      description={group.description}
                      items={group.products}
                      className={styles.noMargin}
                      productTileClassName={productTileStyles.categoryPageItem}
                    />
                  )) : ( emptyFilters ?
                      (
                        (function() {
                          return groups.map((group, idx) => (
                              <Grid
                                isGroup
                                key={idx}
                                label={group.title}
                                description={group.description}
                                items={group.products}
                                className={styles.noMargin}
                                productTileClassName={productTileStyles.categoryPageItem}
                              />
                            )
                          );
                        })()
                      ) : (
                        <h1 className={styles.missingProducts}>No products found with the current filter&#8230;</h1>
                      )
                  )}
                </div>
              </div>
            ) : (
              <div id="groups">
                {groups.map((group, idx) => (
                  <Grid
                    isGroup
                    key={idx}
                    label={group.title}
                    description={group.description}
                    items={group.products}
                    productTileClassName={productTileStyles.categoryPageItem}
                  />
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          ((hasFilters && uniqueFilters && uniqueFilters.length) ? (
            <div className={cn(styles.productsFilterGridContainer)}>
              <div className={styles.filters}>
                <CategoryFilter.Component filters={uniqueFilters} onFilterUpdate={onFilterUpdate} />
              </div>
              <div id="groups">
                {(filteredProducts && filteredProducts.length) ? (
                  <Grid
                    label={title}
                    items={filteredProducts}
                    className={styles.noMargin}
                    productTileClassName={productTileStyles.categoryPageItem}
                  />
                ) : ( emptyFilters ? (
                    <Grid
                      label={title}
                      items={products}
                      className={styles.noMargin}
                      productTileClassName={productTileStyles.categoryPageItem}
                    />
                  ) : (
                    <h1 className={styles.missingProducts}>No products found with the current filter&#8230;</h1>
                  )
                )}
              </div>
            </div>
          ) : (
            <div id="groups">
              <Grid label={title} items={products} />
            </div>
          ))
        )}
      </div>
      <div>
        {Array.isArray(acf.footer_content) ? acf.footer_content.map((c, idx) => {
          const { acf_fc_layout, ...contentProps } = c
          const nextItem = idx < acf.footer_content.length - 1 && acf.footer_content[idx + 1]
          const isLastOfType = nextItem.acf_fc_layout !== acf_fc_layout
          const Component = Layouts[acf_fc_layout]

          if (!Component) {
            return null
          }

          return (
            <Component
              {...store}
              {...contentProps}
              key={idx}
              className={cn({ 'last-of-type': isLastOfType })}
              store={store}
            />
          )
        }) : null}
      </div>
    </div>
  )
}

export default observer(CategoryPage)
