import React from 'react';
import Wrap from '../Wrap'
import classNames from "classnames/bind";
import styles from './text-row.module.css';
import Measure from "react-measure";

const cn = classNames.bind(styles);

export default ({title, columns, className}) => (
  <div className={cn(styles.container, className)}>
    <Wrap className={styles.wrap}>
      {title && <h3 className={styles.title}>{title}</h3>}
    </Wrap>
    <_TextGrid columns={columns} />
  </div>
);

const _getTextColumns = (columns, columnsPerRow) => {
  if (!Array.isArray(columns)) {
    return []
  }

  const numberOfRows = Math.ceil(columns.length / columnsPerRow)
  return Array.from(
    { length: numberOfRows * columnsPerRow },
    (k, index) => columns[index] || { isPlaceholder: true }
  )
}

class _TextGrid extends React.Component {
  state = { columnsPerRow: 1 }

  onMeasure = ({ width }) => {
    const breakpoints = [0, 600, 920, 1180]
    let columnsPerRow = 0

    breakpoints.forEach((bp) => {
      if (width > bp) {
        columnsPerRow++
      }
    })

    this.setState({ columnsPerRow })
  }

  render() {
    const { columns, classNames } = this.props

    if (!Array.isArray(columns) || columns.length === 0) {
      return null
    }

    const { columnsPerRow } = this.state
    const textColumns = _getTextColumns(columns, columnsPerRow)

    return (
      <Measure onMeasure={this.onMeasure}>
        <Wrap>
          <div className={styles.items}>
            {textColumns.map((column, idx) => (
              <div className={cn(styles.item, styles['row-' + columnsPerRow])}>
                {column.is_bold ? (
                  <h3>{column.text}</h3>
                ) : (
                  <h3 className={styles.lightText}>{column.text}</h3>
                )}
              </div>
            ))}
          </div>
        </Wrap>
      </Measure>
    )
  }
}
