import React from 'react'
import styles from './cart.module.css'
import { Cart as CartContext } from '../../Cart'
import { Link } from 'react-router-dom'
import Currency from '../Currency'
import Wrap from '../Wrap'
import {CheckoutButton} from './CheckoutButton';
import Hash from 'object-hash';
import classNames from "classnames/bind";
import productTileStyles from "../Products/product-tile.module.css";
import {getPriceUsd} from "../../stores/Page";

const productTileCn = classNames.bind(productTileStyles);

const Cart = ({
                total,
                totalSale,
                vat,
                vatSale,
              })=> {
  const cart = React.useContext(CartContext);
  const { items } = cart.state;

  if (items.length === 0) {
    return (
      <div className={styles.emptyCart}>
        <h3>Your cart is empty</h3>
        <p>Go find something you like.</p>
      </div>
    )
  }

  const hasBackorder = items.some(({ configuration, item, variation }) => {
    const product = variation || item
    const { manage_stock, stock_status, stock_quantity, backorders } = product

    if(configuration) {
      for(let i = 0; i < configuration.length; i++) {
        if(configuration[i].has_backorder) {
          return true;
        }
      }
    }

    if (!manage_stock) {
      return stock_status === 'onbackorder'
    }

    return Number(stock_quantity) <= 0 && backorders === 'yes'
  })

  let countryCode = cart.country ? cart.country.code : '';

  return (
    <div>
      <div className={styles.cart}>
        <Wrap>
          <div className={styles.items}>
            {items.filter(item => !!item.item).map(item => {
              let key = item.item.id + (item.variation ? item.variation.id : '');

              if (item.configuration) {
              key += Hash.sha1(item.configuration);
            }

            return (
              <Item
                item={item.item}
                count={item.count}
                variation={item.variation}
                configuration={item.configuration}
                key={key}
                cart={cart}
              />
              );
            })}
          </div>
          <div className={styles.cost}>
            <section className={styles.subtotal}>
              <div>
                <h2>Subtotal</h2>
                <div className={styles.subtotalPrice}>
                  {cart.subtotalSale ? (
                    <Currency eur={cart.subtotalSale} usd={cart.subtotalSale} regularPriceUsd={cart.subtotal} regularPriceEur={cart.subtotal} onSale={true} />
                  ) : (
                    <Currency eur={cart.subtotal} usd={cart.subtotal} onSale={false} />
                  )}
                </div>
              </div>
            </section>
            <section className={styles.total}>
              <div>
                <h2>Total</h2>
                <div className={styles.totalPrice}>
                  <span>{cart.currency}</span>
                  {totalSale ? (
                    <Currency eur={totalSale} usd={totalSale} regularPriceUsd={total} regularPriceEur={total} onSale={true} totalSale={true} />
                  ) : (
                    <Currency>{total}</Currency>
                  )}
                </div>
              </div>
              {cart.tax && (
                <p className={styles.tax}>
                  Including <Currency>{totalSale ? vatSale : vat}</Currency>
                  <span> {cart.tax.name || 'VAT'}</span>
                </p>
              )}
            </section>
            {hasBackorder && (
              <p className={styles.backorderInfo}>
                Your cart contains pre-order items. The order will be shipped once
                all items are in stock.
              </p>
            )}
          </div>

          <div className={styles.bottom}>
            <CheckoutButton items={items} currency={cart.currency} countryCode={countryCode} />
          </div>
        </Wrap>
      </div>
    </div>
  )
}

export function Item({ item, count, cart, variation, configuration }) {
  const { recommendedProductsData } = cart.state;
  const { name, acf, permalink } = item
  const product = variation || item

  const { images, image_front, hide_product, configuration_image } = acf || {}
  const frontImage = (images ? images.image_front : false) || image_front;

  const url = permalink && permalink.replace(/^.*\/wp\/product/, '')
  const attribute =
    Array.isArray(product.attributes) &&
    product.attributes.length > 0 &&
    product.attributes[0]

  const usdPrices = getPriceUsd(product);

  if(typeof product.price_usd === "undefined") {
    product.price_usd = usdPrices.regular;
    item.price_usd    = product.price_usd;
  }

  if(typeof product.sale_price_usd === "undefined") {
    product.sale_price_usd = usdPrices.sale;
    item.sale_price_usd    = product.sale_price_usd;
  }

  if (product.price_usd <= 0) product.price_usd = '1';

  const total = (price, configuration) => {
    let total = Number(price);

    if(!configuration) return total;

    configuration.forEach(config => {
      if (config.extra_price) {
        total += Number(config.extra_price);
      }
    })

    return total;
  }

  const priceUsd   = total(product.price_usd, configuration);
  const priceEur   = total(product.on_sale ? product.regular_price : product.price, configuration);
  let salePriceUsd = null;
  let salePriceEur = null;

  let currencyComponent = <Currency usd={priceUsd} eur={priceEur} onSale={false} inCart={true} />;

  if(product.on_sale) {
    salePriceUsd = total(product.sale_price_usd, configuration, true);
    salePriceEur = total(product.price, configuration, false);

    currencyComponent = <Currency usd={salePriceUsd} eur={salePriceEur} regularPriceEur={priceEur} regularPriceUsd={priceUsd} onSale={true} inCart={true} />;
  }

  React.useEffect(() => {
    if(typeof recommendedProductsData !== "undefined") {
      const recKey = 'prod_' + product.id;

      if(typeof recommendedProductsData[recKey] !== "undefined"){
        item.recommended_products_price = recommendedProductsData[recKey].price.eur;
        item.recommended_products_price_usd = recommendedProductsData[recKey].price.usd;
        item.recommendedBy = recommendedProductsData[recKey].recommendedBy;
        item.use_recommended_products_price = recommendedProductsData[recKey].useDiscount;
      }
    }
  }, [recommendedProductsData])


  if(item.use_recommended_products_price){
    currencyComponent = (
      <div className={productTileCn(productTileStyles.currencyContainer)}>
          <span className={productTileCn(productTileStyles.currency)}>
            <Currency usd={item.recommended_products_price_usd} eur={item.recommended_products_price} />
          </span>
        <span className={productTileCn(productTileStyles.currency, productTileStyles.originalPrice)} style={{ fontSize: 14 + "px"}}>
            <Currency usd={product.price_usd} eur={product.price} />
          </span>
      </div>
    );
  }

  const title = `${name}${attribute ? (typeof attribute.option === "undefined" ? '' : ` (${attribute.option})`) : ''}`

  const productImage = configuration ? (configuration_image ? configuration_image : frontImage) : frontImage;

  return (
    <div className={styles.item}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${productImage})` }}
        title={title}
      />
      <div className={styles.title} title={title}>
        {url && !hide_product ? (
          <Link to={url} className={styles.titleLink}>
            {title}
          </Link>
        ) : (
          <div className={styles.titleLink}>{title}</div>
        )}
        {product.stock_quantity <= 0 &&
        product.backorders === 'yes' && (
          <p className={styles.preOrderText}>Pre-order</p>
        )}
        {configuration && (
          <ul className={styles.configurationList}>{configuration.filter(config => config.is_choice).map(config => {
            return (
              <li key={Hash.sha1(config)}>{config.layer_name}: {config.name}
                {config.extra_price ? <Currency eur={config.extra_price} usd={config.extra_price} addon={true} /> : ''}
                {config.has_backorder ? ' — PRE-ORDER' : null}
              </li>
            )
          })}</ul>
        )}
      </div>
      <div className={styles.quantityWrapper}>
        <select
          className={styles.quantity}
          value={count}
          onChange={evt => {
            const count = Number(evt.target.value)
            cart.dispatch({ type: 'set_count', item, variation, count, configuration, currency: cart.currency })
          }}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
        {count === 0 && (
          <button
            className={styles.updateQuantityButton}
            onClick={() => {
              cart.dispatch({ type: 'remove', item, variation, configuration })
            }}
          >
            Remove
          </button>
        )}
      </div>
      <div className={styles.price}>
        {currencyComponent}
      </div>
    </div>
  )
}

export default Cart
