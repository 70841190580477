import React from 'react'

export default ({ fill = 'currentColor', height, ...props}) => (
  <svg height={height} {...props}
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 283.5 283.5"
    viewBox="0 0 283.5 283.5"
  >
    <g className="st0" style={{display: 'none'}}>
      <path d="M0 0H283.5V283.5H0z" className="st1" style={{display: 'inline'}}></path>
    </g>
    <g>
      <path d="M86.9 151.3L99.3 151.3 99.3 182.3 105.3 182.3 105.3 151.3 117.7 151.3 117.7 146.2 86.9 146.2z"></path>
      <path d="M173.1 182.3L179.2 182.3 179.2 169.3 193.5 169.3 193.5 164.2 179.2 164.2 179.2 151.3 196.5 151.3 196.5 146.2 173.1 146.2z"></path>
      <path d="M81.3 146.2L73.7 146.2 61.6 164.2 59.6 164.2 47.5 146.2 39.9 146.2 52 164.2 56.9 164.2 44.8 182.3 52.4 182.3 60.6 170.1 68.8 182.3 76.4 182.3 64.3 164.2 69.2 164.2z"></path>
      <path d="M236 146.2L223.8 164.2 221.9 164.2 209.8 146.2 202.2 146.2 214.3 164.2 219.7 164.2 219.7 182.3 226 182.3 226 164.2 231.4 164.2 243.6 146.2z"></path>
      <path d="M159 157.6c0-3.3-1.2-6-3.7-8.2-2.5-2.2-5.6-3.2-9.5-3.2h-15.3v36.1h6.1v-12.9h5.9l8.7 12.9h7.6l-9-13.4c2.1-.5 4-1.4 5.5-2.8 2.5-2.1 3.7-5.2 3.7-8.5zm-13.2 6.6h-9.2v-12.9h9.2c4.1 0 6.9 2.6 6.9 6.3.1 3.5-2.8 6.6-6.9 6.6z"></path>
      <g>
        <path
          d="M96.9 101.4L96.9 136 85.6 136 85.6 123.7 71.9 123.7 71.9 136 60.7 136 60.7 101.4 71.9 101.4 71.9 114.1 85.6 114.1 85.6 101.4z"
          className="st2" style={{fill: '#E50043'}}
        ></path>
        <path
          d="M244.3 136h-12.2v-7.4c0-.9-.2-1.7-.7-2.5-4.7-8.2-9.5-16.4-14.2-24.6H230c2.6 4.5 5.2 8.9 7.7 13.4.3.4.6.4.9 0 2.6-4.5 5.1-8.9 7.7-13.4h12.8c-4.7 8.2-9.5 16.4-14.2 24.6-.5.8-.7 1.6-.7 2.5.1 2.5.1 4.9.1 7.4z"
          className="st2" style={{fill: '#E50043'}}
        ></path>
        <path
          d="M134.5 109.5L134.5 101.4 101.9 101.4 101.9 136 135.3 136 135.3 127.9 113.1 127.9 113.1 122.6 127.2 122.6 127.2 115.1 113.1 115.1 113.1 109.5z"
          className="st2" style={{fill: '#E50043'}}
        ></path>
        <path
          d="M43.9 127.1h12.3v8.9H41.5c-9.6 0-17.3-7.7-17.3-17.3 0-9.6 7.8-17.3 17.3-17.3h14.7v8.9H43.9c-4.6 0-8.4 3.8-8.4 8.4s3.8 8.4 8.4 8.4M171 124.1c9.8-7.1 4.8-22.7-7.4-22.7h-23.9V136h10.6v-9h8.1c2.2 0 3.4.7 4.5 2.6.9 1.6 1.8 3.2 2.8 4.8.7 1.2 1.4 1.6 2.8 1.6h8.9c-2.2-4-4.3-7.9-6.4-11.9zm-9.9-5.5h-10.7v-9.2h10.7c2.5 0 4.6 2.1 4.6 4.6 0 2.5-2.1 4.6-4.6 4.6zM212.5 124.1c9.8-7.1 4.8-22.7-7.4-22.7h-23.9V136h10.6v-9h8.1c2.2 0 3.4.7 4.5 2.6.9 1.6 1.8 3.2 2.8 4.8.7 1.2 1.5 1.6 2.8 1.6h8.9c-2.2-4-4.3-7.9-6.4-11.9zm-10-5.5h-10.7v-9.2h10.7c2.6 0 4.6 2.1 4.6 4.6 0 2.5-2.1 4.6-4.6 4.6z"
          className="st2" style={{fill: '#E50043'}}
        ></path>
      </g>
      <path
        d="M231.4 189.9l-9.9 14.7-.1.1-.1.2c-.5.7-1.9 2.3-3.9 2.3H66.1c-2 0-3.4-1.6-3.9-2.3l-.1-.2-.1-.1-10-14.7h-7.3l12.4 18.5c2.4 3.4 6.6 3.9 8.3 4H217.8c1.6-.1 5.9-.5 8.3-4l12.4-18.5h-7.1zM226.2 75.2c-2.4-3.4-6.6-3.9-8.3-4H65.5c-1.6.1-5.9.5-8.3 4L44.8 93.7H52L61.9 79l.1-.1.1-.2c.5-.7 1.9-2.3 3.9-2.3h151.2c2 0 3.4 1.6 3.9 2.3l.1.2.1.1 9.9 14.7h7.3l-12.3-18.5z"
        className="st2" style={{fill: '#E50043'}}
      ></path>
    </g>
  </svg>
)
