import React from 'react';
import classNames from "classnames/bind";
import styles from "./newsFeed.module.css";
import NewsTile from "../NewsTile";
import Link from "../Link";
import Button from "../Button";

const cn = classNames.bind(styles);

export default function(props) {
  const acf = props.acf?.content?.filter(c => c.acf_fc_layout === 'newsfeed')[0];
  const [newsList, setNewsList] = React.useState([]);

  React.useEffect(() => {
    if(acf.show_news_feed) {
      fetch('/api/wp/latest-news?filter=' + acf.filter, {method: 'GET'}).then(rsp => rsp.json()).then(data => setNewsList(data));
    }
  }, []);

  if(acf.show_news_feed && newsList.length) {
    return (
      <div className={cn(styles.container)}>
        <h3>NEWS</h3>
        <div className={cn(styles.newsItems)}>
          {newsList.map(newsItem => {
            return <NewsTile news={newsItem} link={newsItem.link} key={newsItem.id} />;
          })}
        </div>
        <div className={cn(styles.readMore)}>
          {acf.read_all_news_link === 'news' ? (
            <Button url={'/news'}>VIEW ALL NEWS</Button>
          ) : (
            <Button url={'/press'}>VIEW ALL PRESS RELEASES</Button>
          )}
        </div>
      </div>
    );
  }

  return null;
}
