import { extendObservable } from 'mobx'
import { wp } from '../api'

export default class Store {
  constructor() {
    extendObservable(this, {
      didFetch: false,
      menus: [
        { name: 'Xtrfy' },
        { name: 'Trending Products' },
        { name: 'Social Media' }
      ]
    })
  }

  getMenus = () => {
    wp.get('menus').then(menus => {
      this.menus = menus
    })
  }
}
