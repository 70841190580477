import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import Store from '../common/stores'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-27452429-2', { debug: false })

let _bootUpData = "";
const results = document.getElementsByClassName('bootup-data');
Array.prototype.map.call(results, data => data.value).forEach(value => {
  _bootUpData += value.replace(/<SINGLE_QOUTE>/g, "'");
});

const bootupData = JSON.parse(_bootUpData);
const store = new Store(bootupData)
const container = document.getElementById('root')

if (process.env.NODE_ENV !== 'production') {
  const logger = console.log
  window.console.logger = (...args) => {
    const [arg, ...rest] = args

    if (arg) {
      logger(JSON.parse(JSON.stringify(arg)), ...rest)
    } else {
      logger(arg)
    }
  }
}

ReactDOM.hydrate(<Root store={store} />, container)
