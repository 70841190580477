import React from 'react'
import {Cart} from '../../../Cart'
import styles from './hero.module.css'
import Button from '../../../components/Button'
import Currency from '../../Currency'
import cn from 'classnames'
import Modal from '../../Modal'
import {useIsMobile} from '../../../hooks/use-is-mobile'
import Carousel from 'nuka-carousel'
import Store, {useStore} from '../../../stores'
import Link from '../../Link'
import {getUrl} from '../Grid'
import BuyButtonRow from "../../BuyButtonRow";
import RecommendedProductsModal from "../../RecommendedProductsModal";
import { parsePrices } from "../../../utils";

const Hero = (props) => {
  let {
    id,
    acf,
    images = [],
    heading,
    short_description,
    button: buttons = [],
    name = '',
    className,
    product,
    product_variations,
    linked_products,
  } = props

  const cart = React.useContext(Cart);
  const title = heading || name || ''
  const description = props.description || acf.short_description
  const button = buttons.length > 0 ? buttons[0] : null
  const attribute =
    Array.isArray(product_variations) && product_variations.length && product_variations[0]
      ? product_variations[0].attributes[0]
      : null
  const link = props.out_of_stock_link || '#';
  const linkText = props.out_of_stock_link_text || 'Out of stock';

  const prices = parsePrices(props);

  return (
    <div className={cn(styles.hero, className)}>
      <Gallery images={images} />
      <div className={styles.column}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          {short_description && (
            <h2 className={styles.shortDescription}>{short_description}</h2>
          )}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className={styles.rsp}>
              <span>
                <Currency eur={prices.eur.price} usd={prices.usd.price} onSale={prices.isOnSale} regularPriceUsd={prices.usd.regularPrice} regularPriceEur={prices.eur.regularPrice} />
              </span>
          </div>
          <BuyButtonRow
            button={button}
            attribute={attribute}
            variationProducts={product_variations}
            product={product}
            cart={cart}
            link={link}
            linkText={linkText}
          />
          {linked_products && <LinkedProducts {...linked_products} id={id} />}
          {attribute && attribute.name && (
            <p className={styles.variationsText}>
              You'll be asked to choose {attribute.name.toLowerCase()} once you
              add the product to your cart.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Gallery

const Gallery = ({ images }) => {
  const isMobile = useIsMobile()
  const [selectedIdx, setSelectedIdx] = React.useState(0)
  const { image: selectedImage, video: selectedVideo, image_fit, background_color, image_position } =
  images[selectedIdx] || {}

  const thumbnails = images.map(img => {
    if(img.image) {
      const {
        image_fit,
        image_position,
        background_color,
        image: { sizes, alt, description, title }
      } = img;

      return {
        url: sizes.medium,
        alt: alt || description || title || '',
        image_fit,
        image_position,
        background_color
      };
    }
  });

  if (isMobile) {
    return (
      <MobileGallery
        images={images}
        thumbnails={thumbnails}
        selectedImage={selectedImage}
        selectedVide={selectedVideo}
        selectedIdx={selectedIdx}
        setSelectedIdx={setSelectedIdx}
      />
    )
  }

  return (
    (selectedVideo ? (
      <div
        className={styles.column}
        style={{
          objectFit: 'contain',
          objectPosition: 'center',
          position: 'relative'
        }}
      >
        <Thumbnails
          className={styles.thumbnails + ' ' + styles.videoThumbnails}
          thumbnails={thumbnails}
          selectedIdx={selectedIdx}
          onSelect={setSelectedIdx}
        />
        <video style={{width: '100%', height: 'auto', position: 'absolute'}} muted playsInline loop autoPlay poster={selectedImage.url}>
          <source src={selectedVideo} type="video/mp4"/>
        </video>
      </div>
    ) : (
      <div
        className={styles.column}
        style={{
          backgroundImage: selectedImage ? `url(${selectedImage.url})` : null,
          backgroundSize: image_fit || 'cover',
          backgroundPosition: image_position || 'center',
          backgroundColor: background_color || 'transparent',
        }}
      >
        <Thumbnails
          className={styles.thumbnails}
          thumbnails={thumbnails}
          selectedIdx={selectedIdx}
          onSelect={setSelectedIdx}
        />
      </div>
    ))
  );
}

const Thumbnails = ({ className, thumbnails, selectedIdx, onSelect }) => {
  return (
    <div className={className}>
      {thumbnails ? thumbnails.map((img, idx) => {
        if(typeof img !== 'undefined') {
          const { url, alt, image_fit, background_color } = img;

          return (
            <button
              key={idx}
              className={cn(styles.thumbnail, {
                [styles.selected]: selectedIdx === idx,
              })}
              onClick={() => {
                onSelect(idx)
              }}
            >
              <img
                src={url}
                alt={alt}
                loading='lazy'
                style={{
                  backgroundColor: background_color || 'white',
                  objectFit: image_fit,
                  transform: image_fit === 'contain' ? 'scale(1.3)' : 'none',
                }}
              />
            </button>
          )
        }
      }) : ''}
    </div>
  );
}

const MobileGallery = ({
                         images,
                         thumbnails,
                         selectedIdx,
                         setSelectedIdx,
                         selectedImage,
                       }) => {
  const width = selectedImage ? selectedImage.sizes['large-width'] : 1024
  const height = selectedImage ? selectedImage.sizes['large-height'] : 512

  let mobileCarousel = null;

  images = images.filter(i => !!i.image);
  const hasMultipleImages = images.length > 1;

  if(hasMultipleImages) {
    mobileCarousel =
      <Carousel
        slideIndex={selectedIdx}
        afterSlide={setSelectedIdx}
        className={styles.slider}
        renderBottomCenterControls={SliderControls}
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
      >
        {images.map(
          ({ image: { sizes, alt, description, title = '' } }, idx) => {
            try {
              return (
                <img
                  key={idx}
                  className={styles.slide}
                  src={sizes.large}
                  alt={alt || description || title}
                  loading='lazy'
                />
              );
            } catch (e) {
              return '';
            }
          }
        )}
      </Carousel>
    ;
  } else {
    const { image: { sizes, alt, description, title } } = images[0];
    mobileCarousel =
      <img
        className={styles.slide}
        src={sizes.large}
        alt={alt || description || title}
        loading='lazy'
      />
    ;
  }

  return (
    <div>
      <div
        style={{
          paddingTop: `${(height / width) * 100}%`,
          height: 0,
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
          }}
        >
          {mobileCarousel}
        </div>
      </div>
      {hasMultipleImages ? (
        <Thumbnails
          className={styles.sliderThumbnails}
          thumbnails={thumbnails}
          selectedIdx={selectedIdx}
          onSelect={setSelectedIdx}
        />
      ) : ''}
    </div>
  )
}

const SliderControls = ({
                          currentSlide,
                          goToSlide,
                          slideCount,
                          slidesToScroll,
                        }) => {
  const indexes = []

  for (var i = 0; i < slideCount; i += slidesToScroll) {
    indexes.push(i)
  }

  const width = 100 / indexes.length

  return (
    <div className={styles.sliderControls}>
      {indexes.map((idx) => (
        <button
          key={idx}
          onClick={goToSlide.bind(null, idx)}
          style={{ width: `${width}%` }}
        />
      ))}
      <div
        style={{
          background: 'black',
          position: 'absolute',
          width: `${width}%`,
          height: '100%',
          left: 0,
          top: 0,
          transform: `translateX(${currentSlide * 100}%)`,
          transition: 'transform .2s ease',
          pointerEvents: 'none',
        }}
      />
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
// BuyButton

export const BuyButton = ({ product, isLoading, children, url, isExtraProduct, ...props }) => {
  const cart = React.useContext(Cart);
  const { dispatch } = cart;
  const { productAddedToCart, recommendedProducts } = cart.state;
  const [clicked, setClicked] = React.useState(false);
  const [shouldAddToCart, setShouldAddToCart] = React.useState(false);
  const [hasRecommendedProducts, setHasRecommendedProducts] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  let timeoutId = -1;

  React.useEffect(() => {
    function cleanup(){
      if(timeoutId !== -1){
        clearInterval(timeoutId);
        setShouldAddToCart(false);
      }
    }

    if(shouldAddToCart){
      dispatch({ type: 'add', item: product, currency: cart.currency });

      Store.getProductsById(product.id).then(productAddedToCart => {
        if(typeof productAddedToCart[0] !== 'undefined') {
          dispatch({type: 'productAddedToCart', productAddedToCart: productAddedToCart[0]});
        }
      });

      setClicked(true)
      timeoutId = setTimeout(() => setClicked(false), 1500);

      // Check if product added to cart is one of the recommended_products, if so, update the recommended products accordingly
      if(typeof recommendedProducts !== "undefined" && recommendedProducts.length > 0){
        let mutableArray = [...recommendedProducts];
        let hasRemoved = false;
        for(let i = 0; i < mutableArray.length; i++){
          const recProduct = mutableArray[i];
          if(recProduct.id === product.id){
            const index = mutableArray.indexOf(recProduct);
            mutableArray.splice(index, 1);
            hasRemoved = true;
            break;
          }
        }

        if(hasRemoved){
          if(mutableArray.length === 0) exitProductRecommendations();
          dispatch({type: 'recommendedProducts', recommendedProducts: mutableArray});
          return cleanup;
        }
      }

      if(!hasRecommendedProducts && !isExtraProduct){
        if(typeof productAddedToCart === 'undefined') {
          Store.getProductsById(product.id).then(fetchedProduct => {
            setRecommendedProducts(fetchedProduct[0]);
          });
        } else {
          setRecommendedProducts(productAddedToCart);
        }
      }
    }

    return cleanup;
  }, [shouldAddToCart])

  React.useEffect(() => {
    if(shouldAddToCart && hasRecommendedProducts && !isExtraProduct){
      setOpenModal(true);
    }
  }, [shouldAddToCart, hasRecommendedProducts])

  const setRecommendedProducts = _productAddedToCart => {
    if(typeof _productAddedToCart.acf.recommended_products !==  "undefined"){
      if(_productAddedToCart.acf.recommended_products.length > 0){
        Store.getProductsById(_productAddedToCart.acf.recommended_products).then(recommendedProducts => {
          dispatch({type: 'recommendedProducts', recommendedProducts});
          if(recommendedProducts.length > 0){
            setHasRecommendedProducts(true);
          }
        });
      }
    }
  }

  const addToCart = () => {
    if(shouldAddToCart){
      setShouldAddToCart(false);
    }

    setTimeout(() => setShouldAddToCart(true), 25);
  }

  const exitProductRecommendations = () => {
    setShouldAddToCart(false);
    setOpenModal(false);
  }

  if(typeof recommendedProducts !== "undefined"){
    if(recommendedProducts.length > 0 && openModal){
      if(productAddedToCart.id === product?.id) {
        return (
          <RecommendedProductsModal
            cart={cart}
            onClose={exitProductRecommendations}
            baseProduct={productAddedToCart}
            recommendedProducts={recommendedProducts}
          />
        );
      } else {
        if(typeof product !== 'undefined') {
          Store.getProductsById(product.id).then(fetchedProduct => {
            return (
              <RecommendedProductsModal
                cart={cart}
                onClose={exitProductRecommendations}
                baseProduct={fetchedProduct}
                recommendedProducts={recommendedProducts}
              />
            );
          });
        }
      }
    }
  }

  let button = (
    <Button
      className={styles.button}
      onClick={addToCart}
      disabled={Boolean(isLoading)}
      url={url}
      {...props}
    >
      {children || 'Add to cart'}
    </Button>
  )

  if(clicked){
    button = (
      <Button
        className={cn(styles.button, styles.buttonClicked)}
        onClick={addToCart}
        disabled={Boolean(isLoading)}
        url={url}
      >
        {'✓'}
      </Button>
    );
  }

  return button;
}

export const BuyVariationButton = ({ product, variations, attribute, isExtraProduct, ...props }) => {
  const cart = React.useContext(Cart);
  const { dispatch } = cart;
  const { productAddedToCart, recommendedProducts } = cart.state;
  const [showModal, setModal] = React.useState(false)
  const [clicked, setClicked] = React.useState(false);
  const [shouldAddToCart, setShouldAddToCart] = React.useState(false);
  const [variation, setVariation] = React.useState(null);
  const [hasRecommendedProducts, setHasRecommendedProducts] = React.useState(false);
  const [recommendedModalOpen, setRecommendedModalOpen] = React.useState(false);
  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)
  let timeoutId = -1;

  React.useEffect(() => {
    function cleanup(){
      if(timeoutId !== -1){
        clearInterval(timeoutId);
        setShouldAddToCart(false);
      }
    }

    if(shouldAddToCart){
      dispatch({ type: 'add', item: product, variation, currency: cart.currency })

      Store.getProductsById(product.id).then(productAddedToCart => {
        if(typeof productAddedToCart[0] !== 'undefined') {
          dispatch({type: 'productAddedToCart', productAddedToCart: productAddedToCart[0]});
        }
      });

      setClicked(true)
      closeModal();
      timeoutId = setTimeout(() => {
        setClicked(false);
      }, 1500);

      // Check if product added to cart is one of the recommended_products, if so, update the recommended products accordingly
      if(typeof recommendedProducts !== "undefined" && recommendedProducts.length > 0){
        let mutableArray = [...recommendedProducts];
        let hasRemoved = false;
        for(let i = 0; i < mutableArray.length; i++){
          const recProduct = mutableArray[i];
          if(recProduct.id === product.id){
            const index = mutableArray.indexOf(recProduct);
            mutableArray.splice(index, 1);
            hasRemoved = true;
            break;
          }
        }

        if(hasRemoved){
          if(mutableArray.length === 0) exitProductRecommendations();
          dispatch({type: 'recommendedProducts', recommendedProducts: mutableArray});
          return cleanup;
        }
      }

      if(!hasRecommendedProducts && !isExtraProduct){
        if(typeof productAddedToCart === 'undefined') {
          Store.getProductsById(product.id).then(fetchedProduct => {
            setRecommendedProducts(fetchedProduct[0]);
          });
        } else {
          setRecommendedProducts(productAddedToCart);
        }
      }
    }

    return cleanup;
  }, [shouldAddToCart])

  React.useEffect(() => {
    if(shouldAddToCart && hasRecommendedProducts && !isExtraProduct){
      setRecommendedModalOpen(true);
    }
  }, [shouldAddToCart, hasRecommendedProducts])

  const setRecommendedProducts = _productAddedToCart => {
    if(typeof _productAddedToCart.acf.recommended_products !==  "undefined"){
      if(_productAddedToCart.acf.recommended_products.length > 0){
        Store.getProductsById(_productAddedToCart.acf.recommended_products).then(recommendedProducts => {
          dispatch({type: 'recommendedProducts', recommendedProducts});
          if(recommendedProducts.length > 0){
            setHasRecommendedProducts(true);
          }
        });
      }
    }
  }

  const exitProductRecommendations = () => {
    setRecommendedModalOpen(false);
    setShouldAddToCart(false);
  }

  if(typeof recommendedProducts !== "undefined"){
    if(recommendedProducts.length > 0 && recommendedModalOpen){
      return (
        <RecommendedProductsModal
          cart={cart}
          onClose={exitProductRecommendations}
          baseProduct={productAddedToCart}
          recommendedProducts={recommendedProducts}
        />
      );
    }
  }

  return (
    <React.Fragment>
      {showModal && (
        <Modal onClose={closeModal}>
          <div className={styles.variationsModal}>
            <h3>{attribute.name}</h3>
            <div className={styles.variations}>
              {variations
                .filter(
                  (variation) =>
                    variation.attributes[0] &&
                    variation.attributes[0].name === attribute.name
                )
                .map((variation) => {
                  const {
                    attributes,
                    price,
                    price_usd,
                    manage_stock,
                    stock_status,
                    stock_quantity,
                    backorders
                  } = variation
                  const { option } = attributes.find(
                    (attr) => attr.name === attribute.name
                  )
                  const addToCart = () => {
                    if(shouldAddToCart){
                      setShouldAddToCart(false);
                    }

                    setTimeout(() => setShouldAddToCart(true), 25);
                    setVariation(variation);
                  }

                  if (!option) {
                    return null
                  }

                  let currency = <Currency eur={price} usd={price_usd} />;

                  if(typeof product.recommendedProductsDataAll !== "undefined" && product.recommendedProductsDataAll.length) {
                    const recProductData = product.recommendedProductsDataAll.find(x => x.id === variation.id);

                    if(recProductData && recProductData.use_discount) {
                      currency = <Currency eur={recProductData.price_eur} usd={recProductData.price_usd} />;
                    }
                  }

                  return (
                    <div className={styles.variation} key={variation.id}>
                      <div className={styles.option}>{option}</div>
                      <div className={styles.price}>
                        {currency}
                      </div>
                      {(
                        manage_stock
                          ? stock_quantity > 0
                          : stock_status === 'instock'
                      ) ? (
                        <Button
                          onClick={addToCart}
                          className={styles.addVariationButton}
                        >
                          Buy
                        </Button>
                      ) : (
                        manage_stock
                          ? backorders === 'yes'
                          : stock_status === 'onbackorder'
                      ) ? (
                        <Button
                          onClick={addToCart}
                          className={styles.addVariationButton}
                        >
                          Pre-order
                        </Button>
                      ) : (
                        <p className={styles.variationOutOfStock}>
                          Out of stock
                        </p>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </Modal>
      )}
      {(clicked ? (
        <Button
          className={cn(styles.button, styles.buttonClicked)}
          onClick={openModal}
        >
          {'✓'}
        </Button>
      ) : (
        <Button className={styles.button} onClick={openModal} {...props}>
          Add to Cart
        </Button>
      ))}
    </React.Fragment>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Linked Products

const LinkedProducts = ({ label, items, id }) => {
  const store = useStore()
  const cart = React.useContext(Cart);
  const { dispatch } = cart;
  const { recommendedProducts, recommendedProductsData } = cart.state;

  if (!Array.isArray(items) || items.length === 0) {
    return null
  }

  const products = items
    .map((id) => store.products.find((product) => product.id === id))
    .filter((product) => product != null)

  return (
    <div className={styles.linkedProducts}>
      {label && <h3>{label}</h3>}
      <div className={styles.linkedProductsList}>
        {products.map((product) => {
          const isSelected = product.id === id
          const { acf = {}, name } = product
          const { image_front, images } = acf
          const frontImage = (images && images.image_front ? images.image_front : false) || image_front;
          const hero =
            acf.content && acf.content.find((x) => x.acf_fc_layout === 'hero')
          const shortName =
            hero && hero.linked_products
              ? hero.linked_products.short_name
              : null

          if(isSelected && recommendedProducts && recommendedProductsData) {
            let isInvalid = false;
            for(let i = 0; i <  recommendedProducts.length; i++) {
              const name = recommendedProducts[i].name;
              const recommendedBy = recommendedProductsData[name]?.recommendedBy;

              if(typeof recommendedBy !== 'undefined' && recommendedBy !== product.id) {
                isInvalid = true;
                break;
              }
            }

            if(isInvalid || recommendedProducts.length === 0) {
              if(product.acf.recommended_products && product.acf.recommended_products.length){
                Store.getProductsById(product.acf.recommended_products).then(recommendedProducts => {
                  dispatch({type: 'recommendedProducts', recommendedProducts});
                });
              }
            }
          }

          return (
            <Link to={getUrl(product)} key={product.id}>
              <div
                className={cn(styles.linedProductsImageContainer, {
                  [styles.selected]: isSelected,
                })}
              >
                <img src={frontImage} alt={name} loading='lazy' />
              </div>
              <span title={shortName || name}>{shortName || name}</span>
            </Link>
          )
        })}
      </div>
    </div>
  )
}


export default Hero
