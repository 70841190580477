import React from 'react';
import styles from './crossSellTitle.module.css';
import classNames from 'classnames/bind';

class CrossSellTitle extends React.Component {
  constructor(props){
    super(props)

    this.cn = classNames.bind(styles);
  }

  render() {
    return <h2 className={this.cn(styles.crossSellTitle)}>{this.props.title || 'You may be interested in'}</h2>
  }
}

export default CrossSellTitle;
