import React from 'react';

function useIsReviewer() {
  const [isReviewer, setIsReviewer] = React.useState(false);

  React.useEffect(() => {
    if(typeof jQuery === "undefined") return;

    setTimeout(() => {
      jQuery.ajax('/is-reviewer', {
        method: "GET",
        success: function () {
          setIsReviewer(true)
        },
        error: function () {
          setIsReviewer(false)
        }
      });
    }, 500);
  }, []);

  return isReviewer;
}

export default useIsReviewer;
