import React from 'react';

export default function () {
  const html = `
        <a href="#">
          <# if ( PC.fe.config.angles.show_image && data.image && data.image.url ) { #>
            <span class="angle-image"><img src="{{data.image.url}}" alt=""></span>
          <# } #>
          <# if ( PC.fe.config.angles.show_name ) { #>
              {{data.name}}
          <# } #>
        </a>`;

  return <script type="text/html" id="tmpl-mkl-pc-configurator-angle-item" dangerouslySetInnerHTML={{__html: html}}/>
}
