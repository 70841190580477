import React from 'react'
import styles from './post.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'
import Title from '../../Title'
import Date from '../../Date'
import Helmet from 'react-helmet'
import { HOSTNAME } from '../../../constants'

const cn = classNames.bind(styles)

export default ({ title = {}, content = {}, date, excerpt, location, ...props }) => {
  const m = excerpt.rendered.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/)
  const description = m[1] || title.rendered

  return (
    <article className={cn('post')}>
      <Helmet
        title={title.rendered}
        titleTemplate="%s – CHERRY XTRFY"
        meta={[
          { property: 'og:url', content: `${HOSTNAME}${location.pathname}` },
          { property: 'og:type', content: 'product' },
          { property: 'og:title', content: title.rendered },
          { property: 'og:description', content: description },
        ]}
      />
      <Wrap className={cn('wrap')}>
        {date && <Date>{date}</Date>}
        <Title large>{title.rendered}</Title>
        <div
          className={cn('content')}
          dangerouslySetInnerHTML={{ __html: content.rendered }}
        />
      </Wrap>
    </article>
  )
}
