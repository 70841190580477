import React from 'react';
import styles from './logout-button.module.css';
import LogoutIcon from "../icons/logout";

export default ({ isMobile }) => {
  const logout = () => {
    jQuery.ajax('http://log:out@localhost:4200/logout', {
      method: "GET",
      complete: function() {
        window.location = '/'
      }
    });
  }

  return isMobile ? (
    <div onClick={logout} className={styles.logoutIcon}>
      <LogoutIcon />
    </div>
  ) : (
    <div className={styles.logoutContainer}>
      <p onClick={logout} className={styles.logout}>Logout</p>
    </div>
  );
}
