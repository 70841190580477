import React from 'react';

class RecommendedProductsTextArea extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
  }
}

export default RecommendedProductsTextArea;
