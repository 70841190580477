import ProductStore from "../stores";
import customConfiguratorHandler from "./customConfiguratorHandler";
import Share from "./share";

let selectedProducts = [];
let setSelectedProducts = null;
let configuratorPrices = {};
let setConfiguratorPrices = null;
let configId = 0;
let cart = {};
let id = 0;
let data = {};

function initHooks(addConfiguratorHooks, _selectedProducts, _setSelectedProducts, _configuratorPrices, _setConfiguratorPrices, _configId, _cart, _id, _data) {
  selectedProducts = _selectedProducts;
  setSelectedProducts = _setSelectedProducts;
  configuratorPrices = _configuratorPrices;
  setConfiguratorPrices = _setConfiguratorPrices;
  configId = _configId;
  cart = _cart;
  id = _id;
  data = _data;

  if(typeof window !== 'undefined') {
    window.xtrfy = { configId, configuratorPrices };
  }

  if ( addConfiguratorHooks && typeof wp !== 'undefined' && typeof wp.hooks !== 'undefined' ) {
    if(!wp.hooks.hasAction('PC.fe.open', 'xtrfy/init_configurator')) {
      wp.hooks.addAction('PC.fe.open', 'xtrfy/init_configurator', init, 1);
    }

    if(!wp.hooks.hasAction('PC.fe.open', 'xtrfy/prices')) {
      wp.hooks.addAction('PC.fe.open', 'xtrfy/prices', fixPrices, 1);
      wp.hooks.addAction('PC.fe.choice.change', 'xtrfy/prices', adjustPriceOnChange, 99);
    }

    if(!wp.hooks.hasAction('PC.fe.viewer.layer.render', 'xtrfy/backorder')) {
      wp.hooks.addAction('PC.fe.viewer.layer.render', 'xtrfy/backorder', backorder);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.render', 'xtrfy/welcome_message')) {
      wp.hooks.addAction('PC.fe.layer.render', 'xtrfy/welcome_message', addWelcomeMessage, 99);
      wp.hooks.addAction('PC.fe.choice.change', 'xtrfy/welcome_message', removeWelcomeMessage, 99);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.render', 'xtrfy/special_choices')) {
      wp.hooks.addAction('PC.fe.layer.render', 'xtrfy/special_choices', addSpecialChoices, 99);
    }

    if(!wp.hooks.hasAction('PC.fe.choice.change', 'xtrfy/description_overlay')) {
      wp.hooks.addAction('PC.fe.choice.change', 'xtrfy/description_overlay', handleDescriptionOverlay);
    }

    if(!wp.hooks.hasAction('PC.fe.close', 'xtrfy/close_configurator')) {
      wp.hooks.addAction('PC.fe.close', 'xtrfy/close_configurator', onClosed);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.render', 'xtrfy/warning_message')) {
      wp.hooks.addAction('PC.fe.layer.render', 'xtrfy/warning_message', addWarningMessage, 99);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.render', 'xtrfy/angles_list')) {
      wp.hooks.addAction('PC.fe.layer.render', 'xtrfy/angles_list', addAngleList);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.render', 'xtrfy/load_scripts')) {
      wp.hooks.addAction('PC.fe.layer.render', 'xtrfy/load_scripts', loadScripts, 1);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.deactivate', 'xtrfy/description')) {
      wp.hooks.addAction('PC.fe.layer.deactivate', 'xtrfy/description', closeDescriptionOnLayerClose, 1);
    }

    if(!wp.hooks.hasAction('PC.fe.layer.activate', 'xtrfy/group_open')) {
      wp.hooks.addAction('PC.fe.layer.activate', 'xtrfy/group_open', openLonelyGroup);
    }
  }
}

function maybeLoadSharedData() {
  if(window && window.location && window.location.search) {
    const url = new URL(window.location);
    const hash = url.searchParams.get('d');
    if(!hash) return;

    Share.getConfiguration(hash, function(fetchedConfiguration) {
      if(fetchedConfiguration) {
        if(!wp.hooks.hasAction('PC.fe.init', 'xtrfy/load-share-data')) {
          wp.hooks.addAction('PC.fe.init', 'xtrfy/load-share-data', function() {
            PC.fe.setConfig(fetchedConfiguration);
          }, 1);
        }

        setTimeout(() => {
          jQuery('#xtrfy-configurator-button').click();
        }, 2000);
      }
    }, function (err) {
      console.log(err);
    });
  }
}

function updateCurrency() {
  const $items = jQuery('div.xtrfy-items div.xtrfy-item');

  const { currency } = cart;

  let total = 0;
  for(let i = 0; i < $items.length; i++) {
    const $this = jQuery($items[i]);
    const $cost = $this.find('div.xtrfy-item-price-container p.xtrfy-item-cost');
    const price = currency === 'EUR' ? $cost.data('price') : $cost.data('price-usd');
    const priceHtml = PC.utils.formatMoney(price);
    $cost.html(priceHtml);
    total += price;
  }

  total /= 2;

  updateConfiguratorTotals(currency, total);
}

function pricesHaveBeenSet() {
  const { currency } = cart;

  for(let i = 0; i < configuratorPrices.skus.length; i++) {
    const sku = configuratorPrices.skus[i];
    const contentLayerId = configuratorPrices.ids[i];
    const prices = configuratorPrices.prices['_' + sku];

    const $button = jQuery('button[data-content-layer-id=' + contentLayerId + ']');
    const $fetchedPrice = $button.find('span.fetched-price');

    if(!prices.eur) prices.eur = 0;
    if(!prices.usd) prices.usd = 0;

    const price = prices.eur;

    if(!price) {
      $fetchedPrice.hide();
      continue;
    }

    const $extraPrice = $button.find('span.choice-price');
    if($extraPrice.length) $extraPrice.hide();

    $fetchedPrice.html(PC.utils.formatMoney(price));
  }
}

function setData(isConfigurable, id, store, ignoreIsConfigurable) {
  if(data && data.acf.configuratorLink && data.acf.showConfiguratorLink) {
    const configId = data.acf.configuratorLink;

    if(typeof window !== 'undefined') {
      if(typeof window.xtrfy !== 'undefined') {
        window.xtrfy.configId = configId;
      } else {
        window.xtrfy = { configId };
      }
    }

    if(configId && store && store.getConfiguration) {
      ProductStore.getProductsById(configId).then(products => {
        const product = products[0];
        if(product && (typeof isConfigurable === 'undefined' || ignoreIsConfigurable)) {
          store.getConfiguration(configId);
        }
      });
    }
  } else if (id && store && store.getConfiguration && (typeof isConfigurable === 'undefined' || ignoreIsConfigurable)) {
    store.getConfiguration(id)
  }
}

/** ==== PRIVATE FUNCTIONS BELOW ==== **/


function init()  {
  wp.hooks.removeAction('PC.fe.open', 'xtrfy/init_configurator', init, 1);

  const availabilityPromise = new Promise(resolve => {
    const __configId = window.xtrfy.configId || configId;

    if(window.xtrfy && window.xtrfy['product_' + __configId]) {
      resolve(window.xtrfy['product_' + __configId]);
      return;
    }

    if(typeof window.xtrfy === "undefined") window.xtrfy = {};

    if(__configId === id) {
      let availability = data.acf.availability;
      if(typeof availability === "undefined") availability = 'available';
      window.xtrfy['product_' + __configId] = availability;
      resolve(availability);
      return;
    }

    ProductStore.getProductsById(__configId).then(products => {
      const product = products[0];
      let availability = (!product || !product.acf) ? 'available' : product.acf.availability;
      if(typeof availability === "undefined") availability = 'available';
      window.xtrfy['product_' + __configId] = availability;
      resolve(availability);
    });
  });

  availabilityPromise.then(availability => {
    if(availability === "coming_soon") {
      const $button = jQuery('button.configurator-add-to-cart');
      $button.prop('disabled', true);
      $button.css('opacity', '0.5');
      $button.css('cursor', 'text');
      $button.text('Coming Soon');
    }
  });

  const finalScriptContainer = document.createElement('script');
  finalScriptContainer.innerHTML = customConfiguratorHandler();
  document.body.appendChild(finalScriptContainer);
}

function fixPrices() {
  wp.hooks.removeAction('PC.fe.open', 'xtrfy/prices', fixPrices, 1);

  if(!PC.fe.layers.length) return;

  let skus = [];
  let ids = [];
  for(let i = 0; i < PC.fe.layers.length; i++) {
    const layer = PC.fe.layers.models[i];
    if(layer.get('not_a_choice')) continue;

    const content = PC.fe.getLayerContent(layer.id);
    for(let j = 0; j < content.length; j++) {
      const choice = content.models[j];

      if(choice.get('class_name') === 'use-extra-price') continue;

      const sku = choice.get('sku');
      if(sku && sku.length) {
        skus.push(sku);
        ids.push(choice.id + '_' + layer.id);
      }
    }
  }

  if(!configuratorPrices.hasBeenSet) {
    fetch('/api/wc/configurator-prices?skus=' + skus.toString()).then(rsp => rsp.json()).then(data => {
      setConfiguratorPrices({hasBeenSet: true, prices: data, skus, ids});

      // have to do this to be able to access this data in the choice change hook
      window.xtrfy.configuratorPrices = {hasBeenSet: true, prices: data, skus, ids};
    });
  }

  const currency = cart ? (
    cart.currency ? cart.currency : (PC_config.lang.money_symbol === '&euro;' ? 'EUR' : 'USD')
  ) : (PC_config.lang.money_symbol === '&euro;' ? 'EUR' : 'USD');

  updateCurrencySymbol(currency);
  updateConfiguratorTotals(currency);

  jQuery('div#summary-open').click(() => {
    const $container = jQuery('div.configuration-summary-container.mobile');
    $container.addClass('open');

    jQuery('div#summary-open').hide();
    jQuery('button.close-mkl-pc').hide();
    jQuery('div#summary-close').show();
    jQuery('div.configuration-summary.mobile').show();
  });

  jQuery('div#summary-close').click(() => {
    const $container = jQuery('div.configuration-summary-container.mobile');
    $container.removeClass('open');

    jQuery('div#summary-close').hide();
    jQuery('button.close-mkl-pc').show();
    jQuery('div#summary-open').show();
    jQuery('div.configuration-summary.mobile').hide();
  });
}

function adjustPriceOnChange(choice) {
  if(typeof jQuery === 'undefined') return;

  const currency = cart ? (
    cart.currency ? cart.currency : (PC_config.lang.money_symbol === '&euro;' ? 'EUR' : 'USD')
  ) : (PC_config.lang.money_symbol === '&euro;' ? 'EUR' : 'USD');

  updateCurrencySymbol(currency);

  const sku = choice.get('sku');
  const cid = choice.cid;
  const layerId = choice.get('layerId');
  const layer = PC.fe.layers.get(layerId);

  if(layer.get('not_a_choice')) return;

  const layerName = layer.get('name');
  let layerSync = layer.get('sync');

  if(!layerSync) layerSync = '';

  if(layer.get('type') === 'multiple') {
    const $potentialItem = jQuery('div.xtrfy-items div.xtrfy-item[data-cid=' + cid +']');
    if($potentialItem.length) {
      $potentialItem.remove();
      updateConfiguratorTotals(currency);
      updateCartCount();
      return;
    }
  } else {
    let query = 'div.xtrfy-items div.xtrfy-item[data-layer-id=' + layerId +']';
    if(layerSync.length) {
      query += ', div.xtrfy-items div.xtrfy-item[data-layer-sync=' + layerSync +']';
    }

    const $potentialItem = jQuery(query);
    if($potentialItem.length) {
      $potentialItem.remove();
    }
  }

  // The Product Configurator exposes the method PC.utils.formatMoney() which uses woocs_current_currency.rate
  // (defined in render-html.js) to add VAT to the price when needed, therefore we no longer need to handle that here

  let price = 0;
  const name = choice.get('name');
  const prices = typeof window.xtrfy.configuratorPrices.prices !== "undefined" ? window.xtrfy.configuratorPrices.prices['_' + sku] : null;


  if(prices) {
    if(!prices.eur) prices.eur = 0;

    price = prices.eur;
  }

  if(!price) {
    price = choice.get('extra_price');
  }

  if(isNaN(price)) price = 0;

  const priceHtml = PC.utils.formatMoney(price)

  let thumbnail = {};
  const images = choice.get('images');
  if(images) thumbnail = images.get(1).get('thumbnail');

  if(name && name.toLowerCase() !== 'none') {
    const item = `
        <div class="xtrfy-item" data-layer-id="${layerId}" data-layer-sync="${layerSync}" data-cid="${cid}">
            <div class="xtrfy-item-image">
               <img src="${thumbnail.url}" width="32" height="32" class="${thumbnail.url ? '' : 'missing-image'}">
            </div>
            <div class="xtrfy-item-content">
                <h3 class="xtrfy-layer-name">${layerName}</h3>
                <p class="xtrfy-choice-name">${name}</p>
            </div>
            <div class="xtrfy-item-price-container">
                <p class="xtrfy-item-cost" data-price="${price}" data-price-usd="${price}">${priceHtml}</p>
            </div>
        </div>
    `;

    jQuery('div.xtrfy-items').append(jQuery.parseHTML(item));
  }

  // Calculate and update totals
  updateConfiguratorTotals(currency);
  updateCartCount();
}

async function backorder(choice) {
  const $button = jQuery('button.configurator-add-to-cart');
  const product = choice.model;

  let _selectedProducts = selectedProducts;

  if(product.get('active')) {
    const layerId = product.get('layerId');
    const layer = PC.fe.layers.get(layerId);

    if(layer.get('cshow') === undefined || layer.get('cshow')) {
      const cshow = product.get('cshow') === undefined || product.get('cshow')

      if(cshow) {
        if(!product.get('available')) {
          _selectedProducts[product.cid] = 'outofstock';
        } else if((product.get('wc_backorder') === 'yes' || product.get('backorder')) && product.get('manage_stock') === 'simple') {
          _selectedProducts[product.cid] = 'onbackorder';
        } else {
          _selectedProducts[product.cid] = 'instock';
        }
      } else {
        if(typeof _selectedProducts[product.cid] !== 'undefined') {
          _selectedProducts[product.cid] = undefined;
        }
      }

    } else {
      if(typeof _selectedProducts[product.cid] !== 'undefined') {
        _selectedProducts[product.cid] = undefined;
      }
    }

  } else {
    if(typeof _selectedProducts[product.cid] !== 'undefined') {
      _selectedProducts[product.cid] = undefined;
    }
  }

  const availabilityPromise = new Promise(resolve => {
    const __configId = window.xtrfy.configId || configId;
    if(window.xtrfy && window.xtrfy['product_' + __configId]) {
      resolve(window.xtrfy['product_' + __configId]);
      return;
    }

    if(typeof window.xtrfy === "undefined") window.xtrfy = {};

    if(__configId === id) {
      window.xtrfy['product_' + __configId] = data.acf.availability;
      resolve(data.acf.availability);
      return;
    }

    ProductStore.getProductsById(__configId).then(products => {
      const product = products[0];
      let availability = (!product || !product.acf) ? 'available' : product.acf.availability;
      window.xtrfy['product_' + __configId] = availability;
      resolve(availability);
    });
  });

  // Before resolving the below promise, make sure we cannot buy the product
  $button.prop('disabled', true);
  $button.css('opacity', '0.5');
  $button.css('cursor', 'text');
  $button.text('Add to cart');

  availabilityPromise.then(availability => {
    if(availability === 'coming_soon') {
      $button.prop('disabled', true);
      $button.css('opacity', '0.5');
      $button.css('cursor', 'text');
      $button.text('Coming Soon');
    } else if(hasAnyOutOfStockProducts(_selectedProducts)) {
      $button.prop('disabled', true);
      $button.css('opacity', '0.5');
      $button.css('cursor', 'text');
      $button.text('Unavailable for purchase');
    } else if(hasAnyBackorderProducts(_selectedProducts)) {
      $button.prop('disabled', false);
      $button.css('opacity', '1');
      $button.css('cursor', 'pointer');
      $button.text('Pre-order');
    } else {
      $button.prop('disabled', false);
      $button.css('opacity', '1');
      $button.css('cursor', 'pointer');
      $button.text('Add to cart');
    }
  });

  $button.addClass('choice-selected');

  setSelectedProducts(_selectedProducts);
}

function addWelcomeMessage() {
  const $welcomeContainer = jQuery('div#configurator-welcome-msg-container');
  const $welcomeMessage = jQuery('div#configurator-welcome-msg');
  if($welcomeContainer.length || $welcomeMessage.length) {
    fetch('/api/wc/configurator-welcome-message?id=' + window.xtrfy.configId).then(rsp => rsp.json()).then(data => {
      if(data) {
        if(data.text) {
          const customHtml = jQuery.parseHTML(data.text);
          $welcomeMessage.append(customHtml);
        }

        if(data.background_image) {
          $welcomeContainer.css('background-image', 'url("' + data.background_image + '")');
        }
      }
    });
  }

  wp.hooks.removeAction('PC.fe.layer.render', 'xtrfy/welcome_message', addWelcomeMessage, 99);
}

function addWarningMessage() {
  const $warningMessage = jQuery('div#configurator-warning-msg');
  if($warningMessage.length) {
    fetch('/api/wc/configurator-warning-message').then(rsp => rsp.json()).then(data => {
      if(data && data.message) {
        $warningMessage.text(data.message);
      }
    });
  }

  wp.hooks.removeAction('PC.fe.layer.render', 'xtrfy/warning_message', addWarningMessage, 99);
}

function removeWelcomeMessage() {
  const $welcomeContainer = jQuery('div#configurator-welcome-msg-container');
  if($welcomeContainer.length) {
    $welcomeContainer.remove();
  }

  wp.hooks.removeAction('PC.fe.choice.change', 'xtrfy/welcome_message', removeWelcomeMessage, 99);
}

function addSpecialChoices() {
  fetch('/api/wc/configurator-special-choices?id=' + window.xtrfy.configId).then(rsp => rsp.json()).then(data => {
    if(data && data.layer) {
      const $specialChoicesContainer = jQuery('div#configurator-special-choices');
      let thisArgs = [];
      let items = '';

      if(!PC.fe.layers) return;

      let id = -1;
      for(let i = 0; i < PC.fe.layers.models.length; i++) {
        const name = PC.fe.layers.models[i].get('name').toLowerCase().trim();
        if(name === data.layer.toLowerCase().trim()) {
          id = PC.fe.layers.models[i].id;
          break;
        }
      }

      if(id === -1) return;

      const content = PC.fe.getLayerContent(id);
      if(!content || !content.models) return;

      for(let i = 0; i < content.models.length; i++) {
        const name = content.models[i].get('name');
        const images = content.models[i].get('images');
        let image = {url: '', dimensions: {height: '', width: ''}, id: ''};
        if(images && images.models && images.models[0]) {
          image = images.models[0].get('thumbnail');
        }

        if(image.url) {
          items += `
              <div class="special-choice-item">
                  <img src="${image.url}" width="64" height="64" />
                  <p>${name}</p>
              </div>
            `;
        } else {
          items += `
              <div class="special-choice-item">
                  <p>${name}</p>
              </div>
            `;
        }

        thisArgs.push(content.models[i]);
      }

      if(items.length) {
        $specialChoicesContainer.append(jQuery.parseHTML(items));
        $specialChoicesContainer.find('div.special-choice-item').each(function(idx) {
          this.addEventListener('click', () => specialChoiceClickListener.call(thisArgs[idx]));
        });
      }
    }
  });

  wp.hooks.removeAction('PC.fe.layer.render', 'xtrfy/special_choices', addSpecialChoices, 99);
}

function addAngleList() {
  const angles = PC.fe.angles;
  if(angles.length > 1) {
    jQuery('div.angles-select').hide();

    let anglesHtml = '';
    let thisArgs = [];
    for(let i = 0; i < angles.length; i++) {
      const model = angles.models[i];
      const text = model.get('name');
      const img = model.get('image');

      let angleItem;

      if(img && img.url) {
        angleItem = `
            <div class="angle-choice">
              <img src="${img.url}" alt="${text}" width="96" height="96">
            </div>
          `;
      } else {
        angleItem = `
            <div class="angle-choice">
              <p>${text}</p>
            </div>
          `;
      }

      anglesHtml += angleItem;
      thisArgs.push(model);
    }

    if(anglesHtml.length) {
      const $angleContainer = jQuery('div#custom-angles-list');
      $angleContainer.append(jQuery.parseHTML(anglesHtml));
      $angleContainer.find('div.angle-choice').each(function(idx) {
        this.addEventListener('click', () => customAngleClickListener.call(thisArgs[idx]));
      });
    }
  }

  wp.hooks.removeAction('PC.fe.layer.render', 'xtrfy/angles_list', addAngleList);
}

function handleDescriptionOverlay(choice) {
  const descriptionText = choice.get('description');

  const $descriptionOverlay = jQuery('div#description-overlay');
  if(descriptionText && descriptionText.trim()) {
    $descriptionOverlay.show();
    $descriptionOverlay.html(descriptionText.trim());
  } else {
    $descriptionOverlay.hide();
  }
}

function onClosed() {
  const $descriptionOverlay = jQuery('div#description-overlay');
  if($descriptionOverlay.length) {
    $descriptionOverlay.remove();
  }
}

function loadScripts() {
  wp.hooks.removeAction('PC.fe.layer.render', 'xtrfy/load_scripts', loadScripts);

  const priceScriptId = 'mkl_pc/extra-price/js-js';

  const $priceScript = jQuery('script.mkl_pc_extra-price_js-js');
  let priceScriptIdEqual = $priceScript.data('product-id') === window.xtrfy.configId.toString();
  if(!$priceScript.length || !priceScriptIdEqual) {
    if($priceScript.length && !priceScriptIdEqual) {
      $priceScript.remove();
    }

    let priceScript = document.createElement('script');
    priceScript.src = "/wp/wp-content/plugins/woocommerce-mkl-pc-extra-price/inc/extra-price.js?ver=xtrfy_mkl_pc_v20230602";
    priceScript.async = true;
    priceScript.id = priceScriptId;
    priceScript.classList.add(priceScriptId.replace(/\//g, '_'));
    priceScript.setAttribute('data-product-id', window.xtrfy.configId);

    document.body.appendChild(priceScript);
  }
}

function closeDescriptionOnLayerClose() {
  jQuery('div#description-overlay').hide();
}

function openLonelyGroup() {
  const $activeLayer = jQuery('ul.layer_choices.active');
  const $lonelyGroup = $activeLayer.find('li.choices-list ul li.is-group:visible');
  if($lonelyGroup.length === 1)  {
    $lonelyGroup.find('ul.choices-list--children').show();
    $lonelyGroup.find('div.choice-group-label span.text.choice-name').addClass('open');
  }
}

function hasAnyOutOfStockProducts(products) {
  const values = Object.values(products);
  if(values.length) {
    for(let i = 0; i < values.length; i++) {
      if(values[i] === 'outofstock') return true;
    }
  }

  return false;
}

function hasAnyBackorderProducts(products) {
  const values = Object.values(products);
  if(values.length) {
    for(let i = 0; i < values.length; i++) {
      if(values[i] === 'onbackorder') return true;
    }
  }

  return false;
}

function updateCartCount() {
  const $cartCount = jQuery('div#summary-open div.cart-count');

  if ($cartCount.length) {
    const itemCount = jQuery('div.xtrfy-items.mobile div.xtrfy-item').length;
    if (!itemCount) {
      $cartCount.hide();
      return;
    }

    $cartCount.text(itemCount);
    $cartCount.show();
  }
}

function updateConfiguratorTotals(currency, total) {
  if(!total) {
    const $items = jQuery('div.xtrfy-items.mobile div.xtrfy-item');
    total = 0;
    for(let i = 0; i < $items.length; i++) {
      const $this = jQuery($items[i]);
      const $cost = $this.find('div.xtrfy-item-price-container p.xtrfy-item-cost');
      let price = currency === 'EUR' ? $cost.data('price') : $cost.data('price-usd');
      price = parseFloat(price) * 100;
      total += price;
    }

    total = (total/100).toFixed(2);
  }

  const $totalFooter = jQuery('div#xtrfy-total-footer');
  jQuery('div.xtrfy-total span.xtrfy-total-cost').html(PC.utils.formatMoney(total));
  if(total) {
    $totalFooter.show();
    return;
  }

  $totalFooter.hide();
}

function specialChoiceClickListener() {
  this.collection.selectChoice(this.id);
  wp.hooks.doAction('PC.fe.choice.set_choice', this);
}

function customAngleClickListener() {
  this.collection.each(function(model) {
    model.set('active', false);
  });

  this.set('active', true);
}

function updateCurrencySymbol(currency) {
  if(currency === "EUR") {
    PC_config.lang.money_symbol = '&euro;';
    return;
  }

  PC_config.lang.money_symbol = '&dollar;';
}

export default { initHooks, updateCurrency, pricesHaveBeenSet, setData, maybeLoadSharedData };
