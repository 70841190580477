import superagent from "superagent";

export function getShareUrl(configuration, onSuccess, onFail) {
  configuration = compressShareData(configuration);

  const data = {
    configuration,
    host: window.location.origin + window.location.pathname
  };

  superagent
    .post('/api/get-share-url')
    .set('Content-Type', 'application/json')
    .send(data)
    .end((err, res) => {
      if (err) {
        if(typeof onFail === 'function') {
          onFail(err);
        }
      } else if (res.statusCode && res.statusCode === 200) {
        if(typeof onSuccess === 'function') {
          onSuccess(res);
        }
      }
  });
}

export function getConfiguration(hash, onSuccess, onFail) {
  superagent
    .post('/api/get-share-data')
    .set('Content-Type', 'application/json')
    .send({hash})
    .end((err, res) => {
      if (err) {
        if(typeof onFail === 'function') {
          onFail(err);
        }
      } else if (res.statusCode && res.statusCode === 200) {
        if(res.body && res.body.configuration) {
          let configuration = res.body.configuration;
          configuration = decompressShareData(configuration);

          try {
            configuration = JSON.parse(configuration);
          } catch (e) {
            configuration = null;
          }

          if(typeof onSuccess === 'function') {
            onSuccess(configuration);
          }
        }
      }
  });
}

export function copyToClipboard(text, $shareLink) {
  if(!navigator.clipboard) {
    fallbackCopyToClipboard($shareLink);
    return;
  }

  navigator.clipboard.writeText(text).then(() => {
    // Select the link after copy to make it clear to the user that it has been copied
    $shareLink.focus();
    $shareLink.select();
  }, err => {
    console.error('Async: Could not copy to clipboard:', err);
  });
}

function fallbackCopyToClipboard($shareLink) {
  $shareLink.focus();
  $shareLink.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Could not copy to clipboard', err);
  }
}

function compressShareData(data) {
  return data
    .replace(/is_choice/g, '0')
    .replace(/layer_id/g, '1')
    .replace(/choice_id/g, '2')
    .replace(/angle_id/g, '3')
    .replace(/image/g, '4')
    .replace(/name/g, '5')
    .replace(/layer_name/g, '6')
    .replace(/},{/g, '}{')
    .replace(/^\[{/, '')
    .replace(/}]$/, '');
}

function decompressShareData(data) {
  return '[{' + data
    .replace(/"0":/g, '"is_choice":')
    .replace(/"1":/g, '"layer_id":')
    .replace(/"2":/g, '"choice_id":')
    .replace(/"3":/g, '"angle_id":')
    .replace(/"4":/g, '"image":')
    .replace(/"5":/g, '"name":')
    .replace(/"6":/g, '"layer_name":')
    .replace(/}{/g, '},{')+ '}]';
}

export default {getShareUrl, getConfiguration, copyToClipboard};
