import React from 'react';

export default function () {
  const html = `<button
    type="button"
    data-unique-item-id="{{data.layerId + '_' + data.linked_product_id}}"
    data-linked-product-id="{{data.linked_product_id}}"
    data-content-layer-id="{{data._id + '_' + data.layerId}}"
    <# if (!data.available) { #>
        class="choice-item out-of-stock"
    <# } else if ((data.wc_backorder && data.wc_backorder === 'yes') || data.backorder) { #>
        class="choice-item on-backorder"
    <# } else { #>
        class="choice-item"
    <# } #>
    >
        <# if(data.thumbnail) { #><i class="mkl-pc-thumbnail"><span><img src="{{data.thumbnail}}" alt="" /></span></i><# } #>
        <# if(data.linked_product_id || data.description) { #>
           <div class="description-container">
              <# if(data.description) { #>
                  <span class="description">{{{data.description}}}</span>
              <# } else if(data.linked_product_id) { #>
                  <span class="description" data-linked-product-id="{{data.linked_product_id}}"></span>
              <# } #>
            </div>
        <# } #>
      <span class="text choice-name">{{data.name}} <span class="fetched-price"></span> <# if ( data.extra_price ) { #><span class="choice-price" data-price="{{data.extra_price}}" data-original-price="{{data.extra_price}}"><# if ( data.extra_price > 0 ) { #>+<# } #> {{{PC.utils.formatMoney(data.extra_price)}}}</span> <# } #></span>
    </button>
   <# if ( data.manage_stock && 'none' !== data.manage_stock && 0 == data.stock ) { #>
    <span class="out-of-stock">Out of stock</span>
   <# } else if (data.manage_stock && 'simple' === data.manage_stock && ((data.wc_backorder && data.wc_backorder === 'yes') || data.backorder)) { #>
    <span class="out-of-stock">Pre-order</span>
   <# } #>`

  return <script type="text/html" id="tmpl-mkl-pc-configurator-choice-item" dangerouslySetInnerHTML={{__html: html}}/>
}
