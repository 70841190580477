import React from 'react'
import Helmet from 'react-helmet'
import { HOSTNAME } from '../constants'

export const renderRoute = ({ store, route }) => props => {
  const { pathname } = props.location
  const slug = (
    route.slug ||
    route.path.substring(1) ||
    pathname.substring(1)
  ).replace(/\/$/,'')

  const pageStore = route.postCategoryId
    ? store.getPostCategory(route.postCategoryId)
    : store.getPage(slug)

  const Component = route.component
  const title = store.title || route.label || ''
  const meta = getMetaData({ title, pathname })

  return (
    <Component
      {...props}
      store={pageStore}
      helmet={<Helmet title={title} titleTemplate="%s – CHERRY XTRFY" meta={meta} />}
    />
  )
}

function getMetaData({ hostname = HOSTNAME, pathname, title = '' }) {
  return [
    {
      name: 'description',
      content: 'In it to win it'
    },
    {
      property: 'og:url',
      content: `${hostname}${pathname}`
    },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:title',
      content: `${title} – CHERRY XTRFY`
    },
    {
      property: 'og:description',
      content: 'In it to win it'
    },
    {
      property: 'og:image',
      content: 'https://cherryxtrfy.com/wp/wp-content/uploads/2023/04/Cherry-Xtrfy-132x132-1.jpg'
    }
  ]
}
