import React from 'react'
import { Cart } from '../Cart'
import styles from './currency.module.css';
import classNames from "classnames/bind";

const cn = classNames.bind(styles);

const Currency = ({
  children,
  currency: _currency,
  minimumFractionDigits = 2,
  eur,
  usd,
  onSale,
  regularPriceUsd,
  regularPriceEur,
  addon,
  inCart,
  totalSale
}) => {
  const cart = React.useContext(Cart)
  const currency = _currency || cart.currency
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits
  })

  // usd is sometimes an object..great thanks!
  if (typeof usd !== 'undefined' && usd && typeof usd.regular !== 'undefined') {
    usd = usd.regular;
  }

  const wrapper = text => {
    return addon ? ' (+ ' + text + ')' : text;
  }

  if (currency === 'EUR' && eur) {
    if(onSale && regularPriceEur) {
      return inCart ? (
        <div>
          <span className={cn(styles.salePrice)}>{formatter.format(Number(eur))}</span>
        </div>
      ) : (
        <div>
          <span className={totalSale ? cn(styles.salePrice, styles.totalSale) : styles.salePrice}>{formatter.format(Number(eur))}</span>
          &nbsp;
          <span className={totalSale ? cn(styles.originalPrice, styles.totalSale) : styles.originalPrice}>{formatter.format(Number(regularPriceEur))}</span>
        </div>
      );
    }

    return wrapper(formatter.format(Number(eur)))
  }

  if (currency === 'USD' && usd) {
    if(onSale && regularPriceUsd) {
      return inCart ? (
        <div>
          <span className={cn(styles.salePrice)}>{formatter.format(Number(usd)) + ' (USD)'}</span>
        </div>
      ) : (totalSale ? (
        <div>
          <span className={totalSale ? cn(styles.salePrice, styles.totalSale) : styles.salePrice}>{formatter.format(Number(usd))}</span>
          &nbsp;
          <span className={totalSale ? cn(styles.originalPrice, styles.totalSale) : styles.originalPrice}>{formatter.format(Number(regularPriceUsd))}</span>
        </div>
      ) : (
        <div>
          <span className={styles.salePrice}>{formatter.format(Number(usd)) + ' (USD)'}</span>
          &nbsp;
          <span className={styles.originalPrice}>{formatter.format(Number(regularPriceUsd)) + ' (USD)'}</span>
        </div>
      ));
    }

    return wrapper(formatter.format(Number(usd)) + ' (USD)')
  }

  if (children) {
    return wrapper(formatter.format(children))
  }

  return '—'
}

export default Currency
