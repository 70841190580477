export function base64Encode(data) {
  const buffer = new Buffer(data);
  return buffer.toString('base64');
}

export function base64Decode(data) {
  const buffer = new Buffer(data, 'base64');
  return buffer.toString('utf-8');
}

export function urlSafeBase64Encode(data) {
  const base64Data = base64Encode(data);

  return base64Data
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function urlSafeBase64Decode(data) {
  let urlUnsafeData = data.replace(/-/g, '+').replace(/_/g, '/');
  return base64Decode(urlUnsafeData);
}
