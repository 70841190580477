import React from 'react';

export default function () {
  const html = `<li class="layer-choices-title"><span>{{data.name}} <a href="#" class="close"><span>Close</span></a></span></li>
  <li class="choices-list"><ul>

  </ul></li>`;

  return <script type="text/html" id="tmpl-mkl-pc-configurator-choices" dangerouslySetInnerHTML={{__html: html}}/>
}
