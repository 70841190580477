import React from 'react'
import { woo } from '../../api'
import { Cart as CartContext } from '../../Cart'

const useGetCoupons = () => {
  const [coupons, setCoupons] = React.useState(null)

  // Get available coupons.
  React.useEffect(() => {
    woo.get('coupons?no_cache=true').then(data => {
      const coupons = Array.isArray(data) ? data : []
      setCoupons(coupons)
    })
  }, [])

  return coupons
}

export const useCoupons = (data, codes) => {
  const cart = React.useContext(CartContext)
  const { subtotal } = cart
  const { email } = data
  const coupons = useGetCoupons()

  if (
    !Array.isArray(codes) ||
    !Array.isArray(coupons) ||
    codes.length === 0 ||
    coupons.length === 0
  ) {
    return { validItems: [], amount: 0 }
  }

  // Validate discount codes and calculate its amount.
  const discounts = codes
    .map(code => {
      const coupon = coupons.find(
        coupon =>
          coupon.code && coupon.code.toLowerCase() === code.toLowerCase()
      )

      // Invalid coupon.
      if (!coupon || !isValid({ cart, subtotal, email }, coupon)) {
        return null
      }

      const {
        discount_type,
        product_ids,
        product_categories,
        excluded_product_ids,
        excluded_product_categories,
        exclude_sale_items
      } = coupon

      // Calculate the coupon's amount.
      // For fixed cart coupons we just deduct the coupon's full amount.
      if (discount_type === 'fixed_cart') {
        return { ...coupon, amount: parseFloat(coupon.amount) }
      }

      // Find all items that are applicable to the discount.
      const applicableItems = cart.state.items.filter(({ item, variation }) => {
        if (
          excluded_product_ids.includes(item.id) ||
          (variation && excluded_product_ids.includes(variation.id))
        ) {
          return false
        }

        if (
          excluded_product_categories.length > 0 &&
          excluded_product_categories.some(id =>
            item.categories.some(category => category.id === id)
          )
        ) {
          return false
        }

        // No items have been specified, so all are considered applicable.
        if (product_ids.length === 0 && product_categories.length === 0) {
          return true
        }

        if (
          product_categories.some(id =>
            item.categories.some(category => category.id === id)
          )
        ) {
          return true
        }

        if (
          product_ids.includes(item.id) ||
          (variation && product_ids.includes(variation.id))
        ) {
          return true
        }

        return false
      })

      let amount = 0

      for (const item of applicableItems) {
        if (discount_type === 'fixed_product') {
          amount += parseFloat(coupon.amount) * item.count
        } else if (discount_type === 'percent') {
          const percent = parseFloat(coupon.amount) / 100
          const product = item.variation || item.item
          const discount = product.price * percent
          amount += discount * item.count
        }
      }

      if (amount === 0) {
        return null
      }

      return { ...coupon, amount }
    })
    .filter(coupon => Boolean(coupon))

  const validDiscounts = discounts.filter(
    coupon => !coupon.individual_use || discounts.length === 1
  )
  const amount = validDiscounts.reduce((acc, discount) => discount.amount, 0)
  const freeShipping = validDiscounts.some(discount => discount.free_shipping)
  return { validItems: validDiscounts, amount, freeShipping }
}

function isValid({ cart, subtotal, email }, coupon) {
  const { items } = cart.state
  const {
    discount_type,
    date_expires,
    minimum_amount,
    maximum_amount,
    product_ids,
    product_categories,
    usage_limit,
    usage_limit_per_user,
    usage_count,
    used_by,
    email_restrictions,
    excluded_product_ids,
    excluded_product_categories,
    exclude_sale_items
  } = coupon

  // Expired.
  if (date_expires && new Date() > new Date(date_expires)) {
    return false
  }

  // Outside amount range.
  if (
    subtotal < Number.parseInt(minimum_amount) ||
    (Number.parseInt(maximum_amount) > 0 &&
      subtotal > Number.parseInt(maximum_amount))
  ) {
    return false
  }

  // Used too many times.
  if (Number.isFinite(usage_limit) && usage_count >= usage_limit) {
    return false
  }

  // Not an allowed email.
  if (
    email_restrictions.length > 0 &&
    (!email ||
      !email_restrictions.some(allowed => {
        if (!allowed.startsWith('*')) {
          return allowed === email
        }

        const allowedDomain = allowed.split('@')[1]
        const domain = email.split('@')[1]

        if (!allowedDomain || !domain) {
          return false
        }

        return allowedDomain === domain
      }))
  ) {
    return false
  }

  // Used too many times by this user.
  if (
    Number.isFinite(usage_limit_per_user) &&
    email &&
    used_by.reduce((total, curr) => (curr === email ? total + 1 : total), 0) >=
      usage_limit_per_user
  ) {
    return false
  }

  // There are disallowed products in the cart.
  if (
    discount_type === 'fixed_cart' &&
    excluded_product_ids.length > 0 &&
    excluded_product_ids.some(id =>
      items.some(
        item =>
          item.item.id === id || (item.variation && item.variation.id === id)
      )
    )
  ) {
    return false
  }

  // There are disallowed product categories in the cart.
  if (
    discount_type === 'fixed_cart' &&
    excluded_product_categories.length > 0 &&
    excluded_product_categories.some(id =>
      items.some(item =>
        item.item.categories.some(category => category.id === id)
      )
    )
  ) {
    return false
  }

  // There are products on sale in the fixed cart.
  if (
    discount_type === 'fixed_cart' &&
    exclude_sale_items &&
    items.some(item => item.item.on_sale)
  ) {
    return false
  }

  // No products or categories are specified.
  if (product_ids.length === 0 && product_categories.length === 0) {
    return discount_type === 'fixed_cart' || discount_type === 'percent'
  }

  // The cart contains at least one of the specified products.
  if (
    product_ids.some(id =>
      items.some(
        item =>
          item.item.id === id || (item.variation && item.variation.id === id)
      )
    )
  ) {
    return true
  }

  // The cart contains at least one of the specified product categories.
  if (
    product_categories.some(id =>
      items.some(item =>
        item.item.categories.some(category => category.id === id)
      )
    )
  ) {
    return true
  }

  return false
}
