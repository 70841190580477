import React from 'react'
import styles from './gallery-grid.module.css'
import classNames from 'classnames/bind'
import Button from "../../Button";

const cn = classNames.bind(styles)
export default class extends React.Component {
    render() {
        let heading = this.props.heading || '';
        let items = this.props.items || [];
        let layout = styles.layoutSingle;
        let leftColumn = [];
        let rightColumn = [];

        switch (items.length) {
          case 1:
            leftColumn = items;
            break;

          case 2:
            layout = styles.layoutMultiple;
            leftColumn.push(items[0]);
            rightColumn.push(items[1]);
            break;

          case 3:
            layout = styles.layoutMultiple;
            leftColumn.push(items[0]);
            rightColumn.push(items[1]);
            rightColumn.push(items[2]);
            break;

          case 4:
            layout = styles.layoutMultiple;
            leftColumn.push(items[0]);
            leftColumn.push(items[1]);
            rightColumn.push(items[2]);
            rightColumn.push(items[3]);
            break;
        }

        const itemTemplate = (item, idx) => {
            let textColor = item.text_color === 'black' ? styles.textContainerBlack : styles.textContainerWhite;

            return <div className={styles.item} key={idx}>
                <div className={styles.imageContainer}>
                  {item.video ? (
                    <video className={cn(styles.galleryGridVideo)} muted playsInline loop autoPlay poster={item.image.url}>
                      <source src={item.video} type="video/mp4"/>
                    </video>
                  ) : (
                    (item.image ? <img src={item.image.url} alt={item.image.alt}/> : '')
                  )}
                </div>
                <div className={cn(styles.textContainer, textColor)}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    {item.button ?
                        <Button className={styles.button} url={item.button.url}>{item.button.title}</Button> : ''}
                </div>
            </div>
        };

        return <div>
            <h2 className={styles.heading}>{heading}</h2>
            <div className={layout}>
                <div className={styles.column}>
                    {leftColumn.map(itemTemplate)}
                </div>
                <div className={styles.column}>
                    {rightColumn.map(itemTemplate)}
                </div>
            </div>
        </div>;
    }
}
