export default function (configuration, configurations) {
  configuration.forEach(config => {
    configurations.content.forEach(content => {
      if (content.layerId === config.layer_id) {
        content.choices.forEach(choice => {
          if (choice._id === config.choice_id) {
            config.extra_price = choice.extra_price;
          }
        })
      }
    })
  })
}
