import React, { Component } from 'react'
import classNames from 'classnames/bind'
import styles from './select.module.css'
import Input, { cn as inputCn } from './Input'
import Popover from './Popover'

const cn = classNames.bind(styles)

export default class Select extends Component {
  render() {
    const { className, value, onChange, label, options, id, required } = this.props
    return (
      <div className={cn('select-container', inputCn('input'), className)}>
        <select
          id={id}
          className={cn('select', inputCn('input-element'))}
          value={value || ''}
          onChange={(evt) => {
            onChange(evt.target.value)
          }}
        >
          <option value="" disabled>Select...</option>
          {options.map(({ value, label, data }) => (
            <option data-extra-info={typeof data != "undefined" && data ? JSON.stringify(data) : '0'} key={value} value={value}>{label}</option>
          ))}
        </select>
        <span className={inputCn('bar')} />
        <label className={cn('label', inputCn('label'))} htmlFor={id}>
          {label}
          {required ? <span className={cn('required')}> * </span> : null}
        </label>
      </div>
    )
  }
}
