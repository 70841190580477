import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import routes, { renderRoute } from './routes'
import styles from './app.module.css'
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Footer from './components/Footer'
import CookiesPolicy from './CookiesPolicy'
import { CartProvider } from './Cart'
import { StoreProvider } from './stores'
import { isProduction } from './utils'
import ReactGA from 'react-ga'

import './reset.css'

const menuLinks = routes.filter((route) => route.includeInMenu)
menuLinks.shift()

function App({ store, location }) {
  useEffect(() => {
    store.getInitialData(routes)
  }, [routes])

  // Log page views to Google Analytics.
  useEffect(() => {
    if (isProduction) {
      const { pathname, search } = location
      ReactGA.set({ page: pathname + search })
      ReactGA.pageview(pathname + search)
    }
  }, [location])

  return (
    <StoreProvider store={store}>
      <CartProvider>
        <ScrollToTop>
          <div className={styles.container}>
            <div className={styles.main}>
              <Header links={menuLinks} />
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    exact={route.path === '/'}
                    path={route.path}
                    render={renderRoute({ store, route })}
                  />
                ))}
              </Switch>
              <div className={styles.footerFix} />
            </div>
            <Footer store={store.footer} />
            <CookiesPolicy />
          </div>
        </ScrollToTop>
      </CartProvider>
    </StoreProvider>
  )
}

export default withRouter(App)