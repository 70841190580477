import React, { useState } from 'react'
import { Cart } from '../Cart'
import Link from './Link'
import styles from './header.module.css'
import styles2 from './menu-desktop.module.css'
import Logo from './icons/logo'
import Wrap from './Wrap'
import { MdChevronRight } from 'react-icons/md'
import cn from 'classnames'
import Search from './Search';
import ShippingLocation from './Shipping/ShippingLocation';
import NewsletterButton from './Newsletter/NewsletterButton';
import CartIcon from './icons/cart';
import LogoutButton from "./LogoutButton";
import useIsAdmin from "../hooks/use-is-admin";
import useIsReviewer from "../hooks/use-is-reviewer";

export const CheckoutIcon = ({ showCount = true }) => {
  const { state } = React.useContext(Cart)
  const count = state.items.reduce((acc, { count }) => acc + count, 0)

  return (
    <Link to={'/checkout'} className={cn(styles.cart)}>
      {showCount && count > 0 ? <div className={styles.cartCount}>{count}</div> : null}
      <CartIcon />
    </Link>
  )
}

const NavLink = ({ location, path, label, textColour }) => {
  let linkStyle = {};
  if(textColour) linkStyle.color = textColour;

  return (
    <Link to={path} style={linkStyle} className={cn(styles.link, styles2.navLink, {
      [styles.active]: location && location.pathname === path
    })}>
      {label}
    </Link>
  )
}

const DropdownLink = ({ location, path, label, dropdownLabel, bordered, position, items, textColour }) => {
  const [isOpen, toggleOpen] = useState(false)

  return (
    <div className={cn(styles.link, styles2.dropdownLink, {
      [styles2.toggled]: isOpen
    })}>
      <button
        onTouchStart={() => toggleOpen(!isOpen)}
        onBlur={() => toggleOpen(false)}
        className={cn(styles.link, styles2.button, {
          [styles.active]: location.pathname === path
        })}
      >
        {label}
        <MdChevronRight className={styles.chevron} />
      </button>
      <nav className={styles2.dropdown}>
        {position === 'first' ? (
          <div className={styles2.dropdownLinkContainer + (bordered ? ' ' + styles2.dropdownLinkContainerBordered : '')}>
            <NavLink path={path} location={location} label={dropdownLabel ? dropdownLabel : label} textColour={textColour} />
          </div>
        ): null}
        {items.map(({ path, label, bordered }) => (
          <div className={styles2.dropdownLinkContainer + (bordered ? ' ' + styles2.dropdownLinkContainerBordered : '')} key={path}>
            <NavLink
              location={location}
              path={path}
              label={label}
              textColour={textColour}
            />
          </div>
        ))}
        {position === 'last' ? (
          <div className={styles2.dropdownLinkContainer + (bordered ? ' ' + styles2.dropdownLinkContainerBordered : '')}>
            <NavLink path={path} location={location} label={dropdownLabel ? dropdownLabel : label} textColour={textColour} />
          </div>
        ): null}
      </nav>
    </div>
  )
}

const Desktop = ({ links, location }) => {
  const isAdmin = useIsAdmin();
  const isReviewer = useIsReviewer();

  return (
    <header className={cn(styles.header, styles.full)}>
      {isAdmin && (
        <div className={cn(styles.adminWarning, 'login-warning')}>
          <p>You are currently browsing as an admin</p>
        </div>
      )}
      {isReviewer && (
        <div className={cn(styles.reviewerWarning, 'login-warning')}>
          <p>You are currently browsing as a reviewer</p>
        </div>
      )}
      <div className={cn(styles2.topBar)}>
        <NewsletterButton />
        <CheckoutIcon />
        <ShippingLocation />
        {(isAdmin || isReviewer) && <LogoutButton />}
      </div>
      <nav className={cn(styles.nav, styles2.nav)}>
        <div className={styles.logoContainer}>
          <Link className={styles.link} to="/">
            <Logo className={styles.logo} />
          </Link>
        </div>
        <div className={cn(styles2.navLinks)}>
          {links.map(({ path, label, dropdownLabel, position, children, textColour }) => {
            const props = { path, label, location, dropdownLabel, position, textColour, key: path }
            return children.length > 0
              ? <DropdownLink {...props} items={children} />
              : <NavLink {...props} />
          })}
        </div>
        <Search />
      </nav>
    </header>
  )
}

export default Desktop
