import React from 'react'
import { Route } from 'react-router-dom'
import CategoryPage from './CategoryPage'
import Product from './Product'
import { observer } from 'mobx-react'

const Products = (props) => {
  const { match, store } = props
  const { items } = store

  if (match.isExact) {
    return <CategoryPage {...props} />
  }

  return (
    <Route
      path={`${props.match.url}/:productId`}
      render={routeProps => {
        const { productId: id } = routeProps.match.params
        const item = items.filter(item => item.slug === id)
        const data = item.length > 0 ? item[0] : null

        return (
          <Product id={id} data={data} store={store} {...routeProps} />
        )
      }}
    />
  )
}

export default observer(Products)
