import React from 'react'
import {woo} from '../api'
import reducer, {clampCount, initialState} from './reducer'
import {useIpData} from './useIpData'
import {getPriceUsd} from '../stores/Page'
import fetchPrice from "../configurator/fetchPrice";

const Cart = React.createContext()
const EuropeanCountryCodes = [
  'AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FO', 'FI', 'FR', 'DE', 'GI', 'GR', 'HU', 'IS', 'IE', 'IM', 'IT', 'XK', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'MD', 'MC', 'ME', 'NL', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'UA', 'GB', 'VA',
];

function CartProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [taxes, setTaxes] = React.useState([])
  const location = useIpData(state.didInit && !state.country)
  const country =
    state.country ||
    (location
      ? {
        code: location.country_code,
        name: location.country_name
      }
      : null);


  const tax = taxes.find(tax => country && country.code === tax.country)
  const isEuropean = EuropeanCountryCodes.includes(country ? country.code : '')
  const currency = isEuropean ? 'EUR' : 'USD'

  const calculateSubtotal = (items) => {
    let atLeastOneItemOnSale = false;
    const subtotal = items.reduce((prices, {item, variation, count, configuration}) => {
      let configurationTotal = 0;

      if (configuration) {
        configuration.forEach(config => {
          if (config.extra_price) {
            configurationTotal += Number(config.extra_price);
          }
        })
      }

      const product = variation ? variation : item;

      const salePriceEur = product.price;
      const regularPriceEur = item.use_recommended_products_price ? item.recommended_products_price : (product.on_sale ? product.regular_price : product.price);

      const salePriceUsd = product.on_sale ? product.sale_price_usd : product.price_usd;
      const regularPriceUsd = item.use_recommended_products_price ? item.recommended_products_price_usd : product.price_usd;

      if(product.on_sale) {
        atLeastOneItemOnSale = true;
      }

      return {
        regular: {
          eur: prices.regular.eur + ((configurationTotal + Number(regularPriceEur)) * count),
          usd: prices.regular.usd + ((configurationTotal + Number(regularPriceUsd)) * count)
        },
        sale: {
          eur: prices.sale.eur + ((configurationTotal + Number(salePriceEur)) * count),
          usd: prices.sale.usd + ((configurationTotal + Number(salePriceUsd)) * count)
        }
      };
    }, { regular: { eur: 0, usd: 0 }, sale: { eur: 0, usd: 0 } });

    if(!atLeastOneItemOnSale) {
      subtotal.sale.eur = 0;
      subtotal.sale.usd = 0;
    }

    return subtotal;
  }

  const subtotalFullData = calculateSubtotal(state.items);
  let subtotal, subtotalSale;

  if(currency === 'EUR') {
    subtotal = subtotalFullData.regular.eur;
    subtotalSale = subtotalFullData.sale.eur;
  } else {
    subtotal = subtotalFullData.regular.usd;
    subtotalSale = subtotalFullData.sale.usd;
  }

  const value = {state, dispatch, location, country, tax, currency, subtotal, subtotalSale, subtotalFullData}

  // Init checkout
  React.useEffect(() => {
    try {
      const serializedState = window.localStorage.getItem('cart')
      const state = JSON.parse(serializedState)

      woo.get(`taxes`).then(res => {
        if (Array.isArray(res)) {
          setTaxes(res)
        }
      })

      if (!state || !Array.isArray(state.items) || state.items.length === 0) {
        dispatch({type: 'init', state: initialState})
        return
      }

      if (Array.isArray(state.variationProducts) && state.variationProducts.length > 0 && !Array.isArray(state.variationProducts[0])) {
        state.variationProducts = [];
      }

      Promise.all(
        state.items.map(({id, variation, configuration}) => {
          const promises = [woo.get(`products/${id}?no_cache=true`)]

          if (configuration) {
            promises.push(
              woo.get(`configurations?id=${id}`)
            )
          }

          if (variation) {
            promises.push(
              woo.get(`products/${id}/variations/${variation}?no_cache=true`)
            )
          }

          return Promise.all(promises)
        })
      ).then(products => {
        state.items = state.items
          .map((item, index) => {
            const product = products[index][0]
            const usdPrices = getPriceUsd(product);
            product.price_usd = usdPrices.regular;
            product.sale_price_usd = usdPrices.sale;

            let variationsIndex = item.configuration ? 2 : 1;
            const variation = products[index][variationsIndex]

            if (variation) {
              const usdPrices = getPriceUsd(variation);
              variation.price_usd = usdPrices.regular;
              variation.sale_price_usd = usdPrices.sale;
            }

            const count = clampCount(item.count, {item: product, variation})
            const configuration = item.configuration;

            if (configuration) {
              const configurations = products[index][1]
              if (configurations) {
                fetchPrice(configuration, configurations);
              }
            }

            return {count, item: product, variation, configuration}
          })
          .filter(({item}) => item != null)

        dispatch({type: 'init', state})
      })
    } catch (err) {
      console.error(`Couldn't read state from localStorage.`, err)
    }
  }, [])

  return (
    <Cart.Provider value={value}>{props.children}</Cart.Provider>
  )
}

const CartConsumer = Cart.Consumer
export {Cart, CartProvider, CartConsumer}
