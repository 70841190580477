import React, { Component } from 'react'
import styles from './quick-info.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'
import Measure from 'react-measure'

const cn = classNames.bind(styles)

const parseStyles = styles =>
  styles
    .split(';')
    .filter(style => style.split(':')[0] && style.split(':')[1])
    .map(style => [
      style
        .split(':')[0]
        .trim()
        .replace(/-./g, c => c.substr(1).toUpperCase()),
      style.split(':')[1].trim()
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1]
      }),
      {}
    )

const Item = ({
  video,
  image,
  image_placement,
  image_styles,
  title,
  text,
  text_alignment,
  textColor: sectionTextColor,
  text_color,
  background_color = 'transparent'
}) => {
  const textAlign = text_alignment || 'left'
  let imagePlacement =
    typeof image_placement === 'string' ? image_placement : 'background'
  const color =
    imagePlacement === 'background'
      ? text_color || sectionTextColor || '#fff'
      : text_color || sectionTextColor || '#333'
  const imageStyle = parseStyles(image_styles || '')
  const ref = React.useRef()
  const containerRef = React.useRef()
  const [pageWidth, setPageWidth] = React.useState(null)
  const [heights, setHeights] = React.useState([null, null])

  if (
    pageWidth &&
    pageWidth < 600 &&
    (imagePlacement === 'left' || imagePlacement === 'right')
  ) {
    imagePlacement = 'top'
  }

  const imageIsInline = imagePlacement === 'left' || imagePlacement === 'right'
  const useImageHeight = imageIsInline && heights[0] >= heights[1]
  const height = useImageHeight ? heights[0] : 'auto'

  const titleId = title.toLowerCase().replace(/\s/g, '-').replace(/[^a-z0-9_-]/, '');

  return (
    <div
      className={cn(`container-${imagePlacement}`)}
      style={{ background: background_color }}
    >
      <div
        ref={containerRef}
        className={cn('item', `text-${textAlign}`, `image-${imagePlacement}`)}
        style={{ height }}
      >
        {imagePlacement === 'top' || imagePlacement === 'bottom' ? (
          <Wrap className={cn('image-wrapper')}>
            {video ? (
              <video className={cn(styles.video)} ref={ref}  muted playsInline loop autoPlay poster={image}>
                <source src={video} type="video/mp4"/>
              </video>
            ) : (
              <img
                loading='lazy'
                ref={ref}
                src={image ? image : undefined} /* This variable may be 'false', which throws an error */
                alt=""
                style={{
                  maxHeight: pageWidth * 0.5,
                  objectFit: 'contain',
                  ...imageStyle
                }}
              />
            )}
          </Wrap>
        ) : (
          (video ? (
              <video className={cn(styles.video)} ref={ref} muted playsInline loop autoPlay poster={image}>
                <source src={video} type="video/mp4"/>
              </video>
            ) : (
              <img
                ref={ref}
                src={image ? image : undefined} /* This variable may be 'false', which throws an error */
                alt=""
                style={imageStyle}
                loading='lazy'
              />
          ))
        )}
        <Measure
          onMeasure={({ height }) => {
            setTimeout(() => {
              setPageWidth(window.innerWidth)
              setHeights([height, ref.current.offsetHeight])
            }, 0)
          }}
        >
          {video ? <div/> : (
            <img
              loading='lazy'
              src={image ? image : undefined} /* This variable may be 'false', which throws an error */
              alt=""
              style={{
                pointerEvents: 'none',
                height: 'auto',
                opacity: 0,
                position: 'absolute',
                maxHeight: imageStyle.maxHeight || 560
              }}
            />
          )}
        </Measure>
        <div className={cn('content')}>
          <Wrap
            className={cn('wrap')}
            style={{
              ...(useImageHeight && { marginTop: 0 })
            }}
          >
            <h3
              className={cn('title')}
              dangerouslySetInnerHTML={{ __html: title }}
              style={{ color }}
              id={titleId}
            />
            <p
              className={cn('text')}
              dangerouslySetInnerHTML={{ __html: text }}
              style={{ color }}
            />
          </Wrap>
        </div>
      </div>
    </div>
  )
}

export default class QuickInfo extends Component {
  render() {
    const { items, textColor, className } = this.props

    return (
      <div className={cn('quick-info', className)}>
        {items.map((data, idx) => (
          <Item {...data} textColor={textColor} key={idx} />
        ))}
      </div>
    )
  }
}
