import React from 'react'
import styles from '../styles/utils.module.css'
import classNames from 'classnames/bind'

const cn = classNames.bind(styles)

export default ({ children, className, as, ...props }) => (
  <div {...props} className={cn('wrap', className)}>
    {children}
  </div>
)
