import React, { Component } from 'react'
import styles from './faq.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'

const cn = classNames.bind(styles)

export default class FAQ extends Component {
  render() {
    const { items, title } = this.props

    return (
      <div className={cn('faq')}>
        <Wrap className={cn('wrap')}>
          {title && <h3 className={cn('title')}>{title}</h3>}
          <div className={cn('fields')}>
            {items.map(({ question, answer = '' }, idx) =>
              <div className={cn('item')} key={idx}>
                <h4 className={cn('question')}>{question}</h4>
                <p className={cn('answer')}>{answer}</p>
              </div>
            )}
          </div>
        </Wrap>
      </div>
    )
  }
}
