import React from 'react';
import { Cart } from "../../Cart";
import fetchPrice from "../../configurator/fetchPrice";
import buyButtonStyles from "../Products/Product/hero.module.css";
import Button from "../Button";
import { Page } from "../../stores/Page";
import Share from "../../configurator/share";
import ShareModal from "../ShareModal";
import ConfiguratorModal from "../ConfiguratorModal";

export default ({product, buttonText, ...props}) => {
  const { dispatch } = React.useContext(Cart);
  const [clicked, setClicked] = React.useState(false);
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [maybeOpenConfigurationModal, setMaybeOpenConfigurationModal] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState('');
  let timeoutId;

  const hasConfigurationModal = product.acf.configurator_modal && product.acf.configurator_modal.active;

  buttonText = buttonText ?? 'Customize';

  const addConfigurationToCart = (evt) => {
    if (evt.target && evt.target.value) {
      try {
        let configuration = JSON.parse(evt.target.value);
        Page.getConfiguration(product.id).then(({isConfigurable, configurations}) => {
          product.isConfigurable = isConfigurable;
          product.configurations = configurations;
          fetchPrice(configuration, product.configurations);

          // Check for potential backorder items
          for(let i = 0; i < configuration.length; i++) {
            if(configuration[i].is_choice) {
              const layerId = configuration[i].layer_id;
              const choiceId = configuration[i].choice_id;

              const choice = PC.fe.getLayerContent(layerId).get(choiceId);
              if(!choice.get('available')) {
                return;
              } else if((choice.get('wc_backorder') === 'yes' || choice.get('backorder')) && choice.get('manage_stock') === 'simple') {
                configuration[i].has_backorder = true;
              }
            }
          }

          dispatch({ type: 'add', item: product, configuration: configuration })
          setClicked(true);
          setMaybeOpenConfigurationModal(true);
          timeoutId = setTimeout(() => setClicked(false), 1500);
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleShare = e => {
    if(typeof jQuery !== "undefined") {
      const data = e.target.value;
      if(!data) return;

      Share.getShareUrl(data, function(res) {
        if(res.body && res.body.url) {
          setShareModalOpen(true);
          setShareUrl(res.body.url);
        }
      });
    }
  }

  const closeShareModal = () => {
    setShareModalOpen(false);
  }

  let className = "configure-product configure-product-simple primary button btn btn-primary " + buyButtonStyles.button;

  if (clicked) {
    className += ' '  + buyButtonStyles.buttonClicked;
  }

  React.useEffect(() => {
    // Reset prices to their original values without VAT applied
    jQuery('.choice-price').each(function() {
      const $el = jQuery(this);
      $el.data('price', $el.data('original-price'));
    });
  }, []);

  return <>
    {hasConfigurationModal && maybeOpenConfigurationModal && (
      <ConfiguratorModal onClose={() => setMaybeOpenConfigurationModal(false)} baseProduct={product} />
    )}
    {shareModalOpen && (
      <ShareModal onClose={closeShareModal} url={shareUrl} />
    )}
    <Button id="xtrfy-configurator-button" className={className} type="button" data-product_id={product.id} {...props}>
      {clicked ? '✓' : buttonText}
    </Button>
    <textarea style={{display: 'none'}} cols="0" rows="0" onChange={addConfigurationToCart} id="product-configuration"/>
    <textarea style={{display: 'none'}} cols="0" rows="0" onChange={handleShare} id="product-configuration-share"/>
  </>
}
