import React from 'react'
import styles from './html.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'

const cn = classNames.bind(styles)

export default ({ content, font_weight }) => {
  const readMoreLink = `
    <a id="read-more-link" href="javascript:(function(){jQuery('#hidden-text').show();jQuery('#read-more-link').remove();return(void(0));})();" style="text-decoration:underline;"> Read more&#8230;</a>
  `;

  if(content.match(/<!--more-->/)) {
    content = content.replace(/(<!--more-->)((.|[\s\S])+)/gm, '$1<div id="hidden-text" style="display:none;">$2</div>');
    content = content.replace(/<!--more-->/, readMoreLink);
  }

  return (
    <Wrap className={cn('wrap')}>
      <div
        className={cn('content', styles['font-weight-' + font_weight])}
        dangerouslySetInnerHTML={{__html: content}}
      />
    </Wrap>
  )
}
