import React from 'react'
import styles from './components.module.css'
import classNames from 'classnames/bind'

const cn = classNames.bind(styles)

var monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

export default ({ children }) => {
  const date = new Date(children)
  const day = date.getDate()
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()

  return (
    <p className={cn('date')}>
      {`${("" + day).replace(/^0+/, '')} ${month}, ${year}`}
    </p>
  )
}
