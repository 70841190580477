import React from 'react';

function useIsAdmin() {
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    if(typeof jQuery === "undefined") return;

    setTimeout(() => {
      jQuery.ajax('/is-admin', {
        method: "GET",
        success: function () {
          setIsAdmin(true)
        },
        error: function () {
          setIsAdmin(false)
        }
      });
    }, 500);
  }, []);

  return isAdmin;
}

export default useIsAdmin;


