import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Mobile from './MenuMobile'
import Desktop from './MenuDesktop'
import { useIsMobile } from '../hooks/use-is-mobile'

const Header = ({ links, location }) => {
  const isMobile = useIsMobile()
  const children = links.filter(route => route.parent)
  const topLevel = links
    .filter(route => !route.parent)
    .map(route => ({
      ...route,
      children: children.filter(child => child.parent === route.slug)
    }))

  return isMobile ? (
    <Mobile location={location} links={topLevel} />
  ) : (
    <Desktop location={location} links={topLevel} />
  )
}

export default withRouter(Header)
