var isoCountries = {
  'AF' : { fullName: 'Afghanistan', currency: 'USD' },
  'AX' : { fullName: 'Aland Islands', currency: 'EUR' },
  'AL' : { fullName: 'Albania', currency: 'EUR'  },
  'DZ' : { fullName: 'Algeria', currency: 'EUR' },
  'AS' : { fullName: 'American Samoa', currency: 'USD' },
  'AD' : { fullName: 'Andorra', currency: 'EUR' },
  'AO' : { fullName: 'Angola', currency: 'USD' },
  'AI' : { fullName: 'Anguilla', currency: 'USD' },
  'AQ' : { fullName: 'Antarctica', currency: 'USD' },
  'AG' : { fullName: 'Antigua And Barbuda', currency: 'USD' },
  'AR' : { fullName: 'Argentina', currency: 'USD' },
  'AM' : { fullName: 'Armenia', currency: 'USD' },
  'AW' : { fullName: 'Aruba', currency: 'USD' },
  'AU' : { fullName: 'Australia', currency: 'USD' },
  'AT' : { fullName: 'Austria', currency: 'EUR' },
  'AZ' : { fullName: 'Azerbaijan', currency: 'USD' },
  'BS' : { fullName: 'Bahamas', currency: 'USD' },
  'BH' : { fullName: 'Bahrain', currency: 'USD' },
  'BD' : { fullName: 'Bangladesh', currency: 'USD' },
  'BB' : { fullName: 'Barbados', currency: 'USD' },
  'BY' : { fullName: 'Belarus', currency: 'USD' },
  'BE' : { fullName: 'Belgium', currency: 'EUR' },
  'BZ' : { fullName: 'Belize', currency: 'USD' },
  'BJ' : { fullName: 'Benin', currency: 'USD' },
  'BM' : { fullName: 'Bermuda', currency: 'USD' },
  'BT' : { fullName: 'Bhutan', currency: 'USD' },
  'BO' : { fullName: 'Bolivia', currency: 'USD' },
  'BA' : { fullName: 'Bosnia And Herzegovina', currency: 'EUR' },
  'BW' : { fullName: 'Botswana', currency: 'USD' },
  'BV' : { fullName: 'Bouvet Island', currency: 'USD' },
  'BR' : { fullName: 'Brazil', currency: 'USD' },
  'IO' : { fullName: 'British Indian Ocean Territory', currency: 'USD' },
  'BN' : { fullName: 'Brunei Darussalam', currency: 'USD' },
  'BG' : { fullName: 'Bulgaria', currency: 'EUR' },
  'BF' : { fullName: 'Burkina Faso', currency: 'USD' },
  'BI' : { fullName: 'Burundi', currency: 'USD' },
  'KH' : { fullName: 'Cambodia', currency: 'USD' },
  'CM' : { fullName: 'Cameroon', currency: 'USD' },
  'CA' : { fullName: 'Canada', currency: 'USD' },
  'CV' : { fullName: 'Cape Verde', currency: 'USD' },
  'KY' : { fullName: 'Cayman Islands', currency: 'USD' },
  'CF' : { fullName: 'Central African Republic', currency: 'USD' },
  'TD' : { fullName: 'Chad', currency: 'USD' },
  'CL' : { fullName: 'Chile', currency: 'USD' },
  'CN' : { fullName: 'China', currency: 'USD' },
  'CX' : { fullName: 'Christmas Island', currency: 'USD' },
  'CC' : { fullName: 'Cocos (Keeling) Islands', currency: 'USD' },
  'CO' : { fullName: 'Colombia', currency: 'USD' },
  'KM' : { fullName: 'Comoros', currency: 'USD' },
  'CG' : { fullName: 'Congo', currency: 'USD' },
  'CD' : { fullName: 'Congo, Democratic Republic', currency: 'USD' },
  'CK' : { fullName: 'Cook Islands', currency: 'USD' },
  'CR' : { fullName: 'Costa Rica', currency: 'USD' },
  'CI' : { fullName: 'Cote D\'Ivoire', currency: 'USD' },
  'HR' : { fullName: 'Croatia', currency: 'EUR' },
  'CU' : { fullName: 'Cuba', currency: 'USD' },
  'CY' : { fullName: 'Cyprus', currency: 'EUR' },
  'CZ' : { fullName: 'Czech Republic', currency: 'EUR' },
  'DK' : { fullName: 'Denmark', currency: 'EUR' },
  'DJ' : { fullName: 'Djibouti', currency: 'USD' },
  'DM' : { fullName: 'Dominica', currency: 'USD' },
  'DO' : { fullName: 'Dominican Republic', currency: 'USD' },
  'EC' : { fullName: 'Ecuador', currency: 'USD' },
  'EG' : { fullName: 'Egypt', currency: 'USD' },
  'SV' : { fullName: 'El Salvador', currency: 'USD' },
  'GQ' : { fullName: 'Equatorial Guinea', currency: 'USD' },
  'ER' : { fullName: 'Eritrea', currency: 'USD' },
  'EE' : { fullName: 'Estonia', currency: 'EUR' },
  'ET' : { fullName: 'Ethiopia', currency: 'USD' },
  'FK' : { fullName: 'Falkland Islands (Malvinas)', currency: 'USD' },
  'FO' : { fullName: 'Faroe Islands', currency: 'EUR' },
  'FJ' : { fullName: 'Fiji', currency: 'USD' },
  'FI' : { fullName: 'Finland', currency: 'EUR' },
  'FR' : { fullName: 'France', currency: 'EUR' },
  'GF' : { fullName: 'French Guiana', currency: 'EUR' },
  'PF' : { fullName: 'French Polynesia', currency: 'EUR' },
  'TF' : { fullName: 'French Southern Territories', currency: 'EUR' },
  'GA' : { fullName: 'Gabon', currency: 'USD' },
  'GM' : { fullName: 'Gambia', currency: 'USD' },
  'GE' : { fullName: 'Georgia', currency: 'USD' },
  'DE' : { fullName: 'Germany', currency: 'EUR' },
  'GH' : { fullName: 'Ghana', currency: 'USD' },
  'GI' : { fullName: 'Gibraltar', currency: 'USD' },
  'GR' : { fullName: 'Greece', currency: 'EUR' },
  'GL' : { fullName: 'Greenland', currency: 'USD' },
  'GD' : { fullName: 'Grenada', currency: 'USD' },
  'GP' : { fullName: 'Guadeloupe', currency: 'USD' },
  'GU' : { fullName: 'Guam', currency: 'USD' },
  'GT' : { fullName: 'Guatemala', currency: 'USD' },
  'GG' : { fullName: 'Guernsey', currency: 'USD' },
  'GN' : { fullName: 'Guinea', currency: 'USD' },
  'GW' : { fullName: 'Guinea-Bissau', currency: 'USD' },
  'GY' : { fullName: 'Guyana', currency: 'USD' },
  'HT' : { fullName: 'Haiti', currency: 'USD' },
  'HM' : { fullName: 'Heard Island & Mcdonald Islands', currency: 'USD' },
  'VA' : { fullName: 'Holy See (Vatican City State)', currency: 'USD' },
  'HN' : { fullName: 'Honduras', currency: 'USD' },
  'HK' : { fullName: 'Hong Kong', currency: 'USD' },
  'HU' : { fullName: 'Hungary', currency: 'EUR' },
  'IS' : { fullName: 'Iceland', currency: 'EUR' },
  'IN' : { fullName: 'India', currency: 'USD' },
  'ID' : { fullName: 'Indonesia', currency: 'USD' },
  'IR' : { fullName: 'Iran, Islamic Republic Of', currency: 'USD' },
  'IQ' : { fullName: 'Iraq', currency: 'USD' },
  'IE' : { fullName: 'Ireland', currency: 'EUR' },
  'IM' : { fullName: 'Isle Of Man', currency: 'USD' },
  'IL' : { fullName: 'Israel', currency: 'USD' },
  'IT' : { fullName: 'Italy', currency: 'EUR' },
  'JM' : { fullName: 'Jamaica', currency: 'USD' },
  'JP' : { fullName: 'Japan', currency: 'USD' },
  'JE' : { fullName: 'Jersey', currency: 'USD' },
  'JO' : { fullName: 'Jordan', currency: 'USD' },
  'KZ' : { fullName: 'Kazakhstan', currency: 'USD' },
  'KE' : { fullName: 'Kenya', currency: 'USD' },
  'KI' : { fullName: 'Kiribati', currency: 'USD' },
  'KR' : { fullName: 'Korea', currency: 'USD' },
  'KW' : { fullName: 'Kuwait', currency: 'USD' },
  'KG' : { fullName: 'Kyrgyzstan', currency: 'USD' },
  'LA' : { fullName: 'Lao People\'s Democratic Republic', currency: 'USD' },
  'LV' : { fullName: 'Latvia', currency: 'EUR' },
  'LB' : { fullName: 'Lebanon', currency: 'USD' },
  'LS' : { fullName: 'Lesotho', currency: 'USD' },
  'LR' : { fullName: 'Liberia', currency: 'USD' },
  'LY' : { fullName: 'Libyan Arab Jamahiriya', currency: 'USD' },
  'LI' : { fullName: 'Liechtenstein', currency: 'EUR' },
  'LT' : { fullName: 'Lithuania', currency: 'EUR' },
  'LU' : { fullName: 'Luxembourg', currency: 'EUR' },
  'MO' : { fullName: 'Macao', currency: 'USD' },
  'MK' : { fullName: 'Macedonia', currency: 'EUR' },
  'MG' : { fullName: 'Madagascar', currency: 'USD' },
  'MW' : { fullName: 'Malawi', currency: 'USD' },
  'MY' : { fullName: 'Malaysia', currency: 'USD' },
  'MV' : { fullName: 'Maldives', currency: 'USD' },
  'ML' : { fullName: 'Mali', currency: 'USD' },
  'MT' : { fullName: 'Malta', currency: 'EUR' },
  'MH' : { fullName: 'Marshall Islands', currency: 'USD' },
  'MQ' : { fullName: 'Martinique', currency: 'USD' },
  'MR' : { fullName: 'Mauritania', currency: 'USD' },
  'MU' : { fullName: 'Mauritius', currency: 'USD' },
  'YT' : { fullName: 'Mayotte', currency: 'USD' },
  'MX' : { fullName: 'Mexico', currency: 'USD' },
  'FM' : { fullName: 'Micronesia, Federated States Of', currency: 'USD' },
  'MD' : { fullName: 'Moldova', currency: 'EUR' },
  'MC' : { fullName: 'Monaco', currency: 'USD' },
  'MN' : { fullName: 'Mongolia', currency: 'USD' },
  'ME' : { fullName: 'Montenegro', currency: 'EUR' },
  'MS' : { fullName: 'Montserrat', currency: 'USD' },
  'MA' : { fullName: 'Morocco', currency: 'EUR' },
  'MZ' : { fullName: 'Mozambique', currency: 'USD' },
  'MM' : { fullName: 'Myanmar', currency: 'USD' },
  'NA' : { fullName: 'Namibia', currency: 'USD' },
  'NR' : { fullName: 'Nauru', currency: 'USD' },
  'NP' : { fullName: 'Nepal', currency: 'USD' },
  'NL' : { fullName: 'Netherlands', currency: 'EUR' },
  'AN' : { fullName: 'Netherlands Antilles', currency: 'EUR' },
  'NC' : { fullName: 'New Caledonia', currency: 'USD' },
  'NZ' : { fullName: 'New Zealand', currency: 'USD' },
  'NI' : { fullName: 'Nicaragua', currency: 'USD' },
  'NE' : { fullName: 'Niger', currency: 'USD' },
  'NG' : { fullName: 'Nigeria', currency: 'USD' },
  'NU' : { fullName: 'Niue', currency: 'USD' },
  'NF' : { fullName: 'Norfolk Island', currency: 'USD' },
  'MP' : { fullName: 'Northern Mariana Islands', currency: 'USD' },
  'NO' : { fullName: 'Norway', currency: 'EUR' },
  'OM' : { fullName: 'Oman', currency: 'USD' },
  'PK' : { fullName: 'Pakistan', currency: 'USD' },
  'PW' : { fullName: 'Palau', currency: 'USD' },
  'PS' : { fullName: 'Palestinian Territory, Occupied', currency: 'USD' },
  'PA' : { fullName: 'Panama', currency: 'USD' },
  'PG' : { fullName: 'Papua New Guinea', currency: 'USD' },
  'PY' : { fullName: 'Paraguay', currency: 'USD' },
  'PE' : { fullName: 'Peru', currency: 'USD' },
  'PH' : { fullName: 'Philippines', currency: 'USD' },
  'PN' : { fullName: 'Pitcairn', currency: 'USD' },
  'PL' : { fullName: 'Poland', currency: 'EUR' },
  'PT' : { fullName: 'Portugal', currency: 'EUR' },
  'PR' : { fullName: 'Puerto Rico', currency: 'USD' },
  'QA' : { fullName: 'Qatar', currency: 'USD' },
  'RE' : { fullName: 'Reunion', currency: 'USD' },
  'RO' : { fullName: 'Romania', currency: 'EUR' },
  'RU' : { fullName: 'Russian Federation', currency: 'EUR' },
  'RW' : { fullName: 'Rwanda', currency: 'USD' },
  'BL' : { fullName: 'Saint Barthelemy', currency: 'USD' },
  'SH' : { fullName: 'Saint Helena', currency: 'USD' },
  'KN' : { fullName: 'Saint Kitts And Nevis', currency: 'USD' },
  'LC' : { fullName: 'Saint Lucia', currency: 'USD' },
  'MF' : { fullName: 'Saint Martin', currency: 'USD' },
  'PM' : { fullName: 'Saint Pierre And Miquelon', currency: 'USD' },
  'VC' : { fullName: 'Saint Vincent And Grenadines', currency: 'USD' },
  'WS' : { fullName: 'Samoa', currency: 'USD' },
  'SM' : { fullName: 'San Marino', currency: 'EUR' },
  'ST' : { fullName: 'Sao Tome And Principe', currency: 'USD' },
  'SA' : { fullName: 'Saudi Arabia', currency: 'USD' },
  'SN' : { fullName: 'Senegal', currency: 'USD' },
  'RS' : { fullName: 'Serbia', currency: 'EUR' },
  'SC' : { fullName: 'Seychelles', currency: 'USD' },
  'SL' : { fullName: 'Sierra Leone', currency: 'USD' },
  'SG' : { fullName: 'Singapore', currency: 'USD' },
  'SK' : { fullName: 'Slovakia', currency: 'EUR' },
  'SI' : { fullName: 'Slovenia', currency: 'USD' },
  'SB' : { fullName: 'Solomon Islands', currency: 'USD' },
  'SO' : { fullName: 'Somalia', currency: 'USD' },
  'ZA' : { fullName: 'South Africa', currency: 'USD' },
  'GS' : { fullName: 'South Georgia And Sandwich Isl.', currency: 'USD' },
  'ES' : { fullName: 'Spain', currency: 'EUR' },
  'LK' : { fullName: 'Sri Lanka', currency: 'USD' },
  'SD' : { fullName: 'Sudan', currency: 'USD' },
  'SR' : { fullName: 'Suriname', currency: 'USD' },
  'SJ' : { fullName: 'Svalbard And Jan Mayen', currency: 'USD' },
  'SZ' : { fullName: 'Swaziland', currency: 'USD' },
  'SE' : { fullName: 'Sweden', currency: 'EUR' },
  'CH' : { fullName: 'Switzerland', currency: 'EUR' },
  'SY' : { fullName: 'Syrian Arab Republic', currency: 'USD' },
  'TW' : { fullName: 'Taiwan', currency: 'USD' },
  'TJ' : { fullName: 'Tajikistan', currency: 'USD' },
  'TZ' : { fullName: 'Tanzania', currency: 'USD' },
  'TH' : { fullName: 'Thailand', currency: 'USD' },
  'TL' : { fullName: 'Timor-Leste', currency: 'USD' },
  'TG' : { fullName: 'Togo', currency: 'USD' },
  'TK' : { fullName: 'Tokelau', currency: 'USD' },
  'TO' : { fullName: 'Tonga', currency: 'USD' },
  'TT' : { fullName: 'Trinidad And Tobago', currency: 'USD' },
  'TN' : { fullName: 'Tunisia', currency: 'USD' },
  'TR' : { fullName: 'Turkey', currency: 'USD' },
  'TM' : { fullName: 'Turkmenistan', currency: 'USD' },
  'TC' : { fullName: 'Turks And Caicos Islands', currency: 'USD' },
  'TV' : { fullName: 'Tuvalu', currency: 'USD' },
  'UG' : { fullName: 'Uganda', currency: 'USD' },
  'UA' : { fullName: 'Ukraine', currency: 'EUR' },
  'AE' : { fullName: 'United Arab Emirates', currency: 'USD' },
  'GB' : { fullName: 'United Kingdom', currency: 'EUR' },
  'US' : { fullName: 'United States', currency: 'USD' },
  'UM' : { fullName: 'United States Outlying Islands', currency: 'USD' },
  'UY' : { fullName: 'Uruguay', currency: 'USD' },
  'UZ' : { fullName: 'Uzbekistan', currency: 'USD' },
  'VU' : { fullName: 'Vanuatu', currency: 'USD' },
  'VE' : { fullName: 'Venezuela', currency: 'USD' },
  'VN' : { fullName: 'Viet Nam', currency: 'USD' },
  'VG' : { fullName: 'Virgin Islands, British', currency: 'USD' },
  'VI' : { fullName: 'Virgin Islands, U.S.', currency: 'USD' },
  'WF' : { fullName: 'Wallis And Futuna', currency: 'USD' },
  'EH' : { fullName: 'Western Sahara', currency: 'USD' },
  'YE' : { fullName: 'Yemen', currency: 'USD' },
  'ZM' : { fullName: 'Zambia', currency: 'USD' },
  'ZW' : { fullName: 'Zimbabwe', currency: 'USD' }
};

export function getCountryName(countryCode) {
  if (isoCountries.hasOwnProperty(countryCode))
    return isoCountries[countryCode].fullName;

  return countryCode;
}

export function getCountryCurrency(countryCode) {
  if(isoCountries.hasOwnProperty(countryCode))
    return isoCountries[countryCode].currency;

  return 'EUR';
}
