import React from 'react';

export default function () {
  const html = `<button class="layer-item" type="button">
        <i class="img"><# if(data.image.url) { #><img src="{{data.image.url}}" alt="img_{{data.image.id}}" /><# } #></i>
      <span class="text layer-name">{{data.name}}</span>
      <# if ( data.description && PC.fe.config.show_layer_description ) { #><span class="description">{{{data.description}}}</span><# } #>
      <div class="layer-overlay"></div>
    </button>`

  return <script type="text/html" id="tmpl-mkl-pc-configurator-layer-item" dangerouslySetInnerHTML={{__html: html}}/>
}
