import React from 'react'

const styles = {
  display: 'block',
  height: 'auto',
  width: '100%'
}

const Media = ({ type, src, className }) => (
  type === 'video' ? (
    <video
      autoPlay
      className={className}
      style={styles}
      loop
      muted
      preload='auto'
      height='auto'
      width='auto'
      src={src}
    />
  ) : (
    <img style={styles} className={className} src={src} alt='Hero' />
  )
)

export default Media
