import React from 'react';

let setValue = function (textarea, value) {
  // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
  var nativeTextAreaSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
  nativeTextAreaSetter.call(textarea, value);

  var event = new Event('input', {bubbles: true});
  textarea.dispatchEvent(event);
};

const handler = function (e) {
  let $input = jQuery(this.pc_configurator_data);

  // Move configuration data into textarea which triggers add to cart in React
  let textarea = document.getElementById('product-configuration');

  // Clear textarea first to enable adding the same configuration two times in a row
  setValue(textarea, '');
  setValue(textarea, $input.val());

  // Close the modal if adding product to cart
  PC.fe.modal.close();

  // Do not submit the form as a regular form POST
  e.preventDefault();
  return false;
};

const shareHandler = function (e) {
  var data = PC.fe.save_data.save();
  let textarea = document.getElementById('product-configuration-share');
  setValue(textarea, '');
  setValue(textarea, data);
}

export default function () {

  // Yes, because otherwise multiple handlers will be added
  if(typeof jQuery !== 'undefined') {
    jQuery(document).off('submit', '#configurator-form', handler);
    jQuery(document).on('submit', '#configurator-form', handler);

    jQuery(document).off('click', '#share-button', shareHandler);
    jQuery(document).on('click', '#share-button', shareHandler);
  }

  const html = `
    <div class="configurator-footer-container">
      <div class="footer-section footer__section-left">
        <h3 class="product-name">{{{data.name}}}</h3>
      </div>
      <div class="footer-section footer__section-middle">
        <div class="form form-cart">
          <div id="xtrfy-extra-cost">
            <div id="xtrfy-total-footer" class="xtrfy-total">Total: <span class="xtrfy-total-cost"></span></div>
          </div>
          <div class="pc_configurator_form">
            <# if ( data.is_in_stock ) { #>
              <# if ( ! data.show_form ) { #>
                <div class="quantity">
                    <label class="screen-reader-text" for="quantity_60b72a479ab97">M4 Black quantity</label>
                </div>
              <# } #>
              <# if ( data.show_form ) { #>
                <form class="cart" method="post" enctype='multipart/form-data' id="configurator-form">
                  <input type="hidden" name="pc_configurator_data" id="pc_configurator_data">
                  <input type="hidden" name="add-to-cart">
                </form>
              <# } #>
            <# } #>
          </div>
        </div>
      </div>
      <div class="footer-section footer__section-right">
        <div class="share-container">
          <button type="button" id="share-button" class="primary button btn btn-primary share-button">Share</button>
        </div>
        <div class="form form-cart">
            <div class="pc_configurator_form">
              <# if ( data.is_in_stock ) { #>
                  <button type="button" class="primary button btn btn-primary configurator-add-to-cart"
                          style="float: right;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="37.118" height="33" viewBox="0 0 37.118 33">
                      <path id="Path_2" data-name="Path 2"
                            d="M34.031-9.475a1.506,1.506,0,0,1-.548.9,1.5,1.5,0,0,1-.935.322H13.664l.387,2.062H31.389a1.406,1.406,0,0,1,1.16.58,1.56,1.56,0,0,1,.322,1.289l-.387,1.611A3.491,3.491,0,0,1,34-1.386a3.5,3.5,0,0,1,.548,1.9,3.474,3.474,0,0,1-1.063,2.546,3.579,3.579,0,0,1-5.092,0A3.511,3.511,0,0,1,27.328.483a3.357,3.357,0,0,1,1.1-2.546H14.889a3.357,3.357,0,0,1,1.1,2.546,3.511,3.511,0,0,1-1.063,2.578,3.579,3.579,0,0,1-5.092,0A3.474,3.474,0,0,1,8.766.516a3.551,3.551,0,0,1,.483-1.8A3.8,3.8,0,0,1,10.57-2.643L6.059-24.75H1.547a1.492,1.492,0,0,1-1.1-.451A1.492,1.492,0,0,1,0-26.3v-1.031a1.492,1.492,0,0,1,.451-1.1,1.492,1.492,0,0,1,1.1-.451H8.186a1.411,1.411,0,0,1,.935.354,1.637,1.637,0,0,1,.548.87l.58,2.9h25.33a1.469,1.469,0,0,1,1.225.58,1.4,1.4,0,0,1,.258,1.289Z"
                            transform="translate(0 28.875)" fill="#707070"/>
                    </svg>
                    <span>Add to cart</span>
                  </button>
              <# } else { #>
                  <div class="out-of-stock"></div>
              <# } #>
            </div>
        </div>
      </div>
    </div>`;

  return <script type="text/html" id="tmpl-mkl-pc-configurator-footer" dangerouslySetInnerHTML={{__html: html}}/>
}
