import { extendObservable, action } from 'mobx'
import { wp } from '../api'

export class PostCategory {
  constructor(store, id) {
    this.store = store

    extendObservable(this, {
      id,
      count: null,
      title: '',
      posts: []
    })

    if (id === 1) {
      // Don't care about uncategorized
      return
    }
  }

  getPosts = () => {
    wp.get(`posts?category=${this.id}&per_page=100`).then(this.setPosts)
  }

  setPosts = action(posts => {
    this.posts = posts
  })

  setData = ({ name, count }) => {
    this.title = name
    this.count = count
  }
}
