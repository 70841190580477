import React,{useEffect} from 'react'
import styles from './checkout.module.css'
import Wrap from '../Wrap'
import Cart from './Cart'
import { Cart as CartContext } from '../../Cart'
import { useCoupons } from './use-coupons'
import Media from 'react-media'

const Checkout = ({ match, history, helmet }) => {
  const cart = React.useContext(CartContext)
  const [_data, setData] = React.useState({})
  const data = { ..._data, country: cart.country }
  const [couponList, setCouponList] = React.useState([])
  const coupons = useCoupons(data, couponList)
  const [didMount, setDidMount] = React.useState(false)

  useEffect(() => {
    setDidMount(true)
  }, [])

  const taxRate = Number(cart.tax ? cart.tax.rate : 0) / 100

  let taxableAmount = cart.subtotal - coupons.amount
  const vat = taxableAmount * taxRate
  const total = cart.subtotal - coupons.amount
  const totalRounded = +parseFloat(total).toFixed(2);

  let taxableAmountSale = cart.subtotalSale ? cart.subtotalSale - coupons.amount : 0;
  const vatSale = taxableAmountSale * taxRate;
  const totalSale = cart.subtotalSale ? cart.subtotalSale - coupons.amount : 0;
  const totalSaleRounded = +parseFloat(totalSale).toFixed(2);

  const cartElement = (
    <Cart
      vat={vat}
      vatSale={vatSale}
      total={totalRounded}
      totalSale={totalSaleRounded}
    />
  )

  return (
    <div className={styles.checkout}>
      {helmet}
      <Media query="(max-width: 980px)">
        {matches => matches && didMount ? (
          <div className={styles.mobileWrap}>
            <div className={styles['order-summary']}>
              {cartElement}
            </div>
          </div>
        ) : (
        <div className={styles.main}>
          <Wrap className={styles.wrap}>
            <div className={styles['order-summary']}>
              {cartElement}
            </div>
          </Wrap>
        </div>
        )}
      </Media>
    </div>
  )
}

export default Checkout
