import React from 'react';
import styles from './colourSwatch.module.css';
import classNames from "classnames/bind";

const cn = classNames.bind(styles);

export default ({data, onSwatchClicked}) => (
  <div
    className={cn(styles.swatch, {[styles.whiteSwatch]: data.colour.toLowerCase() === "#ffffff"})}
    style={{backgroundColor: data.colour}} onClick={e => onSwatchClicked(e, data)}
  />
);
