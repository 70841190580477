import React, { Component } from 'react'
import styles from './products.module.css'
import Measure from 'react-measure'
import Wrap from '../Wrap'
import ProductTile from './ProductTile'
import cn from 'classnames'
import routes from '../../routes';

const getProducts = (items, itemsPerRow) => {
  if (!Array.isArray(items)) {
    return []
  }

  const numberOfRows = Math.ceil(items.length / itemsPerRow)
  return Array.from(
    { length: numberOfRows * itemsPerRow },
    (k, index) => items[index] || { isPlaceholder: true }
  )
}

export const getUrl = (product) => {
  if(product.acf && product.acf.hide_product) {
    return '/';
  }

  if (product.status === 'draft' || product.status === 'pending') {
    const possibleChildSlug = product.categories[0].slug;
    const possibleParentSlug = routes.filter(route => (route.slug === possibleChildSlug && !route.skipParentInUrl));
    if(possibleParentSlug.length > 0) {
      if(typeof possibleParentSlug[0] !== 'undefined') {
        const parentSlug = possibleParentSlug[0].parent ?? null;
        if(parentSlug !== null) {
          return `/${parentSlug}/${product.categories[0].slug}/${product.slug}/`;
        }
      }
    }

    return `/${product.categories[0].slug}/${product.slug}/`
  }

  if (product.permalink) {

    const matches = product.permalink.match(/^.*\/product\/[\w-]+\/[\w-]+\/[\w-]+/);
    if(matches && matches.length) {
      return product.permalink.replace(/^.*\/product\/[\w-]+/, '');
    }

    return product.permalink.replace(/^.*\/product/, '');
  }

  return null;
}

export default class Grid extends Component {
  state = { itemsPerRow: 1 }

  onMeasure = ({ width }) => {
    const breakpoints = [0, 600, 920, 1180]
    let itemsPerRow = 0

    breakpoints.forEach((bp) => {
      if (width > bp) {
        itemsPerRow++
      }
    })

    this.setState({ itemsPerRow })
  }

  render() {
    const { items, label, className, productTileClassName, isGroup, description, shouldCenter } = this.props

    if (!Array.isArray(items) || items.length === 0) {
      return null
    }

    const { itemsPerRow } = this.state
    const products = getProducts(items, itemsPerRow)

    if(shouldCenter) {
      return (
        <Wrap className={className}>
          {label &&
            (isGroup ? (
              <React.Fragment>
                <h3 className={cn(styles.title, styles.isGroup)}>{label}</h3>
                {description && (
                  <p className={styles.description}>{description}</p>
                )}
              </React.Fragment>
            ) : (
              <h2 className={styles.title}>{label}</h2>
            ))}
          <div className={styles.items}>
            {products.map((product, idx) => ( product.isPlaceholder ? (
                <></>
              ) : (
                <ProductTile
                  key={idx}
                  className={cn(styles.item, styles[`row-${itemsPerRow}`], productTileClassName)}
                  url={getUrl(product)}
                  {...product}
                />
              )
            ))}
          </div>
        </Wrap>
      )
    }

    return (
      <Measure onMeasure={this.onMeasure}>
        <Wrap className={className}>
          {label &&
            (isGroup ? (
              <React.Fragment>
                <h3 className={cn(styles.title, styles.isGroup)}>{label}</h3>
                {description && (
                  <p className={styles.description}>{description}</p>
                )}
              </React.Fragment>
            ) : (
              <h2 className={styles.title}>{label}</h2>
            ))}
          <div className={styles.items}>
            {products.map((product, idx) => (
              <ProductTile
                key={idx}
                className={cn(styles.item, styles[`row-${itemsPerRow}`], {
                  [styles.placeholder]: product.isPlaceholder
                }, productTileClassName)}
                url={getUrl(product)}
                {...product}
              />
            ))}
          </div>
        </Wrap>
      </Measure>
    )
  }
}
