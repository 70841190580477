import React from 'react'
import styles from './components.module.css'
import classNames from 'classnames/bind'

const cn = classNames.bind(styles)

export default ({ large, small, children, tag = 'h2' }) =>
  React.createElement(
    tag,
    { className: cn('title', { large, small }) },
    children
  )
