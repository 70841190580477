import {extendObservable, action, computed, decorate} from 'mobx'
import {woo} from '../api'
import Store from './index'

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function searchProducts(query, context) {
  if (context.lastQuery === query) {
    return
  }

  context.status = 'fetching'
  context.lastQuery = query;

  woo.get(`../search/${query}`).then(products => {
    Store.getProductsById(products).then(finalProducts => {
      context.setItems(finalProducts);
      context.status = 'fetched';
    });
  });
}

const debouncedSearch = debounce(searchProducts, 300);

function getCurrencyType() {
  if(typeof jQuery === "undefined") {
    return 'EUR';
  }

  const currencyType = jQuery('#current-country').data('currency-type');
  return currencyType || 'EUR';
}

export class Page {
  constructor(store, slug) {
    this.store = store

    extendObservable(this, {
      slug,
      productCategoryId: null,
      acf: null,
      content: null,
      title: '',
      hero: null,
      data: null,
      items: [],
      variations: null,
      status: '',
      lastQuery: '',
    })
  }

  get _accessories() {
    if (!this.productCategoryId) {
      return null
    }

    if (this.slug === 'accessories') {
      return this.items
    }

    const accessoriesPage = this.store.pages.get('accessories')

    if (!accessoriesPage) {
      return []
    }

    return accessoriesPage.items.filter(accessory => {
      const {productCategories} = accessory.acf || {}
      return (
        Array.isArray(productCategories) &&
        productCategories.includes(this.productCategoryId)
      )
    })
  }

  get accessories() {
    const accessories = this._accessories

    if (!accessories) {
      return null
    }

    return accessories.filter(accessory =>
      accessory.categories.some(category => category.slug === 'accessories')
    )
  }

  get spareParts() {
    const accessories = this._accessories

    if (!accessories) {
      return null
    }

    return accessories.filter(accessory =>
      accessory.categories.some(category => category.slug === 'spare-parts')
    )
  }

  getConfiguration = async id => {
    if (!Array.isArray(this.items)) {
      return
    }

    const items = [...this.items]
    const index = items.findIndex(item => item.id === id)
    let item    = items[index]

    if(!item) { // This item probably belongs to another category
      item = await woo.get(`products/${id}`);
    }

    if (!item || item.isConfigurable || item.isConfigurable === false) {
      return
    }

    const productConfigurations = await woo.get(`configurations?id=${id}`);

    let isConfigurable = productConfigurations && productConfigurations.isConfigurable;

    // WooCommerce Product Configurator reads data from PC.productData global
    if (productConfigurations) {
      const key = 'prod_' + id;
      PC.productData = PC.productData || {};
      PC.productData[key] = productConfigurations;
      PC_config = {
        "ajaxurl": "/wp/wp-admin/admin-ajax.php",
        "lang": {
          "money_precision": 2,
          "money_symbol": "&euro;",
          "money_decimal": ".",
          "money_thousand": ",",
          "money_format": "%s&nbsp;%v",
          "required_error_message": "%s is required",
          "out_of_stock_error_message": "%s is out of stock, please select something else.",
          "outofstock_nomorechoice": "No item in stock"
        },
        "config": {
          "inline": false,
          "where": "out",
          "bg_image": "/wp/wp-content/plugins/product-configurator-for-woocommerce/assets/images/default-bg.jpg",
          "close_configurator_on_add_to_cart": false,
          "close_choices_when_selecting_choice": false,
          "close_choices_when_selecting_choice_desktop": false,
          "choice_description_no_tooltip": false,
          "image_loading_mode": "lazy",
          "show_choice_description": false,
          "show_layer_description": false,
          "show_active_choice_in_layer": 1,
          "show_active_choice_image_in_layer": false,
          "sku_mode": "individual",
          "show_form": false,
          "no_toggle": false,
          "open_first_layer": false,
          "auto_scroll": true,
          "angles": {
            "show_image": true,
            "show_name": true,
            "save_current":false
          },
          "add_tax": false,
          "tax_enabled": false,
          "tax_settings": {
            "prices_include_tax": true,
            "tax_display_mode": "incl"
          },
          "relative_price":false,
          "enable_selection_when_outofstock":true,
          "hide_outofstock_items":false
        }
      };
    }

    items[index] = {
      ...items[index],
      isConfigurable: isConfigurable,
      configurations: productConfigurations
    }

    // Create a new array so that mobx registers the change.
    this.items = items
  }

  getStockQuantity = async id => {
    if (!Array.isArray(this.items)) {
      return
    }

    const items = [...this.items]
    const index = items.findIndex(item => item.id === id)
    const item = items[index]

    if (!item || item.didGetStockQuantity) {
      return
    }

    const requests = [woo.get(`products/${id}?no_cache=true`)]

    if (Array.isArray(item.variations) && item.variations.length > 0) {
      requests.push(woo.get(`products/${id}/variations?no_cache=true`))
    }

    // Get fresh data and update the product.
    const [product, variations] = await Promise.all(requests)
    let variationProducts = null

    if (Array.isArray(variations)) {
      variationProducts = variations.map(variation => ({
        ...variation,
        // Get USD price from meta data for easier access.
        price_usd: getPriceUsd(variation)
      }))
    }

    items[index] = {
      ...items[index],
      ...product,
      didGetStockQuantity: true,
      variationProducts
    }

    // Create a new array so that mobx registers the change.
    this.items = items
  }

  getProducts = () => {
    if (
      !this.productCategoryId ||
      this.items.length > 0 ||
      this.status === 'fetching' ||
      this.status === 'fetched'
    ) {
      return
    }

    this.status = 'fetching'

    woo.get(`products?category=${this.productCategoryId}&per_page=100`).then(items => {
      this.setItems(items)
      this.status = 'fetched'
    })
  }

  getProductsSearch = query => {
    debouncedSearch(query, this);
  }

  setItems = action(items => {
    // Remove stock data so that we don't use cached values.
    this.items = items.map(item => {
      delete item.stock_status
      delete item.stock_quantity
      delete item.manage_stock
      delete item.backorders_allowed
      delete item.backorders

      // Get USD price from meta-data for easier access.
      const usdPrices = getPriceUsd(item);
      item.price_usd = usdPrices.regular;
      item.sale_price_usd = usdPrices.sale;

      return item
    })
  })

  setData = action(({acf, content, title}) => {
    this.acf = acf
    this.content = content
    this.title = title ? title.rendered : ''
    this.productCategoryId = acf ? acf.category_connection : null

    if (
      !acf ||
      !acf.category_connection ||
      !Array.isArray(acf.hero) ||
      acf.hero.length === 0
    ) {
      return
    }

    const hero = acf.hero[0]
    this.hero = {
      type: hero.acf_fc_layout,
      src: hero[hero.acf_fc_layout]
    }
  })

  static getConfiguration = async id => {
    const item = await woo.get(`products/${id}`);

    if (!item || item.isConfigurable || item.isConfigurable === false) {
      return;
    }

    const productConfigurations = await woo.get(`configurations?id=${id}`);

    let isConfigurable = productConfigurations && productConfigurations.isConfigurable;

    // WooCommerce Product Configurator reads data from PC.productData global
    if (productConfigurations) {
      const key = 'prod_' + id;
      PC.productData = PC.productData || {};
      PC.productData[key] = productConfigurations;
      PC_config = {
        "ajaxurl": "/wp/wp-admin/admin-ajax.php",
        "lang": {
          "money_precision": 2,
          "money_symbol": "&euro;",
          "money_decimal": ".",
          "money_thousand": ",",
          "money_format": "%s&nbsp;%v",
          "required_error_message": "%s is required",
          "out_of_stock_error_message": "%s is out of stock, please select something else.",
          "outofstock_nomorechoice": "No item in stock"
        },
        "config": {
          "inline": false,
          "where": "out",
          "bg_image": "/wp/wp-content/plugins/product-configurator-for-woocommerce/assets/images/default-bg.jpg",
          "close_configurator_on_add_to_cart": false,
          "close_choices_when_selecting_choice": false,
          "close_choices_when_selecting_choice_desktop": false,
          "choice_description_no_tooltip": false,
          "image_loading_mode": "lazy",
          "show_choice_description": false,
          "show_layer_description": false,
          "show_active_choice_in_layer": 1,
          "show_active_choice_image_in_layer": false,
          "sku_mode": "individual",
          "show_form": false,
          "no_toggle": false,
          "open_first_layer": false,
          "auto_scroll": true,
          "angles": {
            "show_image": true,
            "show_name": true,
            "save_current":false
          },
          "add_tax": false,
          "tax_enabled": false,
          "tax_settings": {
            "prices_include_tax": true,
            "tax_display_mode": "incl"
          },
          "relative_price":false,
          "enable_selection_when_outofstock":true,
          "hide_outofstock_items":false
        }
      };
    }

    return { isConfigurable, configurations: productConfigurations };
  }
}

decorate(Page, {
  _accessories: computed,
  accessories: computed,
  spareParts: computed
})

function getPriceFromValue(price, isRegularPrice) {
  const { value } = price

  if (typeof value === 'object') {
    const values = Object.values(price.value)

    if(values[0] <= 0) {
      if(isRegularPrice) {
        return '1';
      }

      return null;
    }

    return values[0] || null
  }

  return value <= 0 ? (isRegularPrice ? '1' : null) : value;
}

export function getPriceUsd(product) {
  const price = {
    regular: null,
    sale: null
  };

  if (!product || !Array.isArray(product.meta_data)) {
    return price;
  }

  const xtrfy_price_usd = product.meta_data.find(
    entry => entry.key === '_woocs_regular_price_USD'
  )

  const xtrfy_price_sale_usd = product.meta_data.find(
    entry => entry.key === '_woocs_sale_price_USD'
  )


  if (xtrfy_price_usd && xtrfy_price_usd.value > 0) {
      price.regular = getPriceFromValue(xtrfy_price_usd);
  }

  if (xtrfy_price_sale_usd && xtrfy_price_sale_usd.value > 0) {
    price.sale = getPriceFromValue(xtrfy_price_sale_usd);
  }

  return price;
}
