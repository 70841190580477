import React from 'react'
import Hamburger from './icons/hamburger'
import Link from './Link'
import styles from './header.module.css'
import styles2 from './menu-mobile.module.css'
import Logo from './icons/logo'
import { CheckoutIcon } from './MenuDesktop'
import LogoutButton from "./LogoutButton";
import cn from 'classnames'
import Search from './Search';
import SearchIcon from './icons/search';
import useIsAdmin from "../hooks/use-is-admin";
import useIsReviewer from "../hooks/use-is-reviewer";

export default ({location, links}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const isAdmin = useIsAdmin();
  const isReviewer = useIsReviewer();

  links = links.reduce((links, link) => (
    [ ...links, link, ...link.children ]
  ), []);

  const onHamburgerClick = () => {
    setIsOpen(!isOpen);
  }

  const onSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  }

  const onLinkClick = () => {
    setIsOpen(false);
  }

  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  return (
    <header
      className={cn(styles.header, styles.mobile, {
        [styles.open]: isOpen
      })}
    >
      {isAdmin && (
        <div className={cn(styles.adminWarning, 'login-warning')}>
          <p>You are currently browsing as an admin</p>
        </div>
      )}
      {isReviewer && (
        <div className={cn(styles.reviewerWarning, 'login-warning')}>
          <p>You are currently browsing as a reviewer</p>
        </div>
      )}
      <div className={styles.buttons}>
        <Hamburger
          className={styles.hamburger}
          isOpen={isOpen}
          menuClicked={onHamburgerClick}
          width={23}
          height={12}
          strokeWidth={1}
          rotate={0}
          color={'black'}
          borderRadius={5}
          animationDuration="0.3"
        />
        {!isSearchOpen ? (
          <div className={styles.logoContainer}>
            <Link
              className={styles.link}
              to="/"
              onClick={onLinkClick}
            >
              <Logo className={styles.logo} />
            </Link>
          </div>
        ) : ''}
        {isSearchOpen ? (
          <div className={styles.logoContainer}>
            <Search onClear={onSearchClick} />
            <button />
          </div>
        ) : (
          <button className={styles.searchButton} onClick={onSearchClick}><SearchIcon height={'30px'} width={'30px'} /></button>
        )}
        <CheckoutIcon />
        {(isAdmin || isReviewer) && <LogoutButton isMobile={true} />}
      </div>
      <nav className={styles.nav}>
        {links.map(({ path, label, parent }, idx) =>
          <Link
            key={path}
            className={cn(styles.link, styles2.link, {
              [styles.active]: location.pathname === path,
              [styles2.sublink]: typeof parent === 'string'
            })}
            onClick={onLinkClick}
            style={{
              transform: `translateY(${isOpen
                ? 0
                : -500 * (idx + 1)}px)`,
              transitionDuration: `${200 + idx * 50}ms`,
              opacity: isOpen ? 1 : 0
            }}
            to={path}
          >
            {label}
          </Link>
        )}
      </nav>
    </header>
  )
}
