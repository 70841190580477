import React from 'react';
import Modal from "../Modal";
import Store from '../../stores';
import styles from './configuratorModal.module.css';
import ProductTile from "../Products/ProductTile";
import BuyButtonRow from "../BuyButtonRow";
import {getUrl} from "../Products/Grid";

export default ({ onClose, baseProduct }) => {
  const [products, setProducts] = React.useState([]);

  const {
    header,
    body,
    product_ids
  } = baseProduct.acf.configurator_modal || {};

  React.useEffect(() => {
    Store.getProductsById(product_ids).then(result => setProducts(result));
  }, [product_ids]);

  return product_ids && product_ids.length ? (
    <Modal onClose={onClose} className={styles.modal}>
      {products.length ? (
        <div className={styles.container}>
          <h1>{header}</h1>
          <div dangerouslySetInnerHTML={{__html: body}} />
          <div className={styles.productContainer}>
            {products.map(product => (
              <div key={product.id + '_conf_mdl'} className={styles.product}>
                <ProductTile url={getUrl(product)} noLink={true} {...product} />
                <BuyButtonRow
                  button={null}
                  product={product}
                  isExtraProduct={false}
                  extraClasses={styles.buyButtonRow}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Modal>
  ) : null;
}
