import React from 'react';
import Checkout from './index';
import Store from "../../stores";
import {Cart as CartContext} from "../../Cart";
import styles from "./cart.module.css";
import CrossSellTitle from "../CrossSellTitle";
import CrossSellProduct from "../CrossSellProduct";
import Wrap from "../Wrap";

async function getCrossSellProducts(cart) {
  const { items } = cart.state;

  // Find all IDs
  let crossSellIds = [];
  for(let i = 0; i < items.length; i++){
    const ids = items[i].item.cross_sell_ids;
    crossSellIds.push(...ids);
  }

  // Remove duplicates
  crossSellIds = [...new Set(crossSellIds)];

  // Remove the products that are already in the cart
  let indexesToBeRemoved = [];
  for(let i = 0; i < crossSellIds.length; i++){
    for(let j = 0; j < items.length; j++){
      if(crossSellIds[i] === items[j].item.id){
        indexesToBeRemoved.push(i);
      }
    }
  }

  for (let i = indexesToBeRemoved.length - 1; i >= 0; i--)
    crossSellIds.splice(indexesToBeRemoved[i],1);

  // Finally query the API for the products
  let crossSellProducts = [];
  crossSellProducts = crossSellProducts.concat(await Store.getProductsById(crossSellIds));

  // Remove any items that are on back order or that aren't in stock
  indexesToBeRemoved = [];
  for(let i = 0; i < crossSellProducts.length; i++) {
    if(crossSellProducts[i].stock_status !== "instock") {
      indexesToBeRemoved.push(i);
    }
  }

  for (let i = indexesToBeRemoved.length - 1; i >= 0; i--)
    crossSellProducts.splice(indexesToBeRemoved[i],1);


  return crossSellProducts;
}

export default ({ match, history, helmet}) => {
  const cart = React.useContext(CartContext);
  const { items, crossSellProducts, previousProducts } = cart.state;
  let itemsChanged = false;

  React.useEffect(() => {
    if(items.length > 0){
      if(typeof previousProducts === "undefined") {
        const itemIds = items.map(item => item.item.id);
        cart.dispatch({ type: 'previousProducts', previousProducts: itemIds });
      } else if(previousProducts.length === 0){
        const itemIds = items.map(item => item.item.id);
        cart.dispatch({ type: 'previousProducts', previousProducts: itemIds });
      }

      // Check if any item in cart has changed
      if(previousProducts) {
        if (previousProducts.length > 0) {
          let tempArray = items.map(item => item.item.id);
          if(tempArray.length !== previousProducts.length) {
            itemsChanged = true;
          } else {
            const tempSet = new Set(previousProducts);
            const difference = [...new Set(tempArray.filter(x => !tempSet.has(x)))];
            if(difference.length > 0) itemsChanged = true;
          }
        }
      }

      if(itemsChanged){
        const itemIds = items.map(item => item.item.id);
        cart.dispatch({ type: 'previousProducts', previousProducts: itemIds });
      }

      if (!crossSellProducts || itemsChanged) {
        getCrossSellProducts(cart).then(products => {
          cart.dispatch({type: 'crossSellProducts', crossSellProducts: products})
        });
      }

      if (typeof crossSellProducts !== "undefined") {
        if(crossSellProducts.length > 0){
          crossSellProducts.forEach(product => {
            product['didGetStockQuantity'] = product.stock_status === "instock";
          });
        }
      }
    }
  },[cart.state, itemsChanged])

  return (
    <div className={styles.checkoutWrapper}>
      <Checkout match={match} history={history} helmet={helmet} />
      <Wrap>
        <div className={styles.crossSellProductsContainer}>
          {(typeof crossSellProducts !== "undefined" ? (crossSellProducts.length > 0 ? <CrossSellTitle /> : '') : '')}
          <div className={styles.crossSellProductsInnerContainer}>
            {(typeof crossSellProducts !== "undefined" ? (crossSellProducts.length > 0 ? crossSellProducts.map(product => {
              return <CrossSellProduct key={product.id} cart={cart} product={product} isExtraProduct={true} />
            }) : '') : '')}
          </div>
        </div>
      </Wrap>
    </div>
  );
}
