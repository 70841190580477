import React from 'react';
import Store from "../../stores"
import ConfigurationButton from "../ConfigurationButton";
import {LoadingSpinner} from "../LoadingSpinner";
import {BuyButton} from "../Products/Product/Hero";

class LinkedConfigurableProduct extends React.Component {
  constructor(props) {
    super(props);

    const { productId, cart } = props;

    if(cart) {
      Store.getProductsById(productId).then(product => {
        cart.dispatch({type: 'linkedConfigurableProduct', linkedConfigurableProduct: product[0]});
      });
    }
  }

  render() {
    const { linkedConfigurableProduct } = this.props.cart ? this.props.cart.state : {};
    let { buttonText } = this.props;

    if(typeof linkedConfigurableProduct !== 'undefined') {
      buttonText = buttonText.replace(/%TITLE%/g, linkedConfigurableProduct.name);
      return <ConfigurationButton product={linkedConfigurableProduct} buttonText={buttonText} {...this.props} />
    }

    return (
      <BuyButton isLoading={true}>
        <LoadingSpinner />
      </BuyButton>
    );
  }
}

export default LinkedConfigurableProduct;
