import React from 'react';
import classNames from 'classnames/bind';
import styles from "./recommendedProduct.module.css";
import RelatedProduct from "../RelatedProduct";
import {getPriceUsd} from "../../stores/Page";
import Store from "../../stores";

class RecommendedProduct extends React.Component {
  constructor(props) {
    super(props);
    this.cn = classNames.bind(styles);

    const that = this;

    const recommendedProductsPromise = new Promise(resolve => {
      let recommendedProductsData = [];

      if(!that.props.useDiscount || !that.props.discount > 0) {
        resolve(recommendedProductsData);
        return;
      }

      let variationFetchPromise = new Promise(nestedResolve => {
        nestedResolve([]);
      });

      if(Array.isArray(that.props.product.variations) && that.props.product.variations.length) {
        const productVariations = ["variations", ...that.props.product.variations];
        variationFetchPromise = Store.getProductsById(productVariations);
      }

      variationFetchPromise.then(variationProducts => {
        if(!variationProducts.length) {
          variationProducts.push(that.props.product);
        }

        variationProducts.forEach(prod => {
          const usdPrices = getPriceUsd(prod);
          const discountedPriceEur = (prod.price * (100 - that.props.discount)) / 100;
          const discountedPriceUsd = (usdPrices.regular * (100 - that.props.discount)) / 100;

          recommendedProductsData.push({
            recommended_by: that.props.recommendedBy,
            price_eur: discountedPriceEur,
            price_usd: discountedPriceUsd,
            id: prod.id,
            use_discount: true
          });
        });

        resolve(recommendedProductsData);
      });
    });

    recommendedProductsPromise.then(recommendedProductsData => {
      that.props.cart.dispatch({type: 'recommendedProductsData', recommendedProductsData});

      // This is only used to display prices, just use the first one for simplicity
      that.props.product.recommendedProductsDataSmallest = recommendedProductsData.sort((a, b) => {
        if(a.price_eur > b.price_eur) return 1;
        if(a.price_eur < b.price_eur) return -1;
        return 0;
      })[0];

      that.props.product.recommendedProductsDataAll = recommendedProductsData;
    });

  }

  render(){
    return (
      <RelatedProduct product={this.props.product} cart={this.props.cart} extraStyles={this.cn(styles.recommendedProductOverride)} isExtraProduct={this.props.isExtraProduct}/>
    );
  }
}

export default RecommendedProduct;
