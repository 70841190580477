import React, { Component } from 'react'
import styles from './reviews.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'
import NukaCarousel from 'nuka-carousel'
import Link from '../../Link'

const cn = classNames.bind(styles)

function chunk(arr, chunkSize) {
  var R = []

  for (let i = 0; i < arr.length; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize))
  }

  return R
}

class Indexes extends Component {
  get indexes() {
    const { slideCount, slidesToScroll } = this.props
    const arr = []
    for (var i = 0; i < slideCount; i += slidesToScroll) {
      arr.push(i)
    }
    return arr
  }

  render() {
    const { currentSlide, goToSlide } = this.props

    return (
      <div className={cn('list')}>
        {this.indexes.map(idx => (
          <button
            key={idx}
            className={cn('button', { active: currentSlide === idx })}
            onClick={goToSlide.bind(null, idx)}
          >
            <div className={cn('bullet')} />
          </button>
        ))}
      </div>
    )
  }
}

const Carousel = ({ slides, innerRef }) => (
  <NukaCarousel
    ref={innerRef}
    renderBottomCenterControls={(props) => <Indexes {...props} />}
    renderCenterLeftControls={() => null}
    renderCenterRightControls={() => null}
  >
    {slides.map((slide, idx) => (
      <div key={idx} className={cn('slide')}>
        {slide}
      </div>
    ))}
  </NukaCarousel>
)

export default class Reviews extends Component {
  componentDidMount() {
    // Needed for carousel to set correct height
    this.timeout = window.setTimeout(() => {
      this.carousel && this.carousel.setDimensions(null)
    }, 200)
  }

  componentWillUnmount () {
    window.clearTimeout(this.timeout)
  }

  renderSlide = items => (
    <div className={cn('items')}>
      {items.map(
        ({ video, award_image, publisher, description, language, url }, idx) => (
          <Link to={url} className={cn('item')} key={idx}>
            {video ? (
              <video muted playsInline loop autoPlay poster={award_image}>
                <source src={video} type="video/mp4"/>
              </video>
            ) : (
              <img
                loading='lazy'
                className={cn('image')}
                src={award_image}
                alt={`${publisher} logo`}
              />
            )}

            <p className={cn('text')}>{description}</p>
            <h4 className={cn('publisher')}>
              <span>{publisher}</span>
              {language && (
                <span className={cn('language')}>&nbsp;— {language}</span>
              )}
            </h4>
          </Link>
        )
      )}
    </div>
  )

  render() {
    const { className, items } = this.props
    const slides = chunk(items, 6).map(items => this.renderSlide(items))

    return (
      <div className={cn('reviews', className)}>
        <Wrap className={cn('wrap')}>
          {slides.length === 1 ? (
            slides[0]
          ) : (
            <Carousel slides={slides} innerRef={ref => this.carousel = ref} />
          )}
        </Wrap>
      </div>
    )
  }
}
