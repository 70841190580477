import React from 'react';
import ProductTile from "../Products/ProductTile";
import classNames from 'classnames/bind';
import productTileStyles from '../Checkout/cart.module.css';
import { getUrl } from "../Products/Grid";
import styles from './relatedProduct.module.css'
import BuyButtonRow from "../BuyButtonRow";
import Store from '../../stores';
import {getPriceUsd} from "../../stores/Page";

class RelatedProduct extends React.Component {
  constructor(props) {
    super(props);
    this.cn1 = classNames.bind(productTileStyles);
    this.cn2 = classNames.bind(styles);

    this.hasVariations = false;
    if(Array.isArray(this.props.product.variations)) {
      if(this.props.product.variations.length > 0){
        const productVariations = ["variations", ...this.props.product.variations];
        Store.getProductsById(productVariations).then(variationProducts =>  {
          variationProducts.forEach(prod => {
            prod.variation_for = this.props.product.id;
            if(!prod.price_usd) prod.price_usd = getPriceUsd(prod);
          });
          this.props.cart.dispatch({ type: 'variationProducts', variationProducts })
        });
        this.hasVariations = true;
      }
    }
  }

  render(){
    const { variationProducts } = this.props.cart.state;
    const classes = this.props.extraStyles ? (this.cn2(styles.productTileContainer) + " " + this.props.extraStyles) : this.cn2(styles.productTileContainer);

    if(typeof variationProducts !== "undefined"){
      let currentVariationProducts = [];
      for(let i = 0; i < variationProducts.length; i++){
        if(variationProducts[i][0].variation_for === this.props.product.id){
          currentVariationProducts = variationProducts[i];
          break;
        }
      }

      this.props.product.price_usd = getPriceUsd(this.props.product);

      if(this.hasVariations){
        const attribute =
          Array.isArray(currentVariationProducts) && currentVariationProducts[0]
            ? currentVariationProducts[0].attributes[0]
            : null

        return (
          <div className={classes}>
            <ProductTile
              disabled={Boolean(this.props.isLoading)}
              className={this.cn1(productTileStyles.item)}
              url={getUrl(this.props.product)}
              {...this.props.product}
            />
            <div className={this.cn2(styles.buyButtonRowContainer)}>
              <BuyButtonRow
                button={null}
                attribute={attribute}
                variationProducts={currentVariationProducts}
                product={this.props.product}
                link={""}
                linkText={""}
                isExtraProduct={this.props.isExtraProduct}
              />
            </div>
          </div>
        );
      }
    }

    if(!this.hasVariations) {
      return (
        <div className={classes}>
          <ProductTile
            disabled={Boolean(this.props.isLoading)}
            className={this.cn1(productTileStyles.item)}
            url={getUrl(this.props.product)}
            {...this.props.product}
          />
          <div className={this.cn2(styles.buyButtonRowContainer)}>
            <BuyButtonRow
              button={null}
              attribute={null}
              variationProducts={null}
              product={this.props.product}
              link={""}
              linkText={""}
              isExtraProduct={this.props.isExtraProduct}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classes}>
        <ProductTile
          disabled={Boolean(this.props.isLoading)}
          className={this.cn1(productTileStyles.item)}
          url={getUrl(this.props.product)}
          {...this.props.product}
        />
        <div className={this.cn2(styles.buyButtonRowContainer)}>
          <BuyButtonRow
            isLoading={true}
            button={null}
            attribute={null}
            variationProducts={null}
            product={this.props.product}
            link={""}
            linkText={""}
            isExtraProduct={this.props.isExtraProduct}
          />
        </div>
      </div>
    );
  }
}

export default RelatedProduct;
