import React from 'react'
import classNames from 'classnames/bind'
import styles from './input.module.css'

export const cn = classNames.bind(styles)

const Input = ({
  className,
  value,
  onChange = () => {},
  required,
  type,
  label,
  multiline,
  rows = 2,
  innerRef,
  ...props
}) => {
  const inputElementProps = {
    className: cn('input-element', { filled: !!value }),
    onChange,
    required,
    type,
    value,
    ...props
  }

  if (multiline) {
    inputElementProps.rows = rows
  }

  return (
    <div className={cn(className, 'input')} ref={innerRef}>
      {React.createElement(multiline ? 'textarea' : 'input', inputElementProps)}
      <span className={cn('bar')} />
      {label && (
        <label className={cn('label')}>
          {label}
          {required ? <span className={cn('required')}> * </span> : null}
        </label>
      )}
    </div>
  )
}

export default Input
