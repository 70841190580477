import React from 'react';

export default function () {
  const html = `
    <div class="choice-group-label">
      <# if(data.thumbnail) { #>
        <i class="mkl-pc-thumbnail"><span><img src="{{data.thumbnail}}" alt="" /></span></i>
      <# } #>
      <# if ( data.description ) { #>
        <i class="info-icon">
          <img src="/noun_icon.svg" alt="info">
          <div class="description-container">
              <span class="description">{{{data.description}}}</span>
          </div>
        </i>
      <# } #>
      <span class="text choice-name">{{data.name}} <# if ( data.extra_price ) { #><span class="choice-price" data-price="{{data.extra_price}}" data-original-price="{{data.extra_price}}"><# if ( data.extra_price > 0 ) { #>+<# } #> {{{PC.utils.formatMoney(data.extra_price)}}}</span> <# } #></span>
     <# if ( data.manage_stock && 'none' !== data.manage_stock && 0 == data.stock ) { #><span class="out-of-stock">Out of stock</span><# } #>  </div>
  <ul class="choices-list--children" data-item-id="{{data._id}}"></ul>`

  return <script type="text/html" id="tmpl-mkl-pc-configurator-choice-group" dangerouslySetInnerHTML={{__html: html}}/>
}
