import React, {useEffect} from 'react'
import Wrap from '../Wrap';
import classNames from 'classnames/bind';
import styles from './search.module.css';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import Grid from '../Products/Grid';
import {toJS} from 'mobx';

const cn = classNames.bind(styles)

function useQuery() {
  return new URLSearchParams(useLocation().search).get('query');
}

function SearchResult({store}) {
  const query = useQuery();
  const items = toJS(store.items);
  const [searching, setSearching] = React.useState(false);

  useEffect(() => {
    store.getProductsSearch(query)
  }, [query]);

  useEffect(() => {
    setSearching(store.status === 'fetching');
  }, [store.status])

  if(searching) {
    return (
      <div className={cn('results')}>
        <h2>Searching for "{query}" ...</h2>

        <Grid items={[]} />
      </div>
    );
  }

  return (
    <div className={cn('results')}>
      <h2>Search Results for "{query}"</h2>

      <Grid items={items} />
    </div>
  );

}

export default observer(SearchResult);
