import React from 'react';

export default function () {
  const html = `
    <a href="#" class="change-angle--trigger"><span>Change angle</span></a>
    <ul/>
  `;

  return <script type="text/html" id="tmpl-mkl-pc-configurator-angles-list" dangerouslySetInnerHTML={{__html: html}}/>
}
