import React, { Component } from 'react'
import styles from './posts.module.css'
import classNames from 'classnames/bind'
import Wrap from '../Wrap'
import { Route } from 'react-router-dom'
import { observer } from 'mobx-react'
import Post from './Post'
import Excerpt from './Excerpt'
import Title from '../Title'

const cn = classNames.bind(styles)

class Posts extends Component {
  componentDidMount() {
    const { store } = this.props

    if (store.posts.length === 0) {
      store.getPosts()
    }
  }

  render() {
    const { helmet, store, match } = this.props
    const { posts = [], title = '' } = store || {}

    return match.isExact
      ? <div className={cn('posts')}>
          {helmet}
          <Wrap className={cn('wrap')}>
            <Title large>{title}</Title>
            <div className={cn('excerpts')}>
              {posts.map(post => <Excerpt key={post.id} {...post} />)}
            </div>
          </Wrap>
        </div>
      : <Route
          path={`${match.url}/:postSlug`}
          render={props => {
            const { postSlug } = props.match.params
            const post = this.props.store.posts.filter(
              post => post.slug === postSlug
            )
            return post.length > 0 && <Post {...props} {...post[0]} />
          }}
        />
  }
}

export default observer(Posts)
