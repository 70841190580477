import React from 'react';
import RelatedProduct from "../RelatedProduct";

class CrossSellProduct extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <RelatedProduct product={this.props.product} cart={this.props.cart} isExtraProduct={this.props.isExtraProduct} />
    );
  }
}

export default CrossSellProduct;
