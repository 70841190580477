import React from 'react'
import Main from "./Main"
import Toolbar from "./Toolbar"
import AngleItem from "./AngleItem";
import AnglesList from "./AnglesList";
import ChoiceGroup from "./ChoiceGroup";
import ConfiguratorChoiceItem from "./ConfiguratorChoiceItem";
import ConfiguratorChoices from "./ConfiguratorChoices";
import EmptyViewer from "./EmptyViewer";
import Footer from "./Footer";
import LayerItem from "./LayerItem";
import Viewer from "./Viewer";

export default function ConfiguratorTemplates() {
  return (<>
    <AngleItem/>
    <AnglesList/>
    <ChoiceGroup/>
    <ConfiguratorChoiceItem/>
    <ConfiguratorChoices/>
    <EmptyViewer/>
    <Footer />
    <LayerItem/>
    <Main/>
    <Toolbar/>
    <Viewer/>
  </>)
}
