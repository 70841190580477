import React, { Component } from 'react'
import styles from './gallery.module.css'
import classNames from 'classnames/bind'
import Measure from 'react-measure'

const cn = classNames.bind(styles)

const Image = ({ src }) =>
  src
    ? <img src={src} className={cn('image')} alt='' loading='lazy' />
    : <div className={cn('image-placeholder')} />

const GalleryItem = ({ video, image, url, size }) =>
  <div className={cn('item')} style={{ height: size, width: size }}>
    {url
      ? <a href={url} className={cn('link')} target="_blank" rel="noopener noreferrer">
        {video ? (
          <video className={cn(styles.galleryVideo)} muted playsInline loop autoPlay poster={image}>
            <source src={video} type="video/mp4"/>
          </video>
        ) : (
          <Image src={image} />
        )}
        </a>
      : (
        video ? (
            <video className={cn(styles.galleryVideo)} muted playsInline loop autoPlay poster={image}>
              <source src={video} type="video/mp4"/>
            </video>
          ) : (
            <Image src={image} />
          )
      )}
  </div>

export default class Gallery extends Component {
  render() {
    const { items, className } = this.props

    if (!items.length) {
      return null
    }

    const [bigItem, ...smallItems] = items

    return (
      <Measure whitelist={['width']}>
        {({ width }) => (
          <div className={cn('gallery', className)}>
            <div className={cn('big-item')}>
              <GalleryItem {...bigItem} size={width / 2} />
            </div>
            <div className={cn('small-items')}>
              {smallItems.map((item, idx) =>
                <GalleryItem {...item} key={idx} size={width / 4} />
              )}
            </div>
          </div>
        )}
      </Measure>
    )
  }
}
