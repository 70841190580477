import React, {useState} from 'react'
import styles from './cart.module.css';
import Button from '../Button';
import {woo} from '../../api';
import {withRouter} from 'react-router-dom';
import {LoadingSpinner} from "../LoadingSpinner";

export const CheckoutButton = withRouter(({items, currency, countryCode}) => {
  const [updating, setUpdating] = useState(false);
  const empty = items.map(item => item.count).reduce((acc, current) => acc + current) === 0;

  async function continueToCheckout() {
    if (empty) {
      return;
    }

    setUpdating(true);

    await woo.clearCart();

    for (const item of items) {
      if (item.count > 0) {
        await woo.addToCart(
          item.item.id,
          item.count,
          item.variation ? item.variation.id : null,
          typeof item.item.recommendedBy !== "undefined" ? item.item.recommendedBy : -1,
          item.configuration ? item.configuration : null
        );
      }
    }

    window.location.href = '/wp/checkout/?currency=' + currency + '&country=' + countryCode;
  }

  return (
    <Button
      disabled={updating || empty}
      onClick={continueToCheckout}
      className={styles.checkoutButton}>
      {updating ? <LoadingSpinner /> : 'Continue to checkout'}
    </Button>
  );
});
