import React from 'react';
import classNames from "classnames/bind";
import styles from './shareModal.module.css';
import Modal from "../Modal";
import Button from "../Button";
import { copyToClipboard } from '../../configurator/share';

const cn = classNames.bind(styles);

export default ({onClose, url}) => (
  <Modal zIndex={99999} onClose={onClose} className={cn(styles.modalContainer)}>
    <h2 className={cn(styles.title)}>Share your configuration on social media</h2>
    <div className={cn(styles.linkContainer)}>
      <p>Just copy the below link</p>
      <div className={cn(styles.copyLinkButtonContainer)}>
        <input type={"text"} className={cn(styles.linkInput) + ' share-url-input'} value={url} />
        <Button onClick={() => {
          copyToClipboard(url, jQuery('input.share-url-input'));
        }}>Copy</Button>
      </div>
    </div>
  </Modal>
);
