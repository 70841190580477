import React from 'react'
import { withRouter } from 'react-router-dom'
import Link from '../../components/Link'
import styles from './promo.module.css'
import classNames from 'classnames/bind'

const cn = classNames.bind(styles)

const BaseComponent = withRouter(({ link, children }) => {
  if (!link || link.length === 0) {
    return <div className={cn('promo')}>{children}</div>
  }

  const type = link[0].acf_fc_layout
  const url = link[0][type]
  return (
    <Link to={url} className={cn('promo', 'link')}>
      {children}
    </Link>
  )
})

export default ({ caption, text, link, image }) => (
  <BaseComponent link={link}>
    {image && (
      <img src={image} className={cn('image')} alt="" decoding="async" />
    )}
    <div className={cn('content')}>
      {caption && <h2 className={cn('caption')}>{caption}</h2>}
      {text && <p className={cn('text')}>{text}</p>}
    </div>
  </BaseComponent>
)
