import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Wrap from './components/Wrap'
import Button from './components/Button'
import styles from './cookies-policy.module.css'

const getAcceptCookies = () => (
  window && window.localStorage && window.localStorage.getItem('acceptCookies')
)

export default function CookiesPolicy() {
  const [acceptCookies, setAcceptCookies] = useState(false)

  useEffect(() => {
    const accept = getAcceptCookies() != null
    setAcceptCookies(accept)
  }, [])

  if (acceptCookies) {
    return null
  }

  return (
    <div className={styles.cookiesPolicy}>
      <Wrap className={styles.wrap}>
        <span className={styles.message}>
          We use cookies to improve your experience.
          By your continued use of this site,
          you accept our cookies and privacy policies.
        </span>
        <Link to='/cookies-policy' className={styles.readMore}>
          Read More »
        </Link>
        <Button className={styles.button} onClick={() => {
          setAcceptCookies(true)

          if (window && window.localStorage) {
            const nowString = new Date().toString()
            window.localStorage.setItem('acceptCookies', nowString)
          }
        }}>OK</Button>
      </Wrap>
    </div>
  )
}
