import React from 'react';
import classNames from 'classnames';
import styles from './recommendedProductsTitle.module.css';
import cn from "classnames";

class RecommendedProductsTitle extends React.Component {
  constructor(props) {
    super(props);
    this.cn = classNames.bind(styles);
  }

  render() {
    if(!this.props.title){
      if(this.props.useDiscount){
        return <h2 className={cn(styles.recommendedProductTitle)}>These items are currently {this.props.discount}% off!</h2>;
      } else {
        return <h2 className={cn(styles.recommendedProductTitle)}>These items may also interest you</h2>;
      }
    }

    const title = this.props.title.replace('%DISCOUNT%', this.props.discount + '%');
    return <h2 className={cn(styles.recommendedProductTitle)}>{title}</h2>;
  }
}

export default RecommendedProductsTitle;
