import React from 'react';
import classNames from "classnames/bind";
import styles from "./newsTile.module.css";

const cn = classNames.bind(styles);

export default function ({ news, link }) {
  const readMoreLink = cn(styles.readMoreLink);

  return (
    <div className={cn(styles.container)}>
      <a href={link}><h3 dangerouslySetInnerHTML={{__html: news.title}} /></a>
      <div className={cn(styles.newsImage)}>
        <a href={link}>
          <img src={news.image} />
        </a>
      </div>
      <div className={cn(styles.newsBody)} dangerouslySetInnerHTML={{__html: news.summary + ` <a href="${link}" class="${readMoreLink}">Read more&#8230;</a>`}} />
    </div>
  );
}
