import React, {Component} from 'react'
import styles from './trending-products.module.css'
import productTileStyles from '../product-tile.module.css';
import {getUrl} from '../Grid'
import cn from 'classnames'
import {observer} from 'mobx-react'
import ProductTile from "../ProductTile";
import NukaCarousel from "nuka-carousel";
import IconChevron from "../../Checkout/IconChevron";
import Measure from "react-measure";

class TrendingProducts extends Component {

    state = {itemCount: 1}

    onMeasure = ({width}) => {
      const breakpoints = [0, 400, 800, 1200, 1600];
      let itemCount = 0;

        breakpoints.forEach((bp) => {
            if (width > bp) {
                itemCount++
            }
        })

        this.setState({itemCount})
    }

    render() {
        const {store, items, title, className } = this.props
        const mainStore = store.store
        let index = 0;

        if (!Array.isArray(items) || items.length === 0) {
          return null
        }

        const products = items
            .map(id => mainStore.products.find(product => product.id === id))
            .filter(product => product != null)

        return (
            <div className={cn(styles.container, className)}>
                {title && <h3 className={styles.title}>{title}</h3>}
                <Measure onMeasure={this.onMeasure}>
                    <div>
                        {products.length > 1 && products.length >= this.state.itemCount ?
                            <NukaCarousel
                                autoplay={true}
                                wrapAround={true}
                                slidesToShow={this.state.itemCount}
                                initialSlideHeight={620}
                                heightMode={'max'}
                                cellAlign={'center'}
                                autoplayInterval={10000}
                                renderBottomCenterControls={() => null}
                                renderCenterLeftControls={({previousSlide}) => (
                                    <button onClick={previousSlide} className={styles.arrowButton}>
                                        <IconChevron className={styles.chevronLeft}/>
                                    </button>
                                )}
                                renderCenterRightControls={({nextSlide}) => (
                                    <button onClick={nextSlide} className={styles.arrowButton}>
                                        <IconChevron className={styles.chevronRight}/>
                                    </button>
                                )}
                                width={'100%'}>
                                {products.map((product) => (
                                    <ProductTile
                                        key={index++}
                                        className={cn(styles.item, productTileStyles.trendingProductsItem)}
                                        url={getUrl(product)}
                                        {...product}
                                    />
                                ))}
                                {products.map((product) => (
                                    <ProductTile
                                        key={index++}
                                        className={cn(styles.item, productTileStyles.trendingProductsItem)}
                                        url={getUrl(product)}
                                        {...product}
                                    />
                                ))}
                            </NukaCarousel>
                            : <div className={styles.grid}>{products.map((product, idx) => (
                                <ProductTile
                                    key={idx}
                                    className={cn(styles.item, productTileStyles.trendingProductsItem)}
                                    url={getUrl(product)}
                                    {...product}
                                />
                            ))}</div>
                        }
                    </div>
                </Measure>
            </div>
        )
    }
}

export default observer(TrendingProducts);
