import React, {useState} from 'react';
import styles from './shipping-location.module.css'
import { Cart } from '../../Cart'
import Flag from 'react-country-flag'
import Modal from "../Modal";
import CountrySelector from "../CountrySelector";
import { getCountryCurrency } from '../Checkout/utils/countries'

const ShippingLocation = () => {
  const cart = React.useContext(Cart)

  const [showModal, setModal] = useState(false);
  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)

  if (!cart.country) {
    return null
  }

  return (
    <>
      {showModal && (
        <Modal onClose={closeModal}>
          <CountrySelector cart={cart} />
        </Modal>
      )}
      <div className={styles.shippingLocation} onClick={openModal}>
        <span id="current-country" data-currency-type={getCountryCurrency(cart.country.code)}>
          <Flag svg code={cart.country.code} styleProps={{ height: 13 }} />
          {cart.country.name}
        </span>
      </div>
    </>
  )
}

export default ShippingLocation
