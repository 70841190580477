import React from 'react'
import { woo } from '../api'
import Wrap from './Wrap'
import styles from './order.module.css'
import Currency from './Currency'

const Order = ({ helmet, match, location }) => {
  const orderId = match.params[0]
  const _order = location.state ? location.state.order : null
  const [order, setOrder] = React.useState(_order)

  React.useEffect(() => {
    if (order) {
      return
    }

    let isMounted = true

    woo.get(`orders/${orderId}`).then(data => {
      if (isMounted) {
        setOrder(data)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  if (!order) {
    return null
  }

  return (
    <div className={styles.order}>
      {helmet}
      <Wrap className={styles.wrap}>
        {_order ? (
          <h1>Thanks, we've received your order!</h1>
        ) : (
          <h1>Order #{order.id}</h1>
        )}
        <div className={styles.details}>
          <div className={styles.item}>
            <h3>Order Number:</h3>
            <p>{order.id}</p>
          </div>
          <div className={styles.item}>
            <h3>Date:</h3>
            <p>{new Date(order.date_created).toLocaleString()}</p>
          </div>
          <div className={styles.item}>
            <h3>Total:</h3>
            <p><Currency currency={order.currency}>{order.total}</Currency></p>
          </div>
          <div className={styles.item}>
            <h3>Payment Method:</h3>
            <p>{order.payment_method_title}</p>
          </div>
        </div>
      </Wrap>
    </div>
  )
}

export default Order
