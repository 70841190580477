import React from 'react';
import CartIcon from '../components/icons/cart';
import ReactDOMServer from 'react-dom/server'

export default function () {
  const cartIcon = ReactDOMServer.renderToString(<CartIcon title={'Open'} />);

  const html = `
    <div class="configuration-summary-container mobile">
       <div id="configuration-summary-controls">
         <div id="summary-open">
            <div class="cart-count"></div>
            ${cartIcon}
         </div>
         <div id="summary-close">
            <span>X</span>
        </div>
       </div>
       <div class="configuration-summary mobile">
         <h2>SUMMARY</h2>
         <div class="xtrfy-items mobile"></div>
         <div class="xtrfy-total-summary xtrfy-total mobile">Total: <span class="xtrfy-total-cost mobile"></span></div>
       </div>
    </div>
    <div id="configurator-welcome-msg-container">
        <div id="configurator-welcome-msg"></div>
        <div id="configurator-special-choices"></div>
    </div>
    <div id="custom-angles-list"></div>
    <div class="mkl_pc_layers"></div>
    <div id="configurator-warning-msg"></div>
    <div id="description-overlay"></div>
  `;

  return <script type="text/html" id="tmpl-mkl-pc-configurator-viewer" dangerouslySetInnerHTML={{__html: html}}/>
}
