import React from 'react';
import styles from './colourSwatches.module.css';
import ColourSwatch from "../ColourSwatch";

export default ({productIds, onSwatchChange}) => {
  const [productInfo, setProductInfo] = React.useState([]);

  React.useEffect(() => {
    fetch('/api/wc/colour-swatches?ids=' + productIds.toString()).then(response => {
      return response.json();
    }, err => {
      setProductInfo([]);
      console.error(err);
    }).then(data => setProductInfo(data));
  }, []);

  return productInfo && productInfo.length ? (
    <div className={styles.colourSwatchesContainer}>
      {productInfo.map(item => (
        <ColourSwatch key={item.id} data={item} onSwatchClicked={onSwatchChange} />
      ))}
    </div>
  ) : null;
}
