import React, { Component } from 'react'
import classNames from 'classnames/bind'
import styles from './footer.module.css'
import Link from '../Link'
import { observer } from 'mobx-react'
import Wrap from '../Wrap'
import ShippingLocation from '../Shipping/ShippingLocation';
import Newsletter from '../Newsletter';

const cn = classNames.bind(styles)

const Section = ({ label, list, children }) => (
  <section className={cn('section')}>
    <h3 className={cn('label')}>{label}</h3>
    {list ? (
      <ul>
        {list.map(item => (
          <li key={item.id}>
            <Link to={item.url}>{item.title.replace(/&amp;/, '&')}</Link>
          </li>
        ))}
      </ul>
    ) : (
      children
    )}
  </section>
)

class Footer extends Component {
  componentDidMount() {
    if (!this.props.store.didFetch) {
      this.props.store.getMenus()
    }
  }

  render() {
    const { store = {} } = this.props
    const { menus } = store

    // Remove duplicates
    const uniqueMenus = menus.reduce((carry, item) => {
      let isDuplicate = false;

      for (const menu of carry) {
        if (menu.name === item.name) {
          isDuplicate = true;
          break;
        }
      }

      if (!isDuplicate) carry.push(item);
      return carry;
    }, []);

    return (
      <footer className={cn('footer')}>
        <div className={cn('footerRow')}>
          <ShippingLocation />
        </div>
        <div className={styles.footerWrapper}>
        {uniqueMenus.map((menu, idx) => menu && (
            <Section key={idx} label={menu.name} list={menu.items} />
          ))}
          <Newsletter isFooter={true} />
        </div>
      </footer>
    )
  }
}

export default observer(Footer)
