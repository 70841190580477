import { useState, useEffect } from 'react'
import { useStore } from '../stores'

export const useIsMobile = () => {
  const store = useStore()
  const [isMobile, setIsMobile] = useState(
    store.device === 'mobile' || store.device === 'tablet'
  )

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 980px)')
    const handler = (evt) => {
      setIsMobile(evt.matches)
    }

    if(mql.addListener) {
      mql.addListener(handler);
    } else {
      mql.addEventListener('change', handler);
    }

    return () => {
      if(mql.removeListener) {
        mql.removeListener(handler);
      } else {
        mql.removeEventListener('change', handler);
      }
    }
  }, [])

  return isMobile
}
