import React, {useEffect, useState} from 'react'
import styles from './search.module.css'
import SearchIcon from '../icons/search'
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

function useQuery() {
  return new URLSearchParams(useLocation().search).get('query');
}

const Search = ({onClear}) => {
  const query = useQuery();
  const [search, setSearch] = useState(query ? query : "");
  const history = useHistory();

  function onClearClick() {
    setSearch('');
    onClear();
  }

  useEffect(() => {
    // Controls if you're on the search page or not
    if (search.length > 0 && history.location.pathname !== '/search') {
      history.push('/search');
    } else if (search.length <= 0 && history.location.pathname === '/search') {
      history.go(-1);
    }

    // Updates your search params
    if (search.length > 0) {
      history.replace(`search?query=${search}`);
    }
  }, [search]);

  return (
    <div className={cn('search-input-wrap')}>
      <SearchIcon className={cn('search-input-icon')} height={'24px'} width={'24px'} />
      <input className={cn('search-input')}
             onChange={(change) => setSearch(change.target.value)}
             value={search}
             placeholder={"Search"}/>
      <button className={cn('close-button')} onClick={() => { onClearClick(); }}>&times;</button>
    </div>
  );
};

export default Search
