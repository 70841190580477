import React, { Component } from 'react'
import styles from './tech-specs.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'

const cn = classNames.bind(styles)

const parseValue = value => {
  if (!value) {
    return value
  }

  const match = value.match(/\[(.*?)\]\((.+?)\)/)

  if (!match || match.length < 3)  {
    return value
  }

  const fileExtension = match[2].substr(-3);
  if(fileExtension === "pdf"){
    return (
      <div className={cn(styles.linkContainer)}>
        <a className="_df_custom" href="#" source={match[2]}>
          Read PDF
        </a>
        <a href={match[2]}>Download PDF</a>
      </div>
    );
  }
  return <a href={match[2]}>{match[1]}</a>
}

export default class TechSpecs extends Component {
  render() {
    const { items, title, image } = this.props
    const hasImage = typeof image === 'string'

    return (
      <div className={cn('tech-specs', { withImage: hasImage })}>
        <Wrap className={cn('wrap')}>
          {title && <h3 className={cn('title')}>{title}</h3>}
          <div className={cn('table')}>
            {items.map(({ label, value = '' }, idx) =>
              <div className={cn('item')} key={idx}>
                <h4 id={label === " DOWNLOADS:" ? "downloads" : ''} className={cn('label')}>
                  {label ? label.replace(/:?$/, ':') : <span>&nbsp;</span>}
                </h4>
                <p className={cn('value')}>{parseValue(value)}</p>
              </div>
            )}
          </div>
          {hasImage &&
          <div className={cn('image-container')}>
            <img className={cn('image')} src={image} alt='' />
          </div>}
        </Wrap>
      </div>
    )
  }
}
