/* eslint no-new-func: 0 */
/* eslint no-useless-escape: 0 */

export const isProduction = process.env.NODE_ENV === 'production'

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function parsePrices ({
  price: priceEur,
  regular_price: regularPriceEur,
  on_sale: isOnSale,
  price_usd: regularPriceUsd,
  sale_price_usd: salePriceUsd,
  product_variations: productVariations
}) {
  const isVariationProduct = !!(
    Array.isArray(productVariations) && productVariations.length && productVariations[0]
      ? productVariations[0].attributes[0]
      : null
  );

  if (isVariationProduct) {
    const prices = productVariations.map(function (p) {
      return {
        eur: {
          salePrice: isOnSale ? Number(p.price) : null,
          price: isOnSale ? null : Number(p.price),
          regularPrice: Number(p.regular_price)
        },
        usd: {
          salePrice: isOnSale ? Number(p.sale_price) : null,
          price: isOnSale ? null : Number(p.price_usd),
          regularPrice: Number(p.price_usd)
        }
      }
    });

    const minPrice = _getLowestPriceFromVariations(prices);

    return {
      ...minPrice,
      isOnSale
    }
  }

  if (!regularPriceUsd) {
    regularPriceUsd = priceEur;
  }

  // <Currency eur={price} usd={sale_price_usd || price_usd} onSale={on_sale} regularPriceUsd={price_usd} regularPriceEur={regular_price} />

  return {
    eur: {
      price: priceEur,
      regularPrice: regularPriceEur
    },
    usd: {
      price: salePriceUsd || regularPriceUsd,
      regularPrice: regularPriceUsd
    },
    isOnSale
  }
}

function _getLowestPriceFromVariations(prices) {
  // Initialize variables to store the lowest prices and their corresponding regular prices
  let lowestEurPrice = null;
  let lowestEurRegularPrice = null;

  let lowestUsdPrice = null;
  let lowestUsdRegularPrice = null;

  prices.forEach(p => {
    // Determine the current EUR price (prefer salePrice over price if salePrice is not null)
    const currentEurPrice = p.eur.salePrice !== null ? p.eur.salePrice : p.eur.price;
    if (currentEurPrice !== null) {
      if (lowestEurPrice === null || currentEurPrice < lowestEurPrice) {
        lowestEurPrice = currentEurPrice;
        lowestEurRegularPrice = p.eur.regularPrice;
      }
    }

    // Determine the current USD price
    let currentUsdPrice = p.usd.salePrice !== null ? p.usd.salePrice : p.usd.price;
    if (currentUsdPrice !== null) {
      if (lowestUsdPrice === null || currentUsdPrice < lowestUsdPrice) {
        lowestUsdPrice = currentUsdPrice;
        lowestUsdRegularPrice = p.usd.regularPrice;
      }
    }
  });

  return {
    eur: {
      price: lowestEurPrice,
      regularPrice: lowestEurRegularPrice
    },
    usd: {
      price: lowestUsdPrice,
      regularPrice: lowestUsdRegularPrice
    }
  };
}
