import React from 'react';
import classNames from "classnames/bind";
import styles from './categoryFilter.module.css';
import ArrowIcon from '../icons/arrow';
import Store from '../../stores/index.js';

const cn = classNames.bind(styles);

function Component({filters, onFilterUpdate}) {
  const [selectedFilters, setSelectedFilters] = React.useState(new Map());
  const [colourRows, setColourRows] = React.useState(0);
  let structuredFilters = {};
  let hasOtherFilters = false;

  for(let i = 0; i < filters.length; i++) {
    if(filters[i].id !== "x_stock_status" && filters[i].id !== "x_pre_order" && filters[i].id !== "x_in_stock") {
      hasOtherFilters = true;
      break;
    }
  }

  if(!hasOtherFilters) return '';

  for(let i = 0; i < filters.length; i++) {
      const filter = filters[i];

      const isParent = filter.parent === 0;
      const parentId = isParent ? filter.id : filter.parent;

      if(typeof structuredFilters['parent_' + parentId] === "undefined") {
        structuredFilters['parent_' + parentId] = {};
      }

      if(typeof structuredFilters['parent_' + parentId].children === "undefined") {
        structuredFilters['parent_' + parentId].children = [];
      }

      if(isParent) {
        let order = filter.order;

        if(order !== 'last') {
          order = Number.parseInt(order);
        }

        structuredFilters['parent_' + parentId].title = filter.name;
        structuredFilters['parent_' + parentId].depends_on = filter.depends_on;
        structuredFilters['parent_' + parentId].order = order;
        structuredFilters['parent_' + parentId].id = filter.id;
      } else {
        structuredFilters['parent_' + filter.parent].children.push(filter);
      }
  }

  function filterUpdate(e) {
    const categoryId = e.target.dataset.categoryId;
    const filterValue = e.target.value;

    let _selectedFilters = selectedFilters.get(categoryId);

    if(typeof _selectedFilters === "undefined") {
      _selectedFilters = new Map([[filterValue, true]]);
      selectedFilters.set(categoryId, _selectedFilters);
    } else if(e.target.checked) {
      _selectedFilters.set(filterValue, true);
      selectedFilters.set(categoryId, _selectedFilters);
    } else {
      _selectedFilters.delete(filterValue);

      if(_selectedFilters.size === 0) {
        selectedFilters.delete(categoryId);
      } else {
        selectedFilters.set(categoryId, _selectedFilters);
      }
    }

    setSelectedFilters(selectedFilters);

    if(typeof onFilterUpdate === 'function') {
      onFilterUpdate(selectedFilters);
    }
  }

  function onArrowClicked(isOpen, id) {
    if(typeof jQuery === "undefined") return;

    const $children = jQuery('#' + id);

    $children.find('> div').toggle(isOpen);
    $children.toggleClass(styles.categoryIsClosed)
  }

  return (
    <div className={styles.container}>
      <div className={styles.filterHeaderContainer}>
        <h2 className={styles.filterHeader}>Filters</h2>
        <ArrowIcon classNames={styles.arrowIcon} additionalReverseClasses={styles.headerArrowIconReversed} onArrowClicked={isReversed => onArrowClicked(isReversed, 'filter-component')} />
      </div>
      <div id={'filter-component'}>
        {structuredFilters ? (
          (function() {
            const keys = Object.keys(structuredFilters);
            let components = [];

            keys.sort((a, b) => {
              const orderA = structuredFilters[a].order;
              const orderB = structuredFilters[b].order;

              if(orderA === 'last' || orderA > orderB) return 1;
              if(orderB === 'last' || orderA < orderB) return -1;
              return 0;
            });

            if(!colourRows) {
              let colours = 0;
              for(const key of keys) {
                let item = structuredFilters[key];
                item.children.forEach(child => { if(!!child.isColour) colours++ });
              }

              // Each row can hold 8 colours
              let wholeColoursRows = Number.parseInt((colours / 8).toString());
              let _colourRows = colours < 8 ? 1 : (colours / 8) > wholeColoursRows ? wholeColoursRows + 1 : wholeColoursRows;
              setColourRows(_colourRows);
            }

            for(const key of keys) {
              let item = structuredFilters[key];

              // Sort the children
              item.children.sort((a, b) => {
                if(a.order > b.order) return 1;
                if(a.order < b.order) return -1;
                return 0;
              });

              let selectedDependency = false;

              // Make sure to only display a filter category if the filter it depends
              // on has been selected.
              if(item.depends_on) {
                CategoryLoop: for(const [_, selectedFiltersInCategory] of selectedFilters) {
                  for(const [selectedCategoryFilter, __] of selectedFiltersInCategory) {
                    if(selectedCategoryFilter === item.depends_on) {
                      selectedDependency = true;
                      break CategoryLoop;
                    }
                  }
                }

                if(!selectedDependency) continue;
              }


              let isColourRow = item.children.filter(child => !!child.isColour).length > 0;

              let extraContainerStyles = {};
              if(isColourRow) {
                extraContainerStyles.height = (35 * colourRows) + 'px';
              }

              components.push(
                <div key={item.id}>
                  <div className={styles.titleContainer}>
                    <h3 className={styles.title}>{item.title}</h3>
                    <ArrowIcon classNames={styles.arrowIcon} onArrowClicked={isReversed => onArrowClicked(isReversed, item.id)} />
                  </div>
                  <div id={item.id} className={styles.childContainer} style={extraContainerStyles}>
                    {item.children.map((child, idx) => {
                        const isColour = !!child.isColour;
                        const multipleColours = child.colours.colour_amount === 'both';
                        const firstColour = child.colours.first_colour || '';
                        const secondColour = child.colours.second_colour || '';

                        let inlineStyles = {
                          backgroundColor: firstColour
                        };

                        if(multipleColours) {
                          inlineStyles.backgroundImage = 'linear-gradient(90deg,' + firstColour + ' 50%,' + secondColour + ' 50%)';
                        }

                        return (
                          <div key={child.id}
                               className={cn(styles.child, {[styles.lastOfType]: idx === (item.children.length - 1)},
                                 {[styles.isColour]: isColour}, {[styles.whiteColour]: firstColour.toLowerCase() === '#ffffff' || secondColour.toLowerCase() === '#ffffff'},
                                 {[styles.lastOfColouredType]: isColour && idx === (item.children.length - 1)})}
                               aria-label={isColour ? child.name : ''}>
                            <input id={child.slug} data-category-id={item.id} type={"checkbox"} value={child.slug} onClick={filterUpdate} />
                            <label style={isColour ? inlineStyles : {}} htmlFor={child.slug}>
                              {!isColour ? child.name : ''}
                            </label>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            }

            return components;
          })()
        ) : ''}
      </div>
    </div>
  );
}

async function addStockStatusFilterToProduct(filters, productId) {
  // Make sure to not add the stock status filters multiple times
  for(let i = 0; i < filters.length; i++) {
    if(filters[i].id === "x_stock_status") {
      return { filters, productId };
    }
  }

  const { stock_status } = await Store.getProductStockStatus(productId);

  filters.unshift({
    parent: 0,
    name: 'Availability',
    slug: 'stock-status',
    isColour: 0,
    id: 'x_stock_status',
    description: '',
    order: 'last',
    colours: {
      colour_amount: 'first',
      first_colour: '',
      second_colour: ''
    }
  });

  if(stock_status === "instock") {
    filters.unshift({
      parent: 'x_stock_status',
      name: 'In stock',
      slug: 'in-stock',
      isColour: 0,
      id: 'x_in_stock',
      description: '',
      order: 1,
      colours: {
        colour_amount: 'first',
        first_colour: '',
        second_colour: ''
      }
    });
  } else if (stock_status === "onbackorder") {
    filters.unshift({
      parent: 'x_stock_status',
      name: 'Pre-Order',
      slug: 'pre-order',
      isColour: 0,
      id: 'x_pre_order',
      description: '',
      order: 2,
      colours: {
        colour_amount: 'first',
        first_colour: '',
        second_colour: ''
      }
    });
  }

  return { filters, productId };
}

export default { Component, addStockStatusFilterToProduct };
