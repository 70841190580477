import React from 'react';
import styles from './styles.module.css';
import classNames from "classnames/bind";

const cn = classNames.bind(styles);

const icon = (
  <svg xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 100 100'>
    <path
      style={{
        WebkitTextIndent: "0",
        textIndent: "0",
        WebkitTextTransform: "none",
        textTransform: "none",
        blockProgression: "tb",
      }}
      d='M49.875 986.37a4 4 0 00-2.656 1.094l-25 24a4.011 4.011 0 005.562 5.781L50 995.901l22.219 21.344a4.011 4.011 0 005.562-5.781l-25-24a4 4 0 00-2.906-1.094z'
      color='#000'
      enableBackground='accumulate'
      overflow='visible'
      transform='translate(0 -952.362)'
    />
  </svg>
);

export default ({ classNames, additionalReverseClasses, onArrowClicked }) => {
  const [isReversed, setIsReversed] = React.useState(false);

  function clickHandler() {
    setIsReversed(!isReversed);

    if(typeof onArrowClicked === "function") {
      onArrowClicked(isReversed);
    }
  }
  /* additionalReverseClasses */
  let classes = cn(styles.arrowIcon, {[styles.arrowIconReversed]: isReversed}, classNames);

  if(additionalReverseClasses && isReversed) classes += ' ' + additionalReverseClasses;

  return (
    <div onClick={clickHandler} className={classes}>
      {icon}
    </div>
  )
};
