import { Component } from 'react'
import ReactDOM from 'react-dom'

export default class Portal extends Component {
  state = { didMount: false }

  componentDidMount () {
    this.setState({
      didMount: true,
      height: document.documentElement.scrollHeight
    })
  }

  componentWillUnmount () {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode)
    }

    this.defaultNode = null
  }

  render () {
    if (!this.state.didMount && this.props.waitForMount !== false) {
      return null
    }

    const { node, children, className, style, zIndex } = this.props

    if (!node && !this.defaultNode) {
      this.defaultNode = document.createElement('div')
      this.defaultNode.style = style
      this.defaultNode.style.height = `${this.state.height}px`
      this.defaultNode.className = className
      document.body.appendChild(this.defaultNode)
    }

    if(zIndex && this.defaultNode) {
      this.defaultNode.style.zIndex = zIndex;
    }

    if(zIndex && node) {
      node.style.zIndex = zIndex;
    }

    return ReactDOM.createPortal(
      children,
      node || this.defaultNode
    )
  }
}
