import React from 'react';
import styles from './anchorMenu.module.css';
import useIsAdmin from "../../hooks/use-is-admin";
import useIsReviewer from "../../hooks/use-is-reviewer";

export default () => {
  const [$domAnchors, setDomAnchors] = React.useState(null);
  const [links, setLinks] = React.useState([]);
  const [topHeight, setTopHeight] = React.useState('130px');

  const isAdmin = useIsAdmin();
  const isReviewer = useIsReviewer();

  const isLoggedIn = isAdmin || isReviewer;

  React.useEffect(() => {
    if(typeof jQuery === "undefined") return;

    jQuery('document').ready($ => {
      setDomAnchors($('i.anchor-link'));
    });
  }, []);

  React.useEffect(() => {
    if($domAnchors === null || !$domAnchors.length) return;

    let anchorLinks = [];
    for(let i = 0; i < $domAnchors.length; i++) {
      anchorLinks.push({name: $domAnchors[i].dataset.name, slug: $domAnchors[i].id});
    }

    setLinks(anchorLinks);
  }, [$domAnchors])

  React.useEffect(() => {
    setTopHeight(isLoggedIn ? '179px' : '130px');
  }, [isLoggedIn]);

  function scrollToAnchor(slug) {
    const anchorMenu = document.getElementById('anchor-menu');
    const el = document.getElementById(slug);

    const rect = el.getBoundingClientRect();
    const top = window.scrollY || document.documentElement.scrollTop;
    const anchorMenuScrollBoundary = anchorMenu.getBoundingClientRect().y - 30;

    window.scroll({
      left: 0,
      top: rect.y - 80 + top - anchorMenuScrollBoundary,
      behavior: 'smooth'
    });
  }

  return links.length ? (
    <div id={'anchor-menu'} className={styles.anchorMenuContainer} style={{ top: topHeight }}>
      <ul>
        {links.map(link => (
          <li onClick={() => scrollToAnchor(link.slug)}>{link.name}</li>
        ))}
      </ul>
    </div>
  ) : null;
}
