import React from 'react';
import routes from '../../routes';
import ProductStore from '../../stores';
import { Cart } from "../../Cart";
import ConfiguratorHooks from "../../configurator/ConfiguratorHooks";
import ConfiguratorTemplates from "../../configurator/ConfiguratorTemplates";
import { woo } from "../../api";
import styles from "./configuratorRoute.module.css";
import {Page} from "../../stores/Page";
import fetchPrice from "../../configurator/fetchPrice";
import ConfiguratorModal from "../ConfiguratorModal";
import Share from "../../configurator/share";
import ShareModal from "../ShareModal";

export default ({ store }) => {
  const route = routes.find(route => route.slug === "custom-keyboard-builder");
  const [product, setProduct] = React.useState({ acf: {} });
  const [shouldOpenModal, setShouldOpenModal] = React.useState(false);
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState('');

  React.useEffect(() => {
    ProductStore.getProductsById(route.productId).then(prd => {
      setProduct(prd[0] || {});

      if(prd[0]) {
        const openModal = prd[0].acf.configurator_modal && prd[0].acf.configurator_modal.active;
        setShouldOpenModal(openModal);
      }
    });
 }, []);

  const { didGetStockQuantity } = product;
  const id = route.productId;
  const [isDead, setIsDead] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasAddedToCart, setHasAddedToCart] = React.useState(false);
  const [isConfigurable, setIsConfigurable] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState({});
  const [configuratorPrices, setConfiguratorPrices] = React.useState({hasBeenSet: false, prices: {}, skus: [], ids: []});
  const cart = React.useContext(Cart);

  React.useEffect(() => {
    woo.get(`configurations?id=${route.productId}`).then(productConfigurations => {
      setIsConfigurable(productConfigurations && productConfigurations.isConfigurable)
    });
  }, [])

  React.useEffect(() => {
    if(product && product.acf) {
      const configId = (product.acf && product.acf.configuratorLink && product.acf.showConfiguratorLink) ? product.acf.configuratorLink : id;

      const addConfiguratorHooks = isConfigurable || (product.acf.configuratorLink && product.acf.showConfiguratorLink);
      ConfiguratorHooks.initHooks(addConfiguratorHooks, selectedProducts, setSelectedProducts, configuratorPrices, setConfiguratorPrices, configId, cart, id, product);
    }
  }, [product, isConfigurable]);

  React.useEffect(() => {
    if(typeof jQuery === 'undefined') return;

    ConfiguratorHooks.updateCurrency()
    ConfiguratorHooks.pricesHaveBeenSet();

  }, [configuratorPrices.hasBeenSet, cart.currency])

  React.useEffect(() => {
    if (id && store && store.getStockQuantity && !didGetStockQuantity) {
      store.getStockQuantity(id)
    }
  }, [id, didGetStockQuantity])

  React.useEffect(() => {
    ConfiguratorHooks.setData(isConfigurable, id, store, true);
    ConfiguratorHooks.maybeLoadSharedData();

    if(isConfigurable) {
      (function myLoop(i) {
        setTimeout(function() {

          if(PC.productData) {
            setIsLoading(false);
            PC.fe.open(route.productId, route.productId, jQuery('#product-configurator-container'));
            return;
          }

          // Decrement i and call myLoop again if i > 0
          if (--i) {
            myLoop(i);
          } else {
            setIsDead(true);
          }

        }, 20)
      })(500); // Try for 5 seconds
    }
  }, [product.acf.configuratorLink, id, isConfigurable])

  const addConfigurationToCart = (evt) => {
    if (evt.target && evt.target.value) {
      try {
        let configuration = JSON.parse(evt.target.value);
        Page.getConfiguration(route.productId).then(({isConfigurable, configurations}) => {
          product.isConfigurable = isConfigurable;
          product.configurations = configurations;
          fetchPrice(configuration, product.configurations);

          // Check for potential backorder items
          for(let i = 0; i < configuration.length; i++) {
            if(configuration[i].is_choice) {
              const layerId = configuration[i].layer_id;
              const choiceId = configuration[i].choice_id;

              const choice = PC.fe.getLayerContent(layerId).get(choiceId);
              if(!choice.get('available')) {
                return;
              } else if((choice.get('wc_backorder') === 'yes' || choice.get('backorder')) && choice.get('manage_stock') === 'simple') {
                configuration[i].has_backorder = true;
              }
            }
          }

          cart.dispatch({ type: 'add', item: product, configuration: configuration })
          setHasAddedToCart(true);
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleShare = e => {
    if(typeof jQuery !== "undefined") {
      const data = e.target.value;
      if(!data) return;

      Share.getShareUrl(data, function(res) {
        if(res.body && res.body.url) {
          setShareModalOpen(true);
          setShareUrl(res.body.url);
        }
      });
    }
  }


  if(hasAddedToCart) {
    return shouldOpenModal ? (
      <ConfiguratorModal onClose={() => setShouldOpenModal(false)} baseProduct={product} />
    ) : (
      <div className={styles.center}>
        <h1>Your item has been added to the cart!</h1>
      </div>
    )
  }

  if(!isLoading) {
    return (
      <div>
        <ConfiguratorTemplates />

        {shareModalOpen && (
          <ShareModal onClose={() => setShareModalOpen(false)} url={shareUrl} />
        )}

        <textarea style={{display: 'none'}} cols="0" rows="0" onChange={addConfigurationToCart} id="product-configuration"/>
        <textarea style={{display: 'none'}} cols="0" rows="0" onChange={handleShare} id="product-configuration-share"/>
      </div>
    );
  }

  if(!isDead) {
    return (
      <div className={styles.center}>
        <h1>Loading configurator...</h1>
      </div>
    );
  }

  return (
    <div className={styles.center}>
      <h1>Could not load configurator</h1>
    </div>
  );
}
