import React from "react";

export default ({ title }) => (
  <svg height='24px' viewBox="0 0 63 64" opacity={0.8}>
    <title>{ title ? title : 'Cart'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h64v64H0z" />
      <path
        d="M.157 3.957a3 3 0 1 1 5.686-1.914L19.931 43.89a3 3 0 0 1-5.687 1.914L.157 3.957zM29.587 56.5a3 3 0 1 1 0-6h8.576a3 3 0 0 1 0 6h-8.576zM59.59 33.513l-3.786 14.234A9.958 9.958 0 0 1 58 54c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10c.866 0 1.707.11 2.508.317L52.987 35H28.5a3 3 0 0 1 0-6h26.083l1.528-5.747A3 3 0 0 0 53.122 20H17.029a3 3 0 1 1 0-6h36.093a9 9 0 0 1 8.917 10.221l-.025.185L60 31.976V32c0 .552-.15 1.07-.41 1.513zM20 64c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm0-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm28 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
