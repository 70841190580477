import React from 'react'
import superagent from 'superagent'

const key = '61d384e3fbe2a153c67a20033ede33dee8a1e04e384465b5f3fe006a'

export const useIpData = shouldGetLocation => {
  const [location, setLocation] = React.useState(null)

  React.useEffect(
    () => {
      if (!shouldGetLocation) {
        return
      }

      // We first attempt to read the location from local storage.
      const serializedLocation = window.localStorage.getItem('location')

      if (serializedLocation) {
        setLocation(JSON.parse(serializedLocation))
        return
      }

      // Couldn't get location from local storage, so get it from ipdata.co.
      superagent
        .get(`https://api.ipdata.co?api-key=${key}`)
        .then(res => {
          const location = res && res.body
          if (location) {
            setLocation(location)

            // Save it for next time.
            const serializedLocation = JSON.stringify({
              country_code: location.country_code,
              country_name: location.country_name,
              currency: location.currency,
              city: location.city,
              calling_code: location.calling_code,
              is_eu: location.is_eu,
              emoji_flag: location.emoji_flag,
              flag: location.flag,
              timezone: location.timezone
            })
            window.localStorage.setItem('location', serializedLocation)
          }
        })
        .catch(err => {
          console.log(`Couldn't get IP location`, err)
        })
    },
    [shouldGetLocation]
  )

  return location
}
