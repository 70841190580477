export default function script() {
  return `(function($){
    var info_elements = [...$('i.info-icon')];
    info_elements.forEach(function(element) {
      element.addEventListener('mouseover', function() { info_showDesc(element); }, false);
      element.addEventListener('mouseout', function() { info_hideDesc(element); }, false);
    });

    function info_showDesc(e) {
      $(e).find('> div.description-container').show();
      $(e).find('> div.description-container > span.description').show();
    }

    function info_hideDesc(e) {
      $(e).find('> div.description-container').hide();
      $(e).find('> div.description-container > span.description').hide();
    }

    var group_groupContainers = [...$('div.choice-group-label')];
    group_groupContainers.forEach(function(element) {
      element.addEventListener('click', function() { group_toggleGroup(element); }, false)
    });

    function group_toggleGroup(e) {
      $(e).next().toggle();
      $(e).find('> span.text.choice-name').toggleClass('open');
    }

    var linkedProductIds = [];
    var configuratorProducts = [...$('button.maybe-on-backorder, div.description-container span.description')];
    for(var i = 0; i < configuratorProducts.length; i++) {
      var linkedId = configuratorProducts[i].dataset.linkedProductId;
      if(linkedId && linkedProductIds.indexOf(linkedId) === -1) {
        linkedProductIds.push(linkedId);
      }
    }

    if(linkedProductIds.length) {
      $.ajax('/api/wc/configurator-product-data?ids=' + linkedProductIds.toString(), { dataType: 'json' }).done(function (response) {
        var desc_descriptions = [...$('div.description-container span.description')];
        desc_descriptions.forEach(function(element) {
          var $element = $(element);
          if($element.data('linked-product-id')) {
            var id = $element.data('linked-product-id').toString();
            var productData = response[id.toString()];
            if(productData && productData.short_description) {
              $element.text(productData.short_description);
            }
          }
        });
      });
    }
  })(jQuery)`;
}
