import React, { Component } from 'react'
import styles from './contact.module.css'
import classNames from 'classnames/bind'
import { observer } from 'mobx-react'
import Wrap from '../../components/Wrap'
import superagent from 'superagent'
import striptags from 'striptags'
import { validateEmail } from '../../utils'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Select from '../../components/Select'

const cn = classNames.bind(styles)

const topics = [
  { value: 'onlinestore', label: 'Online Store – Questions about our store and your shipment' },
  { value: 'support', label: 'Product Support – If you have any issues with a product' },
  { value: 'sales', label: 'Sales – If you are a reseller' },
  { value: 'distribution', label: 'Distribution – For distribution inquires' },
  { value: 'other', label: 'Other' }
]

const initialState = {
  email: '',
  message: '',
  name: '',
  orderNumber: '',
  topic: topics[0].value,
  didSendEmail: false,
  errors: null
}

class Contact extends Component {
  state = { ...initialState }

  onTextChange = key => evt => {
    this.setState({ [key]: evt.target.value })
  }

  onTopicChange = topic => {
    this.setState({ topic })
  }

  onReset = () => {
    this.setState(state => ({
      ...initialState,
      email: state.email,
      name: state.name
    }))
  }

  onSubmit = evt => {
    if (this.state.isSubmitting) {
      return
    }

    evt.preventDefault()
    const emailIsValid = validateEmail(this.state.email)
    const messageIsValid = this.state.message.length > 0

    if (!emailIsValid || !messageIsValid) {
      this.setState({
        errors: { email: !emailIsValid, message: !messageIsValid }
      })
      return
    }

    this.setState({ isSubmitting: true })

    superagent
      .post('/api/mail')
      .set('Content-Type', 'application/json')
      .send(this.state)
      .end((err, res) => {
        if (err) {
          this.setState({ errors: { serverError: true } })
        } else if (res.statusCode && res.statusCode === 200) {
          this.setState({ didSendEmail: true, errors: null })
        }

        this.setState({ isSubmitting: false })
      })
  }

  render() {
    const { store, helmet } = this.props
    const { content } = store
    const {
      email,
      name,
      message,
      topic,
      orderNumber,
      errors,
      didSendEmail
    } = this.state

    return (
      <div
        className={cn('page', {
          didSendEmail,
          failed: errors && errors.serverError,
          contentIsLoaded: Boolean(content)
        })}
      >
        {helmet}
        <Wrap className={cn('wrap')}>
          <div className={cn('content')}>
            <h1>Get In Touch</h1>
            <p>
              {content && striptags(content.rendered)}
            </p>
          </div>
          <form className={cn('form')} onSubmit={this.onSubmit}>
            <Select
              label="Topic"
              onChange={this.onTopicChange}
              value={topic}
              options={topics}
            />
            {(topic === 'onlinestore' || topic === 'support') && (
              <Input
                onChange={this.onTextChange('orderNumber')}
                type="number"
                value={orderNumber}
                label="Order Number"
              />
            )}
            <Input
              onChange={this.onTextChange('name')}
              type="text"
              value={name}
              label="Name"
            />
            <Input
              required
              error={
                errors && errors.email && 'Please provide a valid email adress'
              }
              onChange={this.onTextChange('email')}
              type="email"
              value={email}
              label="Email"
            />
            <Input
              required
              onChange={this.onTextChange('message')}
              error={errors && errors.message && 'Please write something'}
              multiline
              rows={5}
              type="text"
              value={message}
              label="Message"
            />
            <Button type="submit">Send Mail</Button>
          </form>
          <div className={cn('success-message')}>
            <h2 className={cn('title')}>
              Thanks, we'll get back to you as soon as we can!
            </h2>
            <button
              className={cn('send-another-button')}
              onClick={this.onReset}
            >
              Send another one
            </button>
          </div>
          {errors &&
            errors.serverError &&
            <div className={cn('server-error-message')}>
              <h2 className={cn('title')}>
                Something went wrong, please try again later or send an email to
                support[at]cherryxtrfy.com
              </h2>
              <button
                className={cn('send-another-button')}
                onClick={this.onReset}
              >
                Try again
              </button>
            </div>}
        </Wrap>
      </div>
    )
  }
}

export default observer(Contact)
