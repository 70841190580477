import Home from './Home'
import Contact from './Contact'
import Page from '../components/Page'
import Products from '../components/Products'
import Posts from '../components/Posts'
import ConfiguratorRoute from "../components/ConfiguratorRoute";
import CheckoutWrapper from '../components/Checkout/CheckoutWrapper'
import Order from '../components/Order'
import SearchResult from '../components/Search/SearchResult';

export { renderRoute } from './render-route'

const routes = [
  {
    path: '/',
    slug: 'startpage',
    exact: true,
    label: 'In it to win it',
    component: Home,
    includeInMenu: true
  },
  /*{
    path: '/gift-guide',
    label: 'Gift Guide',
    component: Page,
    includeInMenu: true,
    textColour: '#D90001'
  },*/
  {
    productCategoryId: 25,
    path: '/headsets',
    slug: 'headsets',
    label: 'Headsets',
    position: 'first',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 198,
    path: '/headset-accessories',
    label: 'Accessories',
    parent: 'headsets',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 199,
    path: '/headset-spare-parts',
    label: 'Spare Parts',
    parent: 'headsets',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 15,
    path: '/keyboards',
    slug: 'keyboards',
    label: 'Keyboards',
    dropdownLabel: 'Keyboards',
    position: 'first',
    component: Products,
    skipParentInUrl: true,
    includeInMenu: true
  },
  {
    productCategoryId: 194,
    path: '/keyboard-accessories',
    label: 'Accessories',
    parent: 'keyboards',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 195,
    path: '/keyboard-switches',
    label: 'Switches',
    parent: 'keyboards',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 196,
    path: '/custom-keyboard-parts',
    label: 'Custom Parts',
    parent: 'keyboards',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 197,
    path: '/keyboard-spare-parts',
    label: 'Spare Parts',
    parent: 'keyboards',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 16,
    path: '/mice',
    slug: 'mice',
    label: 'Mice',
    dropdownLabel: 'Mice',
    position: 'first',
    component: Products,
    skipParentInUrl: true,
    includeInMenu: true
  },
  {
    productCategoryId: 191,
    path: '/mouse-accessories',
    label: 'Accessories',
    parent: 'mice',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 192,
    path: '/custom-mouse-parts',
    label: 'Custom Parts',
    parent: 'mice',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 193,
    path: '/mouse-spare-parts',
    label: 'Spare Parts',
    parent: 'mice',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 17,
    path: '/mousepads',
    label: 'Mousepads',
    component: Products,
    includeInMenu: true
  },
  {
    productCategoryId: 223,
    path: '/microphones',
    slug: 'microphones',
    label: 'Microphones',
    dropdownLabel: 'Microphones',
    position: 'first',
    component: Products,
    skipParentInUrl: true,
    includeInMenu: true
  },
  {
    productCategoryId: 237,
    path: '/mic-accessories',
    label: 'Accessories',
    parent: 'microphones',
    component: Products,
    includeInMenu: true
  },
  {
    path: '/customize',
    label: 'Customize',
    slug: 'customize',
    component: Page,
    includeInMenu: true
  },
  {
    path: '/custom-keyboard-builder',
    slug: 'custom-keyboard-builder',
    label: 'Custom Keyboard Builder',
    parent: 'customize',
    skipParentInUrl: true,
    component: ConfiguratorRoute,
    productId: 26907,
    includeInMenu: true
  },
  {
    path: '/resellers',
    label: 'Resellers',
    component: Page,
    includeInMenu: true
  },
  {
    path: '/xtrfy-family',
    label: 'Xtrfy Family',
    component: Page,
    includeInMenu: true
  },
  {
    productCategoryId: 40,
    path: '/merch',
    label: 'Merch',
    component: Products,
    includeInMenu: true,
  },
  {
    path: '/gift-guide',
    label: 'Gift Guide',
    component: Page,
    includeInMenu: true,
    textColour: '#D90001'
  },
  {
    path: '/distributors',
    label: 'Distributors',
    component: Page
  },
  {
    path: '/contact',
    label: 'Contact',
    component: Contact
  },
  {
    path: '/cart',
    slug: 'cart',
    label: 'Cart',
    exact: true,
    component: CheckoutWrapper,
    includeInMenu: false
  },
  {
    path: '/checkout',
    slug: 'checkout',
    label: 'Checkout',
    exact: true,
    component: CheckoutWrapper,
  },
  {
    path: '/order/*',
    slug: 'order',
    label: 'Order',
    component: Order
  },
  {
    postCategoryId: 22,
    path: '/press',
    label: 'Press Releases',
    component: Posts
  },
  {
    postCategoryId: 23,
    path: '/news',
    label: 'News',
    component: Posts
  },
  {
    path: '/search',
    slug: 'search',
    label: 'Search',
    component: SearchResult
  },
  // Generic fallback path
  {
    path: '',
    component: Page
  }
];

/*const today = new Date();

const blackFridayStart = new Date('2024-11-04T08:00+01:00');
const blackFridayEnd = new Date('2024-12-01T00:00+01:00');

const shouldShowBlackFridayLink = today >= blackFridayStart && today < blackFridayEnd;
if (shouldShowBlackFridayLink) {
  // Make sure to insert this directly after the /merch link
  let merchIndex = routes.findIndex(route => route.path === '/merch');
  if (merchIndex === -1) merchIndex = routes.length - 1;

  routes.splice(merchIndex + 1, 0, {
    path: '/black-cherry-weeks',
    label: 'Black Weeks',
    component: Page,
    includeInMenu: true,
    textColour: '#D90001'
  });
}*/

export default routes;
