import React from 'react'

const IconChevron = props => (
  <svg width="1em" height="1em" viewBox="0 0 8 13" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-8-5h24v24H-8z" />
      <path fill="#000" d="M.59 11.34l4.58-4.59L.59 2.16 2 .75l6 6-6 6z" />
    </g>
  </svg>
)

export default IconChevron
