import superagent from 'superagent'

const handleResponse = res => {
  if (res.status && res.status === 200) {
    return JSON.parse(res.text) || []
  }
  return []
}

export default class WooCommerce {
  createUrl = path => `/api/wc/${path}`;
  get = path =>
    superagent
      .get(this.createUrl(path))
      .then(handleResponse)
      .catch(err => {
        console.log('client_error_woocommerce_api', err)
      });
  clearCart = async () => {
    return superagent
      .get(`/wp/?clear-cart`)
      .withCredentials()
      .then(() => {
        return true;
      })
      .catch(err => {
        console.log('clear_cart', err)
      });
  };

  addToCart = async (productId, count, variation, recommendedById, configuration) => {
    let data = {
      'add-to-cart': productId,
      quantity: count,
      variation_id: variation,
      recommendedBy: recommendedById,
    }

    if (configuration) {
      data.pc_configurator_data = JSON.stringify(configuration);
    }

    return superagent
      .post('/wp/', data)
      .type('form')
      .withCredentials()
      .then(() => {
        return true;
      })
      .catch(err => {
        console.log('add_to_cart', err)
      });
  };
}
