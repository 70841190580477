import React, { Component } from 'react'
import styles from './info-boxes.module.css'
import classNames from 'classnames/bind'
import Wrap from '../../Wrap'

const cn = classNames.bind(styles)

export default class InfoBoxes extends Component {
  render() {
    const { items, className } = this.props

    return (
      <div className={cn('info-boxes', className)}>
        <Wrap className={cn('wrap')}>
          {items.map(({ title = '', text = '' }, idx) =>
            <div className={cn('item')} key={idx}>
              <h3 className={cn('title')}>{title}</h3>
              <p className={cn('text')}>{text}</p>
            </div>
          )}
        </Wrap>
      </div>
    )
  }
}
