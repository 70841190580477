import React from 'react';
import classNames from 'classnames/bind';
import styles from './recommendedProductsModal.module.css';
import Modal from "../Modal";
import RecommendedProductsTitle from "../RecommendedProductsTitle";
import RecommendedProduct from "../RecommendedProduct";
import RecommendedProductsTextArea from "../RecommendedProductsTextArea";

class RecommendedProductsModal extends React.Component {
  constructor(props) {
    super(props);
    this.cn = classNames.bind(styles);
  }

  render(){
    const {
      recommended_products_discount,
      recommended_products_title,
      activate_recommended_products_discount,
      recommended_products_text_area
    } = this.props.baseProduct.acf;

    return (
      <Modal onClose={this.props.onClose} className={this.cn(styles.recommendedProductsBaseModal)}>
        <div className={this.cn(styles.recommendedProductsModal)}>
          <RecommendedProductsTitle title={recommended_products_title} useDiscount={activate_recommended_products_discount} discount={recommended_products_discount} />
          <RecommendedProductsTextArea text={recommended_products_text_area} />
          <div className={this.cn(styles.recommendedProductsContainer)}>
            {this.props.recommendedProducts.map(recProduct => {
              if(recProduct.stock_status === "instock") {
                return (
                  <div key={recProduct.id} className={this.cn(styles.recommendedProductsInnerContainer)}>
                    <RecommendedProduct
                      cart={this.props.cart}
                      product={recProduct}
                      useDiscount={activate_recommended_products_discount}
                      discount={recommended_products_discount}
                      recommendedBy={this.props.baseProduct.id}
                      isExtraProduct={true}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Modal>
    );
  }
}

export default RecommendedProductsModal;
