import React from 'react'

export default function () {
  const html = `<div class="overlay"></div>
  <div class="mkl_pc_container">
          <div class="mkl_pc_bg<# if ( data.bg_image && '/wp/wp-content/plugins/product-configurator-for-woocommerce/assets/images/default-bg.jpg' == data.bg_image ) { #> default-bg<# } #>"<# if ( data.bg_image ) { #> style="background-image: url({{data.bg_image}}); "<# } #>></div>
          <div class="configuration-summary-container">
             <div class="configuration-summary">
               <h2>SUMMARY</h2>
               <div class="xtrfy-items"></div>
               <div class="xtrfy-total-summary xtrfy-total">Total: <span class="xtrfy-total-cost"></span></div>
             </div>
          </div>
      </div>
  <div class="loader">
    <div class="header">Loading Data </div>
    <div class="spinner"></div>
  </div>`
  return <script type="text/html" id="tmpl-mkl-pc-configurator" dangerouslySetInnerHTML={{__html: html}}/>
}
