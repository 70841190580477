import React, { Component } from 'react'
import Portal from './Portal'
import { MdClose } from 'react-icons/md'
import styles from './modal.module.css'
import cn from 'classnames'

export default class Modal extends Component {
  componentDidMount () {
    this.ref.focus()
  }

  onKeyDown = evt => {
    if (evt.key === 'Escape') {
      this.props.onClose()
    }
  }

  render () {
    const { onClose, children, className } = this.props
    let { zIndex } = this.props;

    if(!zIndex) zIndex = 1000;

    return (
      <Portal zIndex={zIndex} className={styles.portal} waitForMount={false}>
        <div
          ref={ref => { this.ref = ref }}
          className={styles.modalContainer}
          onKeyDown={this.onKeyDown}
          style={{zIndex: zIndex}}
          onClick={(e) => {
            e.stopPropagation();
            onClose()
          }}
        >
          <button onClick={() => {
            onClose()
          }} className={styles.closeButton}>
            <MdClose />
          </button>
          <div
            className={cn(styles.modal, className)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </div>
        </div>
      </Portal>
    )
  }
}
