import Link from '../Link';
import React, {useState} from 'react';
import {validateEmail} from '../../utils';
import superagent from 'superagent';
import classNames from 'classnames/bind';
import styles from './newsletter.module.css';
import Input from '../Input';
import Button from '../Button';

const cn = classNames.bind(styles)

const initialState = {
  email: '',
  hasError: false,
  errors: null,
  didSendEmail: false,
  didAcceptPrivacyPolicy: false
}

function Newsletter({isFooter}) {
  const [state, setState] = useState(initialState);

  const {
    email,
    didSendEmail,
    errors,
    hasError,
    didAcceptPrivacyPolicy
  } = state;
  const hasServerError = errors && errors.serverError

  const onEmailChange = evt => {
    if (evt.target) {
      let value = evt.target.value;

      setState(prevState => {
        return {...prevState, ...{email: value}}
      })
    }
  }

  const onSignup = (e) => {
    const {email, didAcceptPrivacyPolicy} = state

    e.preventDefault();

    if (!didAcceptPrivacyPolicy) {
      return false;
    }

    if (!validateEmail(email)) {
      setState({hasError: true})
      return false;
    }

    superagent
      .post('/api/newsletter')
      .set('Content-Type', 'application/json')
      .send({ email, tags: ['Newsletter cherryxtrfy.com'] })
      .end((err, res) => {
        if (err) {
          setState({
            errors: {didSendEmail: false, serverError: true}
          })
        } else if (res.statusCode && res.statusCode === 200) {
          setState({...initialState, ...{didSendEmail: true, hasError: null}})
        }
      })

    return false;
  }

  let section = isFooter ? 'footer-section' : 'section';
  return (
    <section className={cn(section)} label="Subscribe To Newsletter">
      {isFooter ? <h3>Subscribe To Newsletter</h3> : <h1>Subscribe To Newsletter</h1>}
      <form className={cn('newsletter')} onSubmit={onSignup}>
        <div className={cn('email-input')}>
          <Input
            autoFocus={!isFooter}
            required
            type="email"
            label="Enter e-mail"
            className={cn('input')}
            value={email}
            onChange={onEmailChange}
          />
        </div>
        <div className={cn('accept-check')}>
          <label>
            <input
              required
              type="checkbox"
              checked={didAcceptPrivacyPolicy}
              onChange={() => {
                setState(prevState => {
                  return {...prevState, ...{didAcceptPrivacyPolicy: true}}
                })
              }}
            />
            <span>
            I accept the <Link className={cn('privacy-policy-link')} to='/privacy-policy'>Privacy Policy</Link>.
          </span>
          </label>
        </div>
        <Button type="submit" className={cn('subscribe-button')}>Join now</Button>
      </form>
      {didSendEmail && (
        <div className={cn('success-message')}>
          <span>Thanks, we'll be in touch!</span>
        </div>
      )}
      {hasError && (
        <div className={cn('error')}>Please enter a valid email</div>
      )}
      {hasServerError && (
        <div className={cn('server-error-message')}>
          <span>Something went wrong, please try again later</span>
        </div>
      )}
    </section>
  )
}

export default Newsletter;
