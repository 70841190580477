import React from 'react'
import styles from './ticker.module.css'
import classNames from 'classnames/bind'
import NukaCarousel from 'nuka-carousel'
import IconChevron from "../../Checkout/IconChevron";

const cn = classNames.bind(styles)

export default class extends React.Component {
    render() {
        const items = this.props.items;

        return <div className={styles.container}>
            {items.length > 1 ?
                <NukaCarousel
                    autoplay={true}
                    wrapAround={true}
                    heightMode={'current'}
                    autoplayInterval={10000}
                    renderBottomCenterControls={() => null}
                    renderCenterLeftControls={({previousSlide}) => (
                        <button onClick={previousSlide} className={styles.arrowButton}>
                            <IconChevron className={styles.chevronLeft}/>
                        </button>
                    )}
                    renderCenterRightControls={({nextSlide}) => (
                        <button onClick={nextSlide} className={styles.arrowButton}>
                            <IconChevron className={styles.chevronRight}/>
                        </button>
                    )}
                    width={'100%'}>
                    {items.map((item, idx) =>
                        <div
                            key={idx}
                            className={cn('ticker-box-item')}
                            dangerouslySetInnerHTML={{__html: item.content}}
                        />
                    )}
                </NukaCarousel>
                : <div
                    className={cn('ticker-box-item')}
                    dangerouslySetInnerHTML={{__html: items[0].content}}
                />}
        </div>
    }
}