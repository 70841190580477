import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    style={{
      enableBackground: "new 0 0 200 200",
    }}
    xmlSpace="preserve"
  >
    <path d="M110.8 157.7H98.3V32.2l-27.9-9.8h40.4v55.1h9.8V12.6H36.9V166l4 1.4 57.3 20.1v-20.1h22.3v-64.9h-9.8v55.2h.1zm-22.3 0V173.9l-18.1-6.4-23.7-8.4V24.4l41.8 14.7v118.6z" />
    <path d="m140.9 67.8-6.9 6.9 10.4 10.4H106v9.8h38.4L134 105.3l6.9 6.9L163.1 90z" />
  </svg>
)
